import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";

import "./SelectDesign.scss";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

import {
  milestone,
  product_details,
} from "../../Redux/ProductSlice";
import { API_URL } from "../../Helper/Helper";

import SmallIcon from "../smallIcon/SmallIcon";
import CommonHeader from "./CommonHeader";

toast.configure();

export default function Step2() {
  const params = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [step, setstep] = useState();
  const [productId, setProductId] = useState();
  const [copyRightMsg, setCopyRightMsg] = useState();
console.log("copyRightMsg",copyRightMsg)
  const CurrentProductDetails = useSelector(
    (state) => state?.product?.productdet
  );
  const milestoneSteps = useSelector((state) => state?.product?.milestoneSteps);
  const settingmsg = useSelector((state) => state?.product?.settingdata);
console.log("FROM STEP-2 :: CurrentProductDetails : ",CurrentProductDetails)
  // set ProductId
  useEffect(() => {
    if (!!params) {
      let _t = params?.pathname?.split("choosedesign");
      let _id = _t[0].split("/");
      setProductId(_id[1]);
    }
  }, [params]);

  // call for fetch product details with productId
  useEffect(() => {
    if (productId) {
      dispatch(product_details(productId));
      dispatch(milestone());
    }
    
  },[productId,dispatch] );

  useEffect(()=>{
    const intervalForStepTwo = setInterval(() => {
      dispatch(product_details(productId));
      console.log("test 2");
    },30000);

    return () => {
      clearInterval(intervalForStepTwo);
    }
  })

  // for re-direct to either step1: choosedesign or step3
  useEffect(() => {
console.log("CurrentProductDetails?.basic_details?.status_document",CurrentProductDetails?.basic_details?.status_document !== "")
    if (CurrentProductDetails?.basic_details?.project_step === 2 && CurrentProductDetails?.basic_details?.status_document !== ""){
      history.push("step3", { id: productId });

    }else if (CurrentProductDetails?.basic_details?.date === null || CurrentProductDetails?.basic_details?.start_time === "" || CurrentProductDetails?.basic_details?.end_time === "") {
      
      history.push('choosedesign',{id:productId});
    } 
  }, [CurrentProductDetails, productId,history]);

  // set Copy Right Message & MileStone List
  useEffect(() => {
    if (milestoneSteps !== "") {
      setstep(milestoneSteps);
    }
    if (settingmsg.data?.data[9]?.setting_value) {
      setCopyRightMsg(settingmsg.data?.data[9]?.setting_value);
    }
  }, [milestoneSteps, settingmsg]);

  // TailSpin if CurrentProductDetails not available or loading
  if (!CurrentProductDetails) {
    return (
      <div className="loader-class">
        <div>
          <TailSpin width="100" color="grey" />
        </div>
      </div>
    );
  }

  return (
    <>
      <CommonHeader currentProjectStep={2} />
      <div className="gapsec pt-0">
        <div className="selectaddPrice step2">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="addressload">
                  <h4>Address</h4>
                  <p style={{ textTransform: "capitalize" }}>
                    <LocationOnIcon />{" "}
                    {CurrentProductDetails?.basic_details?.address}
                  </p>
                </div>
                <div className="designDetail">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="lftimg">
                        {" "}
                        <img
                          src={
                            CurrentProductDetails?.basic_details?.showroom_id
                              ?.default_image === ""
                              ? "/images/blank.png"
                              : API_URL +
                                "/uploads/showroom/" +
                                CurrentProductDetails?.basic_details
                                  ?.showroom_id?.default_image
                          }
                          alt="showroom-banner-img"
                        />
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="sliderRightsec">
                        <h2>
                          {
                            CurrentProductDetails?.basic_details?.showroom_id
                              ?.title
                          }
                        </h2>
                        <ul className="showroomdetail">
                          <li>
                            <span className="icon">
                              <img src="/images/detailic2.svg" alt="" />
                            </span>
                            <span className="detailHeading">Floor Plan</span>
                            <ul className="bedbath">
                              {CurrentProductDetails?.basic_details?.showroom_id?.floor_plan?.map(
                                (id, index) => (
                                  <div key={index}>
                                    {id?.bedroom_numbers && (
                                      <>
                                        <SmallIcon img="bed.svg" />
                                        {id.bedroom_numbers}
                                      </>
                                    )}
                                    {id?.studio && "Studio"}
                                    {id?.bathroom_numbers && (
                                      <>
                                        <SmallIcon img="bath.svg" />
                                        {id.bathroom_numbers}
                                      </>
                                    )}
                                  </div>
                                )
                              )}
                            </ul>
                          </li>
                          <li>
                            <span className="icon">
                              <img src="/images/detailic3.svg" alt="" />
                            </span>
                            <span className="detailHeading">
                              Package Includes
                            </span>
                            {
                              CurrentProductDetails?.basic_details?.showroom_id
                                ?.packge_includes
                            }
                          </li>
                          <li>
                            <span className="icon">
                              <img src="/images/detailic4.svg" alt="" />
                            </span>
                            <span className="detailHeading">
                              Estimated Size
                            </span>
                            ~
                            {
                              CurrentProductDetails?.basic_details?.showroom_id
                                ?.estimated_size
                            }{" "}
                            sqft
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-overview-txt"> Project Overview</div>

                <div className="accordionsec">
                  <Accordion defaultActiveKey="0">
                    {!step ? (
                      ""
                    ) : (
                      <>
                        {step?.map((x, key) => (
                          <div key={key}>
                            <Accordion.Item
                              style={{ margin: "5px 5px" }}
                              eventKey={key}
                            >
                              <Accordion.Header>{x?.title}</Accordion.Header>
                              <Accordion.Body>
                                <ul className="steps">
                                  {x?.steps_details.length > 0 ? (
                                    <>
                                      {x?.steps_details?.map((id, index) => (
                                        <li key={index}>
                                          <h4>{id?.title}</h4>
                                          <p>
                                            <QueryBuilderIcon /> {id?.duration}
                                          </p>
                                        </li>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <ul> No records found</ul>{" "}
                                    </>
                                  )}
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                          </div>
                        ))}
                      </>
                    )}
                  </Accordion>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="priceTime">
                  <div className="startingat">
                    <h3>Estimated at</h3>
                  </div>
                  <div className="pricetag">
                    <h2>
                      {CurrentProductDetails?.basic_details?.showroom_id?.estimated_cost?.toLocaleString(
                        "en-US",
                        { style: "currency", currency: "USD" }
                      )}
                    </h2>
                  </div>
                  <div className="tableWrap">
                    <table>
                      <thead>
                        <tr>
                          <td>Permit</td>
                          <td>
                            Starting at $
                            {CurrentProductDetails?.basic_details?.permit}
                          </td>
                        </tr>
                        <tr>
                          <td>Construction</td>
                          <td>
                            Starting at $
                            {CurrentProductDetails?.basic_details?.construction}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="dateTime stepField">
                    <ul>
                      <li>
                        <label>Date</label>
                        <div className="form-group">
                          <p>
                            {moment(
                              CurrentProductDetails?.basic_details?.date
                            ).format("dddd")}
                            ,{" "}
                            {moment(
                              CurrentProductDetails?.basic_details?.date,
                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                            )
                              .format("MMMM")
                              .substring(0, 3)}{" "}
                            {moment(
                              CurrentProductDetails?.basic_details?.date
                            ).format("DD")}{" "}
                            {moment(
                              CurrentProductDetails?.basic_details?.date
                            ).format("YYYY")}
                          </p>
                        </div>
                      </li>
                      <li>
                        <label>Time</label>

                        <div className="form-group">
                          <p>
                            {" "}
                            {
                              CurrentProductDetails?.basic_details?.start_time
                            } - {CurrentProductDetails?.basic_details?.end_time}{" "}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="msgbox">
                    <h4>Message</h4>
                    <div
                      value={copyRightMsg === undefined ? "" : copyRightMsg}
                      onChange={(e) => setmsg(e?.target?.value)}
                    >
                      {copyRightMsg === undefined ? "" : copyRightMsg}
                    </div>
                  </div> */}
                </div>
                {/* <div className="nxtbtn">
                <button type="button" className="btn btn-sm rounded-pill" onClick={()=>history.push('step3',{id:productId})} >Next step</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
