import { configureStore } from "@reduxjs/toolkit";
import CmsSlice from "./CmsSlice";
import authSlice from "./AuthSlice";
import ActiveSlice from "./ActiveSlice";
import FooterSlice from "./FooterSlice";
import productSlice from "./ProductSlice";
import contactSlice from './ContactSlice'

import showroomSlice from "./showroomSlice";
export const store = configureStore({
  reducer: {
    CmsDetails: CmsSlice,
    Auth: authSlice,
    Footer: FooterSlice,
    product:productSlice,
    showroom:showroomSlice,
    contactreducer:contactSlice,
    ActiveSlice:ActiveSlice
  },
});
