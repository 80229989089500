import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import "@popperjs/core/dist/cjs/popper.js";
import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
  
  <React.StrictMode>
  <GoogleOAuthProvider clientId="38608546965-2nh53mrebi2r8l2prlq6ockp9fkm2btk.apps.googleusercontent.com">
    <Provider store={store}>
     
      <App />
  
      
    </Provider>
    </GoogleOAuthProvider>,
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
