import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../Helper/Helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const initialState = {
  isLoggedIn: false,
  loggedInUserDetails: {},
};

export const signup = createAsyncThunk("/signup", async (user) => {
  const response = await ApiHelperFunction({
    urlPath: "/api/user/signup",
    method: "POST",
    formData: user,
  });

  return response;
});

export const socialSignup = createAsyncThunk("/socialSignup", async (user) => {
  const response = await ApiHelperFunction({
    urlPath: "/api/user/social-signup",
    method: "POST",
    formData: user,
  });
  // console.log(response,'response')
  return response;
});

export const forgotPassword = createAsyncThunk(
  "/forgot-password",
  async (user) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/user/forgot-password",
      method: "POST",
      formData: user,
    });

    if (response !== "") {
    }

    return response;
  }
);

export const otp = createAsyncThunk("/verify-otp", async (user) => {
  const response = await ApiHelperFunction({
    urlPath: "/api/user/verify-otp",
    method: "POST",
    formData: user,
  });

  if (response !== "") {
  }

  return response;
});

export const changePassword = createAsyncThunk(
  "/change-password",
  async (user) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/user/change-password",
      method: "POST",
      formData: user,
    });

    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "/reset-password",
  async (user) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/user/reset-password",
      method: "POST",
      formData: user,
    });

    if (response !== "") {
    }

    return response;
  }
);

export const updateProfile = createAsyncThunk(
  "/api/user/edit-profile",
  async (user) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/user/edit-profile`,
      method: "POST",
      formData: user,
    });

    return response;
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      // localStorage.removeItem("pointb_token");
      localStorage.clear();
      state.isLoggedIn = false;
      state.loggedInUserDetails = {};
    },
    setUserDetails: (state, { payload }) => {
      state.isLoggedIn = true;
      state.loggedInUserDetails = payload;
    },
    clearupdate: (state, action) => {
      state.updateprofilestatus = "";
    },
    addreduxname: (state, action) => {
      state.reduxname = action?.payload;
    },
    clrrespass: (state, action) => {
      state.changepasswordstatus = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state, action) => {
        state.status = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.status = false;
          state.isRegistered = true;
          state.signUpMessage = action?.payload?.data?.message;
          state.userToken = action?.payload?.data?.token;
          window.localStorage.setItem(
            "pointb_token",
            action?.payload?.data?.token
          );
        } else {
          toast.success("Registration Failed", {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.signUpMessage = action?.payload?.data?.message;
          state.status = false;
          // state.signUpMessage = "Registration Failed";
        }

        return state;
      })
      .addCase(socialSignup.pending, (state, action) => {
        state.status = true;
      })
      .addCase(socialSignup.fulfilled, (state, action) => {
        if (
          action?.payload?.data?.message === "You have successfully logged in."
        ) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.userprofile = action?.payload?.data?.data;
          state.userToken = action?.payload?.data?.token;

          window.localStorage.setItem(
            "pointb_token",
            action?.payload?.data?.token
          );
          state.status = false;
          state.isLoggedIn = true;
          state.signInMessage = action?.payload?.data?.message;
        } else if (
          action?.payload?.data?.message === "You have successfully registered."
        ) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.isRegistered = true;

          //======================me========================
          state.userprofile = action?.payload?.data?.data;
          state.userToken = action?.payload?.data?.token;

          window.localStorage.setItem(
            "pointb_token",
            action?.payload?.data?.token
          );
          state.status = false;
          state.isLoggedIn = true;
          state.signInMessage = action?.payload?.data?.message;

          //=====================me===========================
        } else {
          toast.success(action?.payload?.data?.message, {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.signUpMessage = action?.payload?.data?.message;
          state.status = false;
        }

        return state;
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.status = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.status = false;
          state.otp = action?.payload?.data?.data?.otp;
          state.forgotpasswordmessage = action?.payload?.data?.message;
          state.successfullforgot = true;
          state.email = action?.payload?.data?.data?.email;
        } else {
          toast.success(action?.payload?.data?.message, {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.email = "";
          state.successfullforgot = false;
          state.forgotpasswordmessage = action?.payload?.data?.message;
          state.status = false;
        }

        return state;
      })

      .addCase(otp.pending, (state, action) => {
        state.status = true;
      })
      .addCase(otp.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.status = false;
          state.otpmessage = action?.payload?.data?.message;
          state.successfullotp = true;
          state.successfullforgot = false;
        } else {
          toast.success(action?.payload?.data?.message, {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.successfullotp = false;
          state.otpmessage = action?.payload?.data?.message;
          state.status = false;
        }

        return state;
      })
      .addCase(changePassword.pending, (state, action) => {
        state.status = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.status = false;
          state.changepasswordstatus = action?.payload?.status;
          state.islogout = true;
          state.changepasswordmessage = action?.payload?.data?.message;
        } else {
          toast.success(action?.payload?.data?.message, {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.changepasswordstatus = action?.payload?.status;
          state.changepasswordmessage = action?.payload?.data?.message;
          state.status = false;
        }

        return state;
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.status = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.status = false;
          state.successfullreset = true;
          state.successfullotp = false;
          state.islogout = true;
          state.resetpasswordmessage = action?.payload?.data?.message;
        } else {
          toast.success(action?.payload?.data?.message, {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.successfullreset = false;
          state.resetpasswordmessage = action?.payload?.data?.message;
          state.status = false;
        }

        return state;
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.status = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.profilepicture = action?.payload?.profile_image;
          state.updateprofilestatus = action?.payload?.status;
          state.status = false;
        } else {
          toast.success(action?.payload?.data?.message, {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.updateProfile = action?.payload?.data?.message;
          state.status = false;
        }

        return state;
      });
  },
});

export default authSlice.reducer;
export const { setUserDetails, logout, clearupdate, addreduxname, clrrespass } =
  authSlice.actions;
