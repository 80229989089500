import moment from "moment";

export const checkTimeAvalableOrNot = (timeRange, booked_time_array,selectedDate) => {

  let rangeSplit=timeRange?.split(" -");
  const rangeStartTimeText = rangeSplit[0]

  let isValid = true;
    if(booked_time_array?.length){
        var valueData = booked_time_array?.find((x) => {
            return rangeStartTimeText.includes(x?.start_time);
      });
      isValid = valueData === undefined ? true : false;
    }
    // console.log("same date","arabinda",moment().format("hh:mm A"))
  // const rangeStartTimeTextSplit =rangeStartTimeText?.split(" ")?.[0]?.split(":");
  // const rangeStartTimeMedian = rangeStartTimeText?.split(" ")?.[1];
  // const rangeStartTimeHour =rangeStartTimeTextSplit?.[0];
  // const rangeStartTimeMinute =rangeStartTimeTextSplit?.[0];

      
    if(moment(selectedDate).startOf("date").isSame(moment().startOf("date")) ){
      if(moment(rangeStartTimeText,"hh:mm A").isBefore(moment())){
        isValid = false
      }
      
    }


    return !isValid;
   
  };

//   let dateString = "08:00 PM - 08:30 AM";
//   let b = dateString.split("-");
//   console.log(b);

// let a = moment(b, "hh:mm A").isBefore(moment());
// console.log(a);

 