import axios from "axios";
// export const API_URL = "http://104.211.229.156:1545";

export const API_URL =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? process.env.REACT_APP_SERVER_DEV
    : process.env.REACT_APP_SERVER;

export const photoURL =
process.env.REACT_APP_ENVIRONMENT === "development"
    ? process.env.REACT_APP_PHOTO_URL_PATH_DEV
    : process.env.REACT_APP_PHOTO_URL_PATH;

console.log("process.env.ENVIRONMENT === development \n",process.env.REACT_APP_ENVIRONMENT,  process.env.REACT_APP_ENVIRONMENT === 'development' );
console.log("API_URL:::::", API_URL);
console.log("photoURL:::::", photoURL);

export const ApiHelperFunction = async (data) => {
  const { urlPath, method, formData } = data;
  var config = {
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": localStorage.getItem("pointb_token"),
    },
    data: formData,
  };

  let responseData = "";
  await axios(config)
    .then(function (response) {
      responseData = response;
    })
    .catch(function (error) {
      console.log(error);
    });
  return responseData;
};
