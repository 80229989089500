import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../Helper/Helper";

export const getHomeDetails = createAsyncThunk("api/home", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/home`,
    method: "GET",
  });

  return response;
});

export const getAboutus = createAsyncThunk("api/about_us", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/about_us`,
    method: "GET",
  });

  return response;
});

export const getHowitwork = createAsyncThunk("api/howitworks", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/howitworks`,
    method: "GET",
  });

  return response;
});

export const getTeamlist = createAsyncThunk("api/teams/getlist", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/teams/getlist`,
    method: "GET",
  });

  return response;
});


//HOw it works FAQ

export const getfaqlist = createAsyncThunk("/api/faq", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/faq`,
    method: "GET",
  });

  return response;
});

// Privacy document////
export const getprivacy = createAsyncThunk(
  "/api/privacy/getDocument",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/privacy/getDocument`,
      method: "GET",
    });

    return response;
  }
);

// Modal List////
export const getmodalList = createAsyncThunk("/api/model/list", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/model/list`,
    method: "GET",
  });

  return response;
});

//Fliter  showroom list /////////
export const showroomfilter = createAsyncThunk(
  "/api/showroom/getlist",
  async (data) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/showroom/getlist",
      data,
      method: "POST",
      formData: data,
    });

    return response;
  }
);

// id wise showroom details
export const showroomDetailsById = createAsyncThunk(
  "/api/showroom/details/:id",
  async (id) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/showroom/details/${id}`,
      method: "GET",
    });

    const { data: responseData } = response.data;
    return responseData;
  }
);

// testimonial list
export const gettestimonial = createAsyncThunk(
  "/api/testimonial/getlist",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/testimonial/getlist`,
      method: "GET",
    });

    return response;
  }
);
//service area
export const getservicearealist = createAsyncThunk(
  "/api/service_area/getlist",
  () => {
    return ApiHelperFunction({
      urlPath: `/api/service_area/getlist`,
      method: "GET",
    });
  }
);
//design list
export const getdesignlist = createAsyncThunk(
  "/api/project/getlist",
  async () => {
    const response = await ApiHelperFunction({
      // urlPath:`/api/project/getlist`,
      urlPath: "/api/showroom/getlist",
      method: "POST",
    });

    const { data: responseData } = response.data;

    return responseData;
  }
);

//getDetails of instructionCms
export const instructionCms = createAsyncThunk("/api/cms/list", async () => {
  const response = await ApiHelperFunction({
    // urlPath:`/api/project/getlist`,
    urlPath: "/api/cms/list",
    method: "POST",
    formData: { slug: "instruction" },
  });
  return response;
});

//floor plan list
export const getfloorlist = createAsyncThunk(
  "/api/floor_plan/getlist",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/floor_plan/getlist`,
      method: "GET",
    });

    const { data: responseData } = response.data;

    return responseData;
  }
);

//signup pop up
export const getpopup = createAsyncThunk("/api/home_popup", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/home_popup`,
    method: "GET",
  });

  return response;
});

//view cities
export const getcities = createAsyncThunk("/api/country/getlist", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/country/getlist`,
    method: "GET",
  });

  return response;
});

//get footer links
export const getFooterLinks = createAsyncThunk(
  "/api/setting/getlist",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/setting/getlist`,
      method: "GET",
    });

    return response;
  }
);

const initialState = {
  status: "idle",
  singleHomeDetails: [],
  singleAboutDetails: [],
  singleHowitwork: [],
  singleteamlist: [],
  faqdatalist: "",
  privacydatalist: "",
  deleteAddress: "",
  modalList: "",
  showroomFilter: "",
  instructionList: [],
  viewshowroom: null,
  testimonialdata: "",
  servicearealist: "",
  designlist: [],
  floorlist: [],
  popup: "",
  citiesdata: "",
  getFooterLinksStatus: "idle",
  getFooterLinksData: null,
};

export const CmsSlice = createSlice({
  name: "CmsDetails",
  initialState,
  reducers: {
    clrdata: (state) => {
      state.viewshowroom = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getHomeDetails.fulfilled, (state, action) => {
        state.status = "idle";

        state.singleHomeDetails = action?.payload?.data?.data;

        return state;
      })

      .addCase(getAboutus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAboutus.fulfilled, (state, action) => {
        state.status = "idle";

        state.singleAboutDetails = action?.payload?.data?.data;

        return state;
      })

      .addCase(getHowitwork.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getHowitwork.fulfilled, (state, action) => {
        state.status = "idle";

        state.singleHowitwork = action?.payload?.data?.data;

        return state;
      })

      .addCase(getTeamlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamlist.fulfilled, (state, action) => {
        state.status = "idle";

        state.singleteamlist = action?.payload?.data?.data;

        return state;
      })

      .addCase(getfaqlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getfaqlist.fulfilled, (state, action) => {
        state.status = "idle";
        state.faqdatalist = action?.payload;

        return state;
      })

      .addCase(getprivacy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getprivacy.fulfilled, (state, action) => {
        state.status = "idle";
        state.privacydatalist = action?.payload;

        return state;
      })

      // Modal List//////////////
      .addCase(getmodalList.pending, (state) => {
        state.modalList = "loading";

        return state;
      })
      .addCase(getmodalList.fulfilled, (state, action) => {
        state.status = "idle";
        state.modalList = action.payload;
        return state;
      })

      // Showroom Filter////////////
      .addCase(showroomfilter.pending, (state) => {
        state.showroomFilter = "loading";
        return state;
      })
      .addCase(showroomfilter.fulfilled, (state, action) => {
        state.status = "idle";
        state.showroomFilter = action.payload;
        return state;
      })

      // Showroom Details {viewshowroom}
      .addCase(showroomDetailsById.pending, (state) => {
        state.status = "loading";
        state.viewshowroom = null;
        return state;
      })
      .addCase(showroomDetailsById.fulfilled, (state, { payload }) => {
        state.status = "idle";

        state.viewshowroom = payload;
        return state;
      })

      .addCase(gettestimonial.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(gettestimonial.fulfilled, (state, action) => {
        state.status = "idle";
        state.testimonialdata = action?.payload;
        return state;
      })
      .addCase(getservicearealist.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(getservicearealist.fulfilled, (state, action) => {
        state.status = "idle";
        state.servicearealist = action?.payload;
        return state;
      })

      // designlist
      .addCase(getdesignlist.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(getdesignlist.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.designlist = payload;
        return state;
      })

      // floorlist
      .addCase(getfloorlist.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(getfloorlist.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.floorlist = payload;
        return state;
      })
      .addCase(instructionCms.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(instructionCms.fulfilled, (state, action) => {
        state.status = "idle";
        state.instructionList = action?.payload;
        return state;
      })
      .addCase(getpopup.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(getpopup.fulfilled, (state, action) => {
        state.status = "idle";
        state.popup = action?.payload;
        return state;
      })
      .addCase(getcities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getcities.fulfilled, (state, action) => {
        state.status = "idle";
        state.citiesdata = action?.payload;
        return state;
      })

      .addCase(getFooterLinks.pending, (state) => {
        state.getFooterLinksStatus = "loading";
        state.getFooterLinksData = null;
        return state;
      })
      .addCase(getFooterLinks.fulfilled, (state, { payload }) => {
        state.getFooterLinksStatus = "idle";
        state.getFooterLinksData = payload?.data?.data;
        return state;
      })
      .addCase(getFooterLinks.rejected, (state, action) => {
        state.getFooterLinksStatus = "idle";
        state.getFooterLinksData = null;
        return state;
      });
  },
});

export default CmsSlice.reducer;
export const { clrdata } = CmsSlice.actions;
