import { CheckCircleOutlined } from "@mui/icons-material";
import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import StripeModal from "./StripeModal/StripeModal";
import achpayment from "./ach.png";
import { ApiHelperFunction } from "../../Helper/Helper";
import { usePlaidLink } from "react-plaid-link";
import qs from "qs";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  product_details,
  FetchOwnProductLists,
} from "../../Redux/ProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { getActiveProject } from "../../Redux/ActiveSlice";

export default function PaymentModal({
  item,
  show,
  handleClose,
  address,
  paymenttype,
  _data,
}) {
  const history = useHistory();
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pymntchoose, setpymntchoose] = useState("");
  const currentproduct = useSelector((state) => state?.product?.productdet);
  const sid =
    currentproduct?.basic_details?._id !== undefined
      ? currentproduct?.basic_details?._id
      : localStorage.getItem("product_id");
  const [fnamerr, setfnamerr] = useState("");
  const [lnameErr, setlaneErr] = useState("");
  const [emailerr, setemailerr] = useState("");
  const [pherr, setphErr] = useState("");
  const [paymenterr, setpaymenterr] = useState("");

  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const [stripeshow, stripesetShow] = useState(false);

  const stripehandleClose = () => stripesetShow(false);
  const stripehandleShow = () => stripesetShow(true);

  const [method, setMethod] = useState(null);
  const [scrren, setScrren] = useState("success");

  const [data, setData] = useState("");

  const fetchToken = useCallback(async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/project/payment/ach/create/token`,
      method: "get",
    });
    let resData = response?.data;
    setData(resData.data);
  }, []);

  // useEffect(() => {
  //   fetchToken();
  // }, [fetchToken]);

  const [access_token_data, set_acess_token_data] = useState(null);
  const [_metadata, setMetaData] = useState(null);

  const onSuccess = useCallback(async (token, metadata) => {
    console.log(token, metadata, "token====");
    setMetaData(metadata);
    setScrren("success");

    const response = await ApiHelperFunction({
      urlPath: `/api/payment/create_access_token`,
      method: "POST",
      formData: {
        link_token: token,
        account_id: metadata?.account_id,
      },
    });

    if (response?.status === 200) {
      set_acess_token_data(response?.data?.data);
    }

    // send token to server

    // const config = {
    //   method: "post",
    //   url: "https://pointb.dedicateddevelopers.us/api/project/payment/ach/token/details",
    //   data: qs.stringify({ token: token }),
    //   headers: { "content-type": "application/json" },
    // };
    // try {
    //   const response = await axios(config);
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }
  }, []);

  const x = () => {
    handleClose();
    setfname("");
    setlname("");
    setEmail("");
    setPhone("");
    setpymntchoose("");
    setpaymenterr("");
    setfnamerr("");
    setlaneErr("");
    setemailerr("");
    setphErr("");
  };

  const [getTokenLoading, setGetTokenLoading] = useState(false);
  const [plaidToken, setPlaidToken] = useState(null);

  const getToken = async () => {
    try {
      setGetTokenLoading(true);

      const response = await ApiHelperFunction({
        urlPath: "/api/payment/create_link_token",
        method: "GET",
      });
      setGetTokenLoading(false);

      if (!!response?.data) {
        setData(response?.data?.data?.link_token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFinalPayment = async () => {
    try {
      setloading(true);
      let res = await ApiHelperFunction({
        urlPath: "/api/payment/plaid_stripe_token_create",
        method: "POST",
        formData: {
          access_token: access_token_data?.access_token,
          accountID: _metadata?.account_id,
          price: item,
          reference: "",
          currency: "USD",
          project_id: sid,
          typeofpayment: paymenttype,
        },
      });
      setloading(false);
      if (res?.data?.status == 200) {
        toast.success("Payment success");
        // history.push("/step4");
        x();
        if (sid) {
          dispatch(product_details(sid));
        }
        dispatch(getActiveProject());
        dispatch(FetchOwnProductLists());
      } else {
        toast.error("Something went wrong");
      }

      // setTimeout(() => {

      // }, 1000);

      // if (res?.data?.status === 200) {
      //   toast.success("Payment success");
      //   history.push("/activeproject");
      // } else {
      //   toast.error("Something went wrong");
      // }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const config = {
    token: data,
    onSuccess,
  };

  const { open, ready, err } = usePlaidLink(config);

  const payNow = () => {
    setfnamerr("");
    setlaneErr("");
    setemailerr("");
    setphErr("");
    setpaymenterr("");
    if (fname == "") {
      setfnamerr("can't keep it blank");
    }
    if (lname == "") {
      setlaneErr("can't keep it blank");
    }
    if (email == "") {
      setemailerr("Email is required");
    }
    if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setemailerr("Enter a valid email");
    }
    if (phone === "") {
      setphErr("can't keep it blank");
    }
    if (pymntchoose?.length === 0) {
      setpaymenterr("Please choose any one payment method");
      return;
    }
    if (
      pymntchoose === "Stripe" &&
      fname !== "" &&
      lname !== "" &&
      email !== "" &&
      phone !== ""
    ) {
      stripehandleShow();
      x();
    }

    if (
      pymntchoose === "Plaid" &&
      fname !== "" &&
      lname !== "" &&
      email !== "" &&
      phone !== ""
    ) {
      open();
      // x();
    }
  };

  const handleContinue = async (item) => {
    if (item === "Plaid") {
      open();
    } else {
      setScrren(null);
      setMethod(pymntchoose);
    }
  };

  useEffect(() => {
    method === "Plaid" && getToken();
  }, [method]);

  useEffect(() => {
    if (show) {
      getToken();
      setMethod(null);
      setScrren("choose");
    }
  }, [show]);

  return (
    <>
      <StripeModal
        paymenttype={paymenttype}
        amount={item}
        address={address}
        stripehandleClose={stripehandleClose}
        stripeshow={stripeshow}
      />
      <Modal
        show={show}
        onHide={x}
        animation={false}
        centered
        className="paymentmodal"
      >
        <Modal.Header>
          <h2>Payment</h2>
        </Modal.Header>
        <Modal.Body>
          {/* CHOOSE PAYEMNT METHOD */}
          {scrren === "choose" && (
            <div className="formInner">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <h3>Choose Payment Method</h3>

                  <div
                    className="stripetag"
                    onClick={() => setpymntchoose("Stripe")}
                  >
                    <h2
                      style={{
                        fontFamily: "futuramedium",
                        fontWeight: 600,
                        fontSize: "25px",
                        color: "#000000eb",
                        margin: " 0 0 7px 0",
                      }}
                    >
                      Stripe
                    </h2>

                    {pymntchoose !== "" && pymntchoose === "Stripe" ? (
                      <CheckCircleOutlined />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="stripetag"
                    onClick={() => setpymntchoose("Plaid")}
                  >
                    <h2
                      style={{
                        fontFamily: "futuramedium",
                        fontWeight: 600,
                        fontSize: "25px",
                        color: "#000000eb",
                        margin: " 0 0 7px 0",
                      }}
                    >
                      Plaid
                    </h2>
                    {pymntchoose !== "" && pymntchoose === "Plaid" ? (
                      <CheckCircleOutlined />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="error">{paymenterr}</div>

                  <button
                    type="button"
                    className="btn w-100"
                    disabled={pymntchoose?.length === 0}
                    onClick={() => handleContinue(pymntchoose)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}

          {method === "Stripe" && (
            <div className="formInner">
              <div className="row">
                <div className="col-lg-6">
                  <h3>Personal Details</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          placeholder="First Name"
                          onChange={(e) => setfname(e.target.value)}
                        />
                        <div className="error">{fnamerr}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          onChange={(e) => setlname(e.target.value)}
                        />
                        <div className="error">{lnameErr}</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Your Email</label>
                        <input
                          type="email"
                          placeholder="Your Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="error">{emailerr}</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Phone number</label>
                        <input
                          type="number"
                          placeholder="Your Number"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="error">{pherr}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3>Choosen Payment Method</h3>

                  <div className="stripetag">
                    <h2
                      style={{
                        fontFamily: "futuramedium",
                        fontWeight: 600,
                        fontSize: "25px",
                        color: "#000000eb",
                        margin: " 0 0 7px 0",
                      }}
                    >
                      Stripe
                    </h2>

                    <CheckCircleOutlined />
                  </div>
                  {/* <div
                    className="stripetag"
                    onClick={() => setpymntchoose("ACH")}
                  >
                    <h2
                      style={{
                        fontFamily: "futuramedium",
                        fontWeight: 600,
                        fontSize: "25px",
                        color: "#000000eb",
                        margin: " 0 0 7px 0",
                      }}
                    >
                      ACH payment
                    </h2>
                    {pymntchoose !== "" && pymntchoose == "ACH" ? (
                      <CheckCircleOutlined />
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className="error">{paymenterr}</div>

                  <button
                    type="button"
                    className="btn w-100"
                    onClick={() => payNow()}
                  >
                    Pay Now 
                  </button>
                </div>
              </div>
            </div>
          )}

          {method === "Plaid" && (
            <div className="formInner">
              <div className="row">
                <div className="col-lg-6">
                  <h3>Personal Details</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          placeholder="First Name"
                          onChange={(e) => setfname(e.target.value)}
                        />
                        <div className="error">{fnamerr}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          onChange={(e) => setlname(e.target.value)}
                        />
                        <div className="error">{lnameErr}</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Your Email</label>
                        <input
                          type="email"
                          placeholder="Your Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="error">{emailerr}</div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Phone number</label>
                        <input
                          type="number"
                          placeholder="Your Number"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="error">{pherr}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3>Choosen Payment Method</h3>

                  <div className="stripetag">
                    <h2
                      style={{
                        fontFamily: "futuramedium",
                        fontWeight: 600,
                        fontSize: "25px",
                        color: "#000000eb",
                        margin: " 0 0 7px 0",
                      }}
                    >
                      Plaid
                    </h2>

                    <CheckCircleOutlined />
                  </div>
                  {/* <div
                    className="stripetag"
                    onClick={() => setpymntchoose("ACH")}
                  >
                    <h2
                      style={{
                        fontFamily: "futuramedium",
                        fontWeight: 600,
                        fontSize: "25px",
                        color: "#000000eb",
                        margin: " 0 0 7px 0",
                      }}
                    >
                      ACH payment
                    </h2>
                    {pymntchoose !== "" && pymntchoose == "ACH" ? (
                      <CheckCircleOutlined />
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className="error">{paymenterr}</div>

                  <button
                    type="button"
                    className="btn w-100"
                    onClick={() => payNow()}
                    disabled={getTokenLoading}
                  >
                    Pay Now 
                  </button>
                </div>
              </div>
            </div>
          )}

          {scrren === "success" && (
            <div className="formInner">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  {/* <h3>Pay </h3> */}

                  <button
                    type="button"
                    className="btn w-100"
                    disabled={loading}
                    onClick={() => handleFinalPayment()}
                  >
                    {loading ? (
                      <div
                        className="spinner-border text-success spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      `Pay Now `
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
