import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getprivacy } from "../../Redux/CmsSlice";
import { useRef } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { API_URL } from "../../Helper/Helper";
import "./Privacy.scss";
export default function Privacy() {
  const dispatch = useDispatch();
  const privacydata = useSelector((e) => e?.CmsDetails?.privacydatalist);
  console.log(privacydata, "privacydata");
  const inputElement = useRef();

  useEffect(() => {
    dispatch(getprivacy());
  }, []);



  const [faq, setfaq] = useState();
  const { singleHowitwork } = useSelector((e) => e.CmsDetails);
  const faqdata = useSelector((e) => e?.CmsDetails?.faqdatalist);

  const myRef = useRef(null)
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {




    if (window.location.hash === "#resultWrap") {
      // executeScroll()
    }
  }, []);

  useEffect(() => {
    // singleHowitwork!==[] && executeScroll()
  }, [singleHowitwork])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  useEffect(() => {
    if (faqdata?.status === 200) {
      setfaq(faqdata?.data?.data);
    }
  }, [faqdata]);

  return (
    <>
      <div className="innerBanner">
        <figure>
          <img src="./images/showroomban.jpeg" alt="" />
        </figure>
        <div className="bancont container">
          <div className="innerbancont">
            <h1>Privacy Policy</h1>
            <p>The information we collect, why we use it, who we share it with, and how we protect it, along with the tools you can use to manage your privacy.</p>
          </div>
        </div>
      </div>
      <div className="gapsm privacysec">
        <div className="container">
          <div className="privacybox">
          <p
          dangerouslySetInnerHTML={{
            __html: `${
              privacydata?.data?.data?.content
                ? privacydata?.data?.data?.content
                : "Loading..."
            }`,
          }}
        ></p>
          </div>
        </div>
      </div>
    </>
  );
}
