import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useHistory } from "react-router-dom";
import "../Projectlist/Listproject.scss";
//import moment from "moment";
//import Modal from "react-bootstrap/Modal";
//import Slider from "react-slick";
import { API_URL } from "../../Helper/Helper";
//import { project_all_list } from "../../Redux/ProductSlice";
import SmallIcon from "../smallIcon/SmallIcon";
import { getActiveProject } from "../../Redux/ActiveSlice";
import gifloader from "./loader.gif";
//import OrderDetail from "./OrderDetail/OrderDetail";
const ActiveProject = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [list, setlist] = useState();
  const [count, setcount] = useState(12);
 // const [openmodal, setopenmodal] = useState(false);
  const [currentitem, setcurrentitem] = useState();
  const toggleModal = (id) => {
    setTimeout(() => {
      // history.push(`/activeproject/orderdetai/${id}`)
      history.push(`${id}/step4`);
    }, 400);

    // localStorage.setItem("item_id", id?.showroom_id);
    localStorage.setItem("sid", id);
    // if(openmodal===false){
    //     setopenmodal(true)
    // }
    // else{
    //     setopenmodal(false)
    //     setcurrentitem()
    // }
    history.push();
  };
  const { activeProjectList, status } = useSelector((e) => e.ActiveSlice);

  useEffect(() => {
    dispatch(getActiveProject());
  }, [dispatch]);

  useEffect(() => {
    if (activeProjectList?.data !== undefined) {
      const val = activeProjectList?.data;

      if (val?.length > 12) {
        const data = val?.slice(0, count);

        setlist(data);
      } else if (val?.length <= 12) {
        setlist(val);
       
      }
    }
  }, [activeProjectList, count]);

  return (
    <>
      <div className="gapsec">
        <h1 style={{ textAlign: "center" }}>Active Project List</h1>
        {list !== undefined ? (
          <>
            {
              <div className="showroomItems list-project">
                <div className="container">
                  <div className="selectfilter"></div>

                  <div className="row">
                    {status === "loading" || "" ? (
                      <>
                        {/* <CircularProgress disableShrink /> */}
                        <div className="loaderwrapper">
                          <img src={gifloader} alt="" />
                        </div>
                      </>
                    ) : (
                      <>
                        {list?.length > 0 ? (
                          <>
                            {list?.map((item, key) => {
                              return (
                                <div
                                  className="col-lg-4 col-md-6"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    toggleModal(item?._id);
                                    setcurrentitem(item);
                                  }}
                                >
                                  <div className="item">
                                    <figure>
                                      {/* onClick={()=>margeImg(item?.showroom_details)} */}
                                      <img
                                        style={{ cursor: "pointer" }}
                                        src={
                                          item?.showroom_details
                                            ?.default_image === ""
                                            ? "/images/blank.png"
                                            : `${API_URL}/uploads/showroom/${item?.showroom_details?.default_image}`
                                        }
                                        alt=""
                                      />
                                    </figure>
                                    <div className="itemInner">
                                      {/* <div
                                        className="addressbar"
                                        style={{ marginButtom: "5px" }}
                                      > */}
                                        {/* <span style={{ color: "#0d3e87" }}>
                                          <LocationOnIcon />
                                        </span>{" "}
                                        <span>{item?.address}</span> */}
                                      {/* </div> */}
                                      <div className="nameBtn d-flex justify-content-between">
                                        <h3>{item?.showroom_details?.title}</h3>
                                        <div>
                                        <span style={{ color: "#0d3e87" }}>
                                          <LocationOnIcon />
                                        </span>{" "}
                                        <span>{item?.address}</span>
                                        </div>
                                      </div>
                                      <div className="bedPrice">
                                        <h4>
                                          {item?.showroom_details
                                            ?.showroom_floor_plan !== undefined
                                            ? item?.showroom_details?.showroom_floor_plan?.map(
                                                (id) => (
                                                  <>
                                                    {id?.bedroom_numbers ===
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        <SmallIcon img="bed.svg" />
                                                        {id?.bedroom_numbers}{" "}
                                                      </>
                                                    )}{" "}
                                                    {id?.studio ? (
                                                      "Studio"
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        {
                                                          // id?.bedroom_numbers
                                                        }{" "}
                                                      </>
                                                    )}
                                                    {id?.bathroom_numbers ===
                                                    "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        <SmallIcon img="bath.svg" />
                                                        {
                                                          id?.bathroom_numbers
                                                        }
                                                      </>
                                                    )}
                                                  </>
                                                )
                                              )
                                            : ""}
                                        </h4>

                                        <p className="pricetag">
                                          {/* <MonetizationOnOutlinedIcon />  */}
                                          {/* $ */}
                                          {
                                            item?.showroom_details
                                              ?.estimated_cost?.toLocaleString("en-US", {style:"currency", currency:"USD"})
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* <OrderDetail openmodal={openmodal} toggleModal={toggleModal} currentitem={currentitem} /> */}
                          </>
                        ) : (
                          <>No data</>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              // ))
            }
          </>
        ) : (
          ""
        )}

        {activeProjectList?.data?.length > 12 ? (
          <div class="text-center">
            {" "}
            {activeProjectList?.data?.length === list?.length ? (
              ""
            ) : (
              <button
                className="loadmore-class"
                onClick={() => setcount(count + 12)}
              >
                Load More...
              </button>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ActiveProject;
