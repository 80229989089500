import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../Helper/Helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { Details } from "@mui/icons-material";

toast.configure();

export const getTime = createAsyncThunk(
  "/api/project/date/list",
  async (formData) => {
    console.log("formData", formData);
    const response = await ApiHelperFunction({
      urlPath: `/api/project/date/list`,
      method: "POST",
      formData: formData,
    });

    return response;
  }
);

//project delete

export const projectDelete = createAsyncThunk(
  "/api/project/deleteProject",
  async (user) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/project/deleteProject`,
      method: "POST",
      formData: user,
    });

    return response;
  }
);

//step1 submit data
export const addstep1 = createAsyncThunk(
  "/api/project/createProject",
  async (formData) => {
    const date = new Date();
    if (formData?.date === undefined) {
      formData["date"] = date;
    }

    const response = await ApiHelperFunction({
      urlPath: `/api/project/createProject`,
      method: "POST",
      formData: formData, // showroom_id, address, date, start_time, end_time, project_id
    });

    const { data: step1DataResponse } = response?.data;

    return step1DataResponse;
  }
);

// Step 2 getproduct Details
export const product_details = createAsyncThunk(
  "/api/project/details/:id",
  async (projectId) => {
    if (projectId) {
      const response = await ApiHelperFunction({
        urlPath: `/api/project/details/${projectId}`,
        method: "GET",
        formData: projectId,
      });

      const { data: projectDataResponse } = response.data;

      return projectDataResponse;
    }
  }
);

// get milestone list
export const milestone = createAsyncThunk(
  "/api/milestone/getlist",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/milestone/getlist`,
      method: "GET",
    });

    const { data: milestoneListResponse } = response?.data;
    return milestoneListResponse;
  }
);

// project payment milestone
export const paymentMilestone = createAsyncThunk(
  "api/project/payment_group_details/id",
  async (data) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/project/payment_group_details/${data?.id}`,
      method: "GET",
    });

    const { data: paymentMileStoneResponse } = response?.data;

    return paymentMileStoneResponse?.projectmilestone_details;
  }
);

export const settingApi = createAsyncThunk("/api/setting/getlist", async () => {
  const response = await ApiHelperFunction({
    urlPath: `/api/setting/getlist`,
    method: "GET",
  });

  return response;
});

export const download_signed_document = createAsyncThunk(
  "/api/project/download_signed_document_step3",
  async (data) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/project/download_signed_document_step3`,
      method: "POST",
      formData: data,
    });

    return response;
  }
);

//signature
export const docuSign = createAsyncThunk(
  "/api/project/docusign",
  async (data) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/project/docusign`,
      method: "POST",
      formData: data,
    });

    return response;
  }
);

export const CreatePoduct = createAsyncThunk(
  "/api/project/inactive/projectCreate",
  async (data) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/project/inactive/projectCreate`,
      method: "POST",
      formData: data,
    });

    const { data: responseData } = response.data;

    return responseData;
  }
);

// Own Inactive Project Lists
export const FetchOwnProductLists = createAsyncThunk(
  "/api/project/getown_inactive_projectlist",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/project/getown_inactive_projectlist`,
      method: "get",
    });

    const { data: responseData } = response.data;
    
    return responseData;
  }
);

// card payment
export const paymentviaCard = createAsyncThunk(
  "/api/project/payment/debit-card",
  async (data) => {
    const response = await ApiHelperFunction({
      urlPath: "/api/project/payment/debit-card",
      method: "post",
      formData: data,
    });
    return response;
  }
);

export const initialState = {
  status: "idle",
  choose_design: null,
  loading: false,
  error: false,
  address: null,
  timeData: null,
  postadd: "",
  projectDeleted: "",
  productdet: "",
  milestoneSteps: [],
  settingdata: "",
  addstepone_data: "",
  signature: "",
  download_signed_document_step3: "",
  saveProductIdData: "",
  inactiveProjectList: [],
  paymentmilstep: "",
  cardPaymentStatus: "",
  successPaymentStatus: null,
};

const productSlice = createSlice({
  name: "choose_design",
  initialState,
  reducers: {
    setProductShowroomDesignId: (state, { payload }) => {
      state.choose_design = payload;
    },
    clrsid: (state, action) => {
      state.addstepone_data = "";
    },
    clrsignaturenoti: (state, action) => {
      state.signature = "";
    },
    setProductDetails: (state, { payload }) => {
      state.productdet = payload;
    },
    clrdownload_signed_document_step3noti: (state, action) => {
      state.download_signed_document_step3 = "";
    },
    clrpaymetdetails: (state, action) => {
      state.cardPaymentStatus = "";
    },
    clearSuccessPaymentStatus: (state, action) => {
      state.successPaymentStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTime.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(getTime.fulfilled, (state, action) => {
        state.status = "idle";
        if (action?.payload?.status === 200) {
          state.timeData = action.payload?.data?.data;
        } else {
          state.timeData = action.payload;
        }
        return state;
      })

      .addCase(projectDelete.pending, (state) => {
        state.projectDeleted = "loading";
        return state;
      })
      .addCase(projectDelete.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.projectDeleted = payload;

        toast.success("Successfully Project Deleted");
        return state;
      })

      .addCase(product_details.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(product_details.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.productdet = payload;
        return state;
      })
      .addCase(milestone.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.milestoneSteps = payload;
        return state;
      })
      .addCase(settingApi.pending, (state) => {
        state.settingdata = "loading";
        return state;
      })
      .addCase(settingApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.settingdata = action.payload;
        return state;
      })
      .addCase(addstep1.pending, (state) => {
        state.addstepone_data = "loading";
        return state;
      })
      .addCase(addstep1.fulfilled, (state, { payload }) => {
        state.status = "idle";

        state.addstepone_data = payload;
        toast.success("Successfully scheduled Showroom", {
          type: "success",
          hideProgressBar: true,
          autoClose: 1000,
        });
        return state;
      })
      .addCase(docuSign.pending, (state) => {
        state.signature = "loading";
        return state;
      })
      .addCase(docuSign.fulfilled, (state, action) => {
        state.status = "idle";
        state.signature = action.payload;
        return state;
      })
      .addCase(download_signed_document.pending, (state) => {
        state.download_signed_document_step3 = "loading";
        return state;
      })
      .addCase(download_signed_document.fulfilled, (state, action) => {
        state.status = "idle";
        state.download_signed_document_step3 = action.payload;
        return state;
      })
      .addCase(CreatePoduct.pending, (state) => {
        state.productdet = "loading";
        return state;
      })
      .addCase(CreatePoduct.fulfilled, (state, { payload }) => {
        if (payload?._id !== undefined) {
          localStorage?.setItem("product_id", payload?._id);
        }

        state.status = "idle";
        state.productdet = payload;
        return state;
      })
      .addCase(FetchOwnProductLists.pending, (state) => {
        state.status = "loading";
        return state;
      })
      .addCase(FetchOwnProductLists.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.inactiveProjectList = payload;
        return state;
      })
      .addCase(paymentMilestone.pending, (state) => {
        state.paymentmilstep = "loading";
        return state;
      })
      .addCase(paymentMilestone.fulfilled, (state, { payload }) => {
        state.status = "idle";
        state.paymentmilstep = payload;
        return state;
      })
      .addCase(paymentviaCard.pending, (state) => {
        state.cardPaymentStatus = "loading";
        return state;
      })
      .addCase(paymentviaCard.fulfilled, (state, action) => {
        state.status = "idle";
        state.successPaymentStatus = action?.payload?.data?.status;
        state.cardPaymentStatus = action.payload;
        if (action?.payload?.data?.status === 200) {
          toast.success(action?.payload?.data?.message);
        } else {
          toast.warning(action?.payload?.data?.message);
        }
        return state;
      });
  },
});

export const {
  setProductShowroomDesignId,
  clrsid,
  setProductDetails,
  clrsignaturenoti,
  clrdownload_signed_document_step3noti,
  clrpaymetdetails,
  clearSuccessPaymentStatus,
} = productSlice.actions;
export default productSlice.reducer;
