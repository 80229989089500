import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../Helper/Helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const initialState = {
  activeProjectList:"",
  status:""
};

export const getActiveProject = createAsyncThunk("/getownprojectlist", async () => {
  const response = await ApiHelperFunction({
    urlPath: "/api/project/getownprojectlist",
    method: "GET",
  });
  return response;
})


export const activeSlice = createSlice({
  name: "activeproject",
  initialState,
  reducers: {
    // logout: (state, action) => {
    //   localStorage.clear();
    //   state.logoutMessage = "Logout Successfull";
    //   state.userToken = "";
    //   state.isLoggedIn = false;
    //   state.islogout = true;
    // }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getActiveProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveProject.fulfilled, (state, action) => {
        state.status = "idle";
        state.activeProjectList = action.payload.data;
      });
  },
});

export default activeSlice.reducer;

