import { useState } from "react";
import Avatar from "react-avatar";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./Header.scss";

import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import logo from "../Header/logo.svg";
import { photoURL } from "../../../Helper/Helper";
import { logout } from "../../../Redux/AuthSlice";

export default function Header() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const { isLoggedIn, loggedInUserDetails } = useSelector(
    (state) => state.Auth
  );
  const { popup } = useSelector((state) => state?.CmsDetails);
  const { inactiveProjectList } = useSelector((state) => state.product);
  const { activeProjectList } = useSelector((state) => state.ActiveSlice);

  const ShowSidebar = () => {
    document.body.classList.add("body-active");
  };

  const CloseSidebar = () => {
    document.body.classList.remove("body-active");
  };

  const logoutHandler = () => {
    setIsLogoutDialogOpen(false);

    history.push("/");
    dispatch(logout());
  };

  return (
    <>
      <header className="header">
        <div className="headerInner">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="headerRight">
            <button type="button" className="closebtn" onClick={CloseSidebar}>
              <CancelOutlinedIcon />
            </button>
            <div className="navbarsec">
              <ul style={{ marginBottom: "0px" }}>
                <li>
                  <NavLink activeClassName="header_active" to="/showroom">
                    Our Models
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="header_active" to="/howitwork">
                    How it works{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="header_active" to="/about">
                    About us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="loginSignup">
              <ul className="loginregbtn" style={{ marginBottom: "0px" }}>
                {isLoggedIn ? (
                  <li>
                    <Dropdown className="dropdown">
                      <Dropdown.Toggle
                        variant="secondary"
                        className="whbtn dropdownbtn"
                        id="dropdown-basic"
                      >
                        {loggedInUserDetails?.profile_image ? (
                          <img
                            style={{
                              borderRadius: "50%",
                              height: "45px",
                              width: "45px",
                            }}
                            src={`${photoURL}/${loggedInUserDetails?.profile_image}`}
                            alt=""
                          />
                        ) : (
                          <Avatar
                            name={loggedInUserDetails?.fullname}
                            size="40"
                            textSizeRatio={0.9}
                            round={true}
                            style={{ marginRight: "5px" }}
                          />
                        )}

                        {loggedInUserDetails?.fullname ?? ""}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="profiledrop">
                        <Dropdown.Item
                          onClick={() => history.push("/update-User-profile")}
                        >
                          <PersonIcon /> Profile
                        </Dropdown.Item>
                        {loggedInUserDetails?.register_type ===
                        "google" ? null : (
                          <Dropdown.Item
                            onClick={() => history.push("/change-password")}
                          >
                            <LockResetIcon /> Change Password
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={() => {
                            history.push("/activeproject");
                            localStorage.setItem("activeproject", true);
                            localStorage.removeItem("product_id");
                            localStorage.removeItem("item_id");
                          }}
                        >
                          <PlaylistAddCheckIcon />
                          Active project list{" "}
                          <span style={{ color: "black", fontWeight: "600" }}>
                            ({activeProjectList?.data?.length ?? 0})
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            history.push("/list");
                            localStorage.setItem("activeproject", false);
                            localStorage.removeItem("item_id");
                          }}
                        >
                          <ListAltIcon />
                          Inactive project list{" "}
                          <span style={{ color: "black", fontWeight: "600" }}>
                            ({inactiveProjectList?.length ?? 0})
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setIsLogoutDialogOpen(true)}
                        >
                          <LogoutIcon /> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                ) : (
                  <li>
                    <Link to="/login">
                      <button type="button" className="whbtn">
                        <img src="./images/useric.svg" alt="" />
                        Login
                      </button>
                    </Link>
                  </li>
                )}

                {!isLoggedIn && (
                  <li>
                    <button
                      type="button"
                      className="graybtn"
                      onClick={() => setShow(true)}
                    >
                      Get Started
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="menubtn">
            <button type="button" onClick={ShowSidebar}>
              <MenuOutlinedIcon />
            </button>
          </div>
        </div>
      </header>
      <div className="overlay" onClick={CloseSidebar}>
        &nbsp;
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        className="signupModal"
      >
        <Modal.Body>
          <div className="signupmodalInner">
            <h4>
              {popup?.data?.data?.title !== "" ? (
                <>{popup?.data?.data?.title}</>
              ) : (
                <></>
              )}
            </h4>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${popup?.data?.data?.content}`,
                }}
              ></div>
            </p>
            <Link to="/signup">
              <button type="button" onClick={() => setShow(false)} className="btnwh">
                Create Your Account
              </button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      <Dialog
        open={isLogoutDialogOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsLogoutDialogOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsLogoutDialogOpen(false)}>
            Stay Login
          </Button>
          <Button onClick={logoutHandler}>Log Out</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
