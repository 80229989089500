import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../Helper/Helper";

export const getShowroomlist = createAsyncThunk(
  "api/showroom/getlist",
  async (data) => {
    const response = await ApiHelperFunction({
      urlPath: `/api/showroom/getlist`,
      method: "POST",
      formData: data,
    });

    const {  data: responseData } = response.data;
    return responseData;
  }
);

const initialState = {
  status: "idle",
  singleShowroomlist: [],
  error: null,
};

export const showroomSlice = createSlice({
  name: "showroom",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShowroomlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getShowroomlist.fulfilled, (state, { payload }) => {
        state.status = "idle";

        state.singleShowroomlist = payload;
      })
      .addCase(getShowroomlist.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default showroomSlice.reducer;
// export const {  } = showroomSlice.actions;
