/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";

import { Dropdown, DropdownButton } from "react-bootstrap";
import { Box, Button, Dialog } from "@material-ui/core";

import "date-fns";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

import styled from "styled-components";
import { toast } from "react-toastify";
import "./SelectDesign.scss";
import "react-toastify/dist/ReactToastify.css";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ClickAwayListener } from "@mui/base";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { showroomDetailsById } from "../../Redux/CmsSlice";
import { product_details, getTime } from "../../Redux/ProductSlice";

import CommonHeader from "./CommonHeader";

import { API_URL, ApiHelperFunction } from "../../Helper/Helper";
import { checkTimeAvalableOrNot } from "../../Helper/utils";
import {
  addNewAddress,
  getProfileDetails,
  removeAddress,
} from "../../services/functions/user.api";
import SmallIcon from "../smallIcon/SmallIcon";
import { setUserDetails } from "../../Redux/AuthSlice";

const StyledDialog = styled(Dialog)`
  .deleteModalWrapper {
    padding: 20px;
  }
  .buttonGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .noBtn {
    margin-left: 1rem;
    background: red;
    color: white;
    &:hover {
      background: blue;
    }
  }
  .yesBtn {
    background: green;
    color: white;
    &:hover {
      background: blue;
    }
  }
`;

toast.configure();

const timeArr = [
  "08:00 AM - 08:30 AM",
  "08:30 AM - 09:00 AM",
  "09:00 AM - 09:30 AM",
  "09:30 AM - 10:00 AM",
  "10:00 AM - 10:30 AM",
  "10:30 AM - 11:00 AM",
  "11:00 AM - 11:30 AM",
  "11:30 AM - 12:00 PM",
  "12:00 PM - 12:30 PM",
  "12:30 PM - 01:00 PM",
  "01:00 PM - 01:30 PM",
  "01:30 PM - 02:00 PM",
  "02:00 PM - 02:30 PM",
  "02:30 PM - 03:00 PM",
  "03:00 PM - 03:30 PM",
  "03:30 PM - 04:00 PM",
  "04:00 PM - 04:30 PM",
  "04:30 PM - 05:00 PM",
];

const SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <button>
      <ArrowBackIosIcon />
    </button>
  ),
  nextArrow: (
    <button>
      <ArrowForwardIosIcon />
    </button>
  ),
};

export default function ChooseDesign() {
  const params = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const [productId, setProductId] = useState();
  const [showroomId, setShowroomId] = useState();

  // ==================================================
  const [deleteAddress, setDeleteAddress] = useState({});
  const [addressDeleteModalOpen, setAddressDeleteModalOpen] = useState(false);
  const count = useRef(0);
  const { timeData, productdet: CurrentProductDetails } = useSelector(
    (state) => state?.product
  );
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [nav3, setNav3] = useState();
  const [nav4, setNav4] = useState();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [curadderss, setcuraddress] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeTitleValue, setTimeTilteValue] = useState(null);

  const [timesec, settimesec] = useState();
  const [pos, setpos] = useState();
  const [profileData, setProfileData] = useState({
    house_address: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [error, setError] = useState({});

  const [userAddresses, setUserAddresses] = useState();
  const showroomDetails = useSelector(
    (state) => state?.CmsDetails?.viewshowroom
  );

  const currentShowroomDetails = useSelector(
    (state) => state?.CmsDetails?.viewshowroom
  );
  const [sliderImages, setSliderImages] = useState([]);

  // set ShowroomId and ProductId
  useEffect(() => {
    const localShowroomId = localStorage.getItem("showroom_id");
    if (localShowroomId) setShowroomId(localShowroomId);

    if (!!params) {
      let _t = params?.pathname?.split("choosedesign");
      let _id = _t[0].split("/");
      setProductId(_id[1]);
    }
  }, [params]);

  // Fetch ShowroomDetails and ProductDetails
  useEffect(() => {
    if (showroomId) dispatch(showroomDetailsById(showroomId));

    if (productId) {
      dispatch(product_details(productId));
    }
  }, [dispatch, showroomId, productId]);

  // set Showroom slider (for images)
  useEffect(() => {
    if (currentShowroomDetails?.other_image) {
      const newarray = currentShowroomDetails.other_image.map(
        (x) => API_URL + "/uploads/showroom/" + x
      );

      setSliderImages(newarray);
    }
  }, [currentShowroomDetails]);

  useEffect(() => {
    if (CurrentProductDetails) {
      // for re-direct to step2
      if (
        (CurrentProductDetails?.basic_details?.project_step !== 1 &&
          CurrentProductDetails?.basic_details?.date !== null) ||
        CurrentProductDetails?.basic_details?.start_time !== "" ||
        CurrentProductDetails?.basic_details?.end_time !== ""
      ) {
        history.push("step2", { id: { productId } });
      }

      const UserAdditionalAddress =
        CurrentProductDetails?.basic_details?.user_id?.additional_address;
      const UserDefaultAddress =
        CurrentProductDetails?.basic_details?.user_id?.address;

      if (UserAdditionalAddress?.length) {
        const formattedData = UserAdditionalAddress.map((address) => {
          const formattedAddress = `${address?.house_address.trim()}${
            address?.house_address && address?.house_number && "-"
          }${address?.house_number}${
            address?.house_address && address?.house_number && ", "
          }${address?.city}${address?.city && address?.state && ", "}${
            address?.state
          }${address?.state && address?.zipcode && ","} ${address?.zipcode}`;
          return { formattedAddress, id: address._id };
        });
        formattedData.push(UserDefaultAddress);

        setUserAddresses(formattedData);
      }

      setcuraddress(UserDefaultAddress);

      // set setSelectedDate and time
      setSelectedDate(CurrentProductDetails.basic_details?.date);
      settimesec(CurrentProductDetails?.basic_details?.end_time);
      if (CurrentProductDetails?.basic_details?.time_position !== "") {
        setpos(CurrentProductDetails?.basic_details?.time_position);
      }
    }
  }, [history, productId, CurrentProductDetails]);

  // validation for startTime, endTime, curadderss
  const validation = () => {
    let error = {};
    if (startTime === undefined && endTime === undefined) {
      error.time_input = "This field is required";
    }
    if (curadderss === undefined) {
      error.selected_address = "This field is required";
    }

    return error;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleDisabledTime = () => {
    if (!selectedDate) {
      toast.success("Please Select a Date", {
        type: "warning",
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  const gotoNext = async () => {
    try {
      if (
        showroomId &&
        productId &&
        startTime !== undefined &&
        endTime !== undefined &&
        curadderss !== undefined
      ) {
        const Step1Prams = {
          showroom_id: showroomId,
          address: curadderss,
          date: new Date(selectedDate),
          start_time: startTime,
          end_time: endTime,
          project_id: productId,
          time_position: 0,
        };

        const response = await ApiHelperFunction({
          urlPath: `/api/project/createProject`,
          method: "POST",
          formData: Step1Prams,
        });

        const { status, data: step1DataResponse } = response?.data;

        if (status === 200 && step1DataResponse?.project_step === 2) {
          history.push("step2", { id: productId });
        }
      } else {
        let ErrorList = validation();
        setError(validation());
        if (Object.keys(ErrorList).length !== 0) {
          throw new Error(ErrorList);
        }
      }
    } catch (error) {
      toast.success(error.message, {
        type: "warning",
        hideProgressBar: true,
        autoClose: 2500,
      });
    }
  };

  // Calcculation Related to MODAL
  const postUserData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProfileData({ ...profileData, [name]: value });
  };

  const CheckAddressValidation = () => {
    let error = {};

    if (
      profileData.house_address
        .replace(profileData.house_address.replace(/[^0-9]/g, ""), "")
        .replace(/[^a-zA-Z ]/g, "") === "" ||
      profileData.house_address.replace(/[^0-9]/g, "") === ""
    ) {
      error.house_address = "House address and house number is required";
    }
    if (!profileData.city) {
      error.city = "City is required";
    }
    if (!profileData.state) {
      error.state = "State is required";
    }

    if (!profileData.zipcode) {
      error.zipcode = "Zipcode is required";
    }
    // else if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(profileData.zipcode)) {
    //   error.zipcode = "Enter a valid zip code";
    // }
    return error;
  };

  const addAddressHandler = async (e) => {
    e.preventDefault();

    try {
      const ErrorList = CheckAddressValidation();

      if (Object.keys(ErrorList).length === 0) {
        const houseNumber = profileData.house_address.replace(/[^0-9/]/g, "");
        // const houseAddress = profileData.house_address
        //   .replace(profileData.house_address.replace(/[^0-9/]/g, ""), "")
        //   .replace(/[^a-zA-Z ]/g, "");
        const houseAddress = profileData.house_address
          .replace(houseNumber, "")
          .replace(/[^a-zA-Z ]/g, "");

        const formData = new FormData();
        formData.append("house_number", houseNumber);
        formData.append("house_address", houseAddress);
        formData.append("city", profileData.city);
        formData.append("state", profileData.state);
        formData.append("zipcode", profileData.zipcode);

        const { status, data, message } = await addNewAddress(formData);
        console.log(" status: ", status, data, message);

        if (status === 200) {
          toast.success(message);
          setShow(false);

          const loggedInUserDetails = await getProfileDetails();

          const { status: profileStatus, data: profileData } =
            loggedInUserDetails;
          alert("getProfileDetails Called! ");

          if (profileStatus === 200) {
            dispatch(setUserDetails(profileData[0]));
          } else throw new Error(error.message);
        } else if (message === "This address already exists") {
          toast.warn(message);
        } else throw new Error(message);
      } else {
        setError(ErrorList);
      }
    } catch (error) {
      toast.error(error);
      setShow(false);
    }
  };

  const removeAddressHandler = async (address) => {
    try {
      const { data, status, message } = await removeAddress(address.id);

      if (status === 200) {
        toast.success("Address Deleted.");
        dispatch(product_details(productId));
        setAddressDeleteModalOpen(false);
      } else throw new Error(message);
    } catch (error) {
      console.log("Remove Address Error: ", error);
      toast.warning("Something went wrong");
    }
  };

  const handleClickAway = () => {
    setShow1(false);
  };

  // ////////////////
  useEffect(() => {
    if (timeData) {
      setStartTime(timeData?.data?.data[0]?.start_time);
      setEndTime(timeData?.data?.data[0]?.end_time);
    }
  }, [timeData]);

  // for disabling the already booked time
  useEffect(() => {
    let formData = new FormData();
    if (selectedDate) {
      formData.append("date", moment(selectedDate)?.format("YYYY-MM-DD"));
      dispatch(getTime(formData));
    }
  }, [dispatch, selectedDate]);

  // set startTime and endTime
  useEffect(() => {
    if (typeof selectedTime === "number") {
      var timeCalculation = timeArr[selectedTime];
      timeCalculation = timeCalculation?.split(" -");
      setStartTime(timeCalculation[0]);
      setEndTime(timeCalculation[1]);
    }
  }, [selectedTime]);

  useEffect(() => {
    if (pos !== undefined) {
      if (pos === 0) {
        // setTimeString("08:00 AM");
        settimesec("08:30 AM");
      } else if (pos === 1) {
        // setTimeString("08:30 AM");
        settimesec("09:00 AM");
      } else if (pos === 2) {
        // setTimeString("09:00 AM");
        settimesec("09:30 AM");
      } else if (pos === 3) {
        // setTimeString("09:30 AM");
        settimesec("10:00 AM");
      } else if (pos === 4) {
        // setTimeString("10:00 AM");
        settimesec("10:30 AM");
      } else if (pos === 5) {
        // setTimeString("10:30 AM");
        settimesec("11:00 AM");
      } else if (pos === 6) {
        // setTimeString("11:00 AM");
        settimesec("11:30 AM");
      } else if (pos === 7) {
        // setTimeString("11:30 AM");
        settimesec("12:00 PM");
      } else if (pos === 8) {
        // setTimeString("12:00 PM");
        settimesec("12:30 PM");
      } else if (pos === 9) {
        // setTimeString("12:30 PM");
        settimesec("01:00 PM");
      } else if (pos === 10) {
        // setTimeString("01:00 PM");
        settimesec("01:30 PM");
      } else if (pos === 11) {
        // setTimeString("01:30 PM");
        settimesec("02:00 PM");
      } else if (pos === 12) {
        // setTimeString("02:00 PM");
        settimesec("02:30 PM");
      } else if (pos === 13) {
        // setTimeString("02:30 PM");
        settimesec("03:00 PM");
      } else if (pos === 14) {
        // setTimeString("03:00 PM");
        settimesec("03:30 PM");
      } else if (pos === 15) {
        // setTimeString("03:30 PM");
        settimesec("04:00 PM");
      } else if (pos === 16) {
        // setTimeString("04:00 PM");
        settimesec("04:30 PM");
      } else if (pos === 17) {
        // setTimeString("04:30 PM");
        settimesec("05:00 PM");
      }
    }
  }, [pos]);

  // useEffect(() => {
  //   let arrTime = [];
  //   timeArr?.map((x) => {
  //     arrTime.push(checkTimeAvalableOrNot(x, timeData, selectedDate));
  //   });

  //   if (arrTime?.includes(false)) {
  //     setTimeTilteValue("Please Select");
  //   } else {
  //     setTimeTilteValue("No Data Available");
  //   }
  // }, [timeData, selectedDate]);
  useEffect(() => {
    const isAnyTimeAvailable = timeArr.some((x) =>
      checkTimeAvalableOrNot(x, timeData, selectedDate)
    );

    setTimeTilteValue(
      isAnyTimeAvailable ? "No Data Available" : "Please Select"
    );
  }, [timeData, selectedDate]);

  const TimeDropdownTitle = useMemo(() => {
    const basicDetails = CurrentProductDetails?.data?.data?.basic_details;
    const startTime = basicDetails?.start_time;
    const endTime = basicDetails?.end_time;

    let titleContent;
    if (!startTime) {
      titleContent =
        selectedTime !== null ? timeArr[selectedTime] : timeTitleValue;
    } else {
      titleContent = (
        <>
          {startTime} - {endTime}
        </>
      );
    }
    return titleContent;
  }, [
    CurrentProductDetails?.data?.data?.basic_details,
    timeTitleValue,
    selectedTime,
  ]);

  return (
    <>
      <CommonHeader currentProjectStep={1} />

      <div className="selectaddPrice">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="selectAddress slec2">
                <label>Select Address</label>

                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic-button"
                    className="select-des"
                  >
                    {curadderss === undefined ? "Add address" : curadderss}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-list">
                    {userAddresses?.map((address, index) => {
                      const shouldRenderXButton =
                        index < userAddresses.length - 1;
                      const handleClickX = () => {
                        setAddressDeleteModalOpen(true);
                        setDeleteAddress(address);
                      };

                      return (
                        <Dropdown.Item
                          key={address.id}
                          onClick={() =>
                            setcuraddress(address.formattedAddress)
                          }
                          style={{ textTransform: "capitalize" }}
                        >
                          {address.formattedAddress}
                          {shouldRenderXButton && (
                            <button onClick={handleClickX}> X </button>
                          )}
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.Item
                      style={{ color: "rgb(12 54 115)" }}
                      onClick={() => setShow(true)}
                    >
                      + Add Address
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div style={{ color: "red" }}>{error.selected_address}</div>
              <div className="designDetail">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="lftimgslider">
                      <Slider
                        className="for"
                        {...SLIDER_SETTINGS}
                        asNavFor={nav2}
                        ref={(slider1) => setNav1(slider1)}
                        infinite={false}
                      >
                        {sliderImages.length ? (
                          sliderImages.map((imageUrl, index) => (
                            <div key={index}>
                              <img
                                src={imageUrl}
                                alt="slider-img"
                                onClick={() => {
                                  setShow1(sliderImages || "/images/blank.png");
                                }}
                              />
                            </div>
                          ))
                        ) : (
                          <div>
                            <img src="/images/blank.png" alt="no-image" />
                          </div>
                        )}
                      </Slider>

                      <Slider
                        className="nav"
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        infinite={false}
                        slidesToShow={
                          sliderImages?.length >= 6 ? 4 : sliderImages.length
                        }
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={false}
                      >
                        {sliderImages.length ? (
                          sliderImages.map((imageURL, index) => (
                            <div key={index}>
                              <img src={imageURL} alt="" />
                            </div>
                          ))
                        ) : (
                          <div>
                            <img src="./images/blank.png" alt="blank" />
                          </div>
                        )}
                      </Slider>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="sliderRightsec">
                      <h2>{showroomDetails?.title}</h2>
                      <ul className="showroomdetail">
                        <li>
                          <ul className="bedbath1">
                            {showroomDetails?.showroom_floor_plan?.map((id) => (
                              <>
                                {id.bedroom_numbers && (
                                  <>
                                    <SmallIcon img="bed.svg" />
                                    {id.bedroom_numbers} <span>Bedroom</span>
                                  </>
                                )}
                                {"   "}
                                {id.bathroom_numbers && (
                                  <>
                                    <SmallIcon img="bath.svg" />
                                    {id.bathroom_numbers}
                                    <span style={{ marginLeft: "4px" }}>
                                      Bathroom
                                    </span>
                                  </>
                                )}
                              </>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <span className="icon">
                            <img src="/images/detailic3.svg" alt="" />
                          </span>
                          <span className="detailHeading">
                            Package Includes
                          </span>
                          {showroomDetails?.packge_includes}
                        </li>
                        <li>
                          <span className="icon">
                            <img src="/images/detailic4.svg" alt="" />
                          </span>
                          <span className="detailHeading">Estimated Size</span>~
                          {showroomDetails?.estimated_size} sqft
                        </li>
                      </ul>
                      <button
                        type="button"
                        onClick={() => {
                          history.push({
                            pathname: "/showroom",
                            state: {
                              product_id: productId,
                            },
                          });
                        }}
                        className="changedesignbtn btn"
                      >
                        Change Design
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="priceTime">
                <div className="startingat">
                  <h3>Starting at</h3>
                </div>
                <div className="pricetag">
                  <h2>
                    {showroomDetails?.estimated_cost?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </h2>
                </div>

                <div className="startingat">
                  <h3>Schedule a consultation</h3>
                </div>

                <div className="dateTime">
                  <ul>
                    <li className="choosedate">
                      <label>Date</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          minDate={new Date()}
                          placeholder="please Select"
                          disablePast
                          error={false}
                          helperText={null}
                          value={
                            selectedDate === undefined
                              ? undefined
                              : selectedDate
                          }
                          format=" MM/dd/yyyy"
                          onChange={handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </li>
                    <li>
                      <label>Time</label>

                      <div>
                        <DropdownButton
                          onClick={handleDisabledTime}
                          id="dropdown-basic-button"
                          className="timedrop"
                          disabled={!selectedDate}
                          title={TimeDropdownTitle ?? ""}
                        >
                          {timeArr?.length &&
                            timeArr?.map((x, key) => (
                              <div>
                                <Dropdown.Item
                                  disabled={checkTimeAvalableOrNot(
                                    x,
                                    timeData,
                                    selectedDate
                                  )}
                                  onClick={() => {
                                    setSelectedTime(key);
                                  }}
                                >
                                  {x}
                                </Dropdown.Item>
                              </div>
                            ))}
                        </DropdownButton>
                      </div>
                    </li>
                  </ul>
                  <div className="nxtbtn">
                    <button
                      type="button"
                      className="btn seduleBtn_main"
                      onClick={() => gotoNext()}
                    >
                      Schedule Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        centered
        className="addLocation addloc2"
      >
        <Modal.Body>
          <div className="form-group">
            <label>Add New Address</label>

            <div className="form_input">
              <input
                type="text"
                placeholder="House Number And Address"
                required
                value={profileData.house_address}
                onChange={postUserData}
                name="house_address"
              />
              {error.house_address && (
                <div className="text-danger d-flex">
                  <span
                    style={{
                      marginLeft: "0px",
                      color: "red",
                      marginBottom: "10px",
                    }}
                    className="error_txt"
                  >
                    {error.house_address}
                  </span>
                </div>
              )}
            </div>

            <div className="form_input">
              <input
                type="text"
                placeholder="City Name"
                required
                value={profileData.city}
                onChange={postUserData}
                name="city"
              />
              {error.city && (
                <div className="text-danger d-flex">
                  <span
                    style={{
                      marginLeft: "0px",
                      color: "red",
                      marginBottom: "10px",
                    }}
                    className="error_txt"
                  >
                    {" "}
                    {error.city}{" "}
                  </span>
                </div>
              )}
            </div>

            <div className="form_input">
              <input
                type="text"
                placeholder="State Name"
                required
                value={profileData.state}
                onChange={postUserData}
                name="state"
              />
              {error.state && (
                <div className="text-danger d-flex">
                  <span
                    style={{
                      marginLeft: "0px",
                      color: "red",
                      marginBottom: "10px",
                    }}
                    className="error_txt"
                  >
                    {error.state}
                  </span>
                </div>
              )}
            </div>

            <div className="form_input">
              <input
                type="text"
                placeholder="Zipcode"
                required
                value={profileData.zipcode}
                onChange={postUserData}
                name="zipcode"
              />
              {error.zipcode && (
                <div className="text-danger d-flex">
                  <span
                    style={{
                      marginLeft: "0px",
                      color: "red",
                      marginBottom: "10px",
                    }}
                    className="error_txt"
                  >
                    {error.zipcode}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="btngrp">
            <button type="submit" onClick={addAddressHandler} className="btn">
              Submit
            </button>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="cancelbtn"
            >
              cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        ref={count}
        show={show1}
        onHide={() => setShow(false)}
        animation={false}
        centered
        className="addLocation"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Modal.Body
            style={{
              backgroundColor: "#b1aeba",
              borderRadius: "12px",
              height: "550px",
            }}
          >
            <button
              type="button"
              onClick={() => setShow1(false)}
              className="closeBtn"
            >
              x
            </button>

            <Slider
              className="for"
              {...SLIDER_SETTINGS}
              asNavFor={nav4}
              ref={(slider3) => setNav3(slider3)}
              infinite={false}
              style={{ height: "340px", marginBottom: "20px" }}
            >
              {sliderImages.length ? (
                sliderImages.map((imageURL, index) => (
                  <img
                    key={index}
                    src={imageURL}
                    alt="showroom-floor-img"
                    style={{
                      height: "240px",
                      // width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                ))
              ) : (
                <img src="/images/blank.png" alt="no-image" />
              )}
            </Slider>

            <Slider
              className="nav"
              style={{ marginTop: "10px", height: "100px" }}
              asNavFor={nav3}
              ref={(slider4) => setNav4(slider4)}
              infinite={false}
              slidesToShow={sliderImages?.length >= 4 ? 4 : sliderImages.length}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
            >
              {sliderImages.length ? (
                sliderImages.map((imageURL, index) => (
                  <img
                    key={index}
                    style={{
                      height: "75px",
                      margin: "15px 2px 0 0",
                    }}
                    src={imageURL}
                    alt=""
                    onClick={() => setShow1(sliderImages)}
                  />
                ))
              ) : (
                <img src="/images/blank.png" alt="no-image" />
              )}
            </Slider>
            <div className="btngrp"></div>
          </Modal.Body>
        </ClickAwayListener>
      </Modal>

      <StyledDialog
        onClose={() => setAddressDeleteModalOpen(false)}
        open={addressDeleteModalOpen}
      >
        <Box className="deleteModalWrapper">
          <h4>Are you sure you want to delete this address?</h4>
          <Box className="buttonGroup">
            <Button
              className="yesBtn"
              onClick={() => {
                removeAddressHandler(deleteAddress);
              }}
            >
              Yes
            </Button>
            <Button
              className="noBtn"
              onClick={() => setAddressDeleteModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </StyledDialog>
    </>
  );
}
