import React, { useState, useEffect } from "react";
import { resetPassword } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

import "./ResetPassword.scss";
import { Link, useHistory } from "react-router-dom";

export default function ForgotPassword() {
  const [userData, setuserData] = useState({
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  const { status, successfullreset, otp, email, successfullotp } = useSelector(
    (e) => e.Auth
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const validation = () => {
    let error = {};
    if (!userData.password) {
      error.password = "Password is required";
    } else if (userData.password.length < 8) {
      error.password = "Minimum 8 characters";
    }

    if (!userData.confirm_password) {
      error.confirm_password = "Confirm Password is required";
    } else if (userData.confirm_password.length < 8) {
      error.confirm_password = "Minimum 8 characters";
    } else if (userData.password !== userData.confirm_password) {
      error.confirm_password = "Password Doesn't Match";
    }

    return error;
  };

  const submitdata = (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      const user = {
        password: userData.password,
        confirm_password: userData.confirm_password,
        otp: otp,
        email: email,
      };
      dispatch(resetPassword(user));
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // useEffect(() => {
  //   if (successfullreset === false) {
  //     history.push("/login");
  //   }
  // }, [successfullreset]);

  useEffect(() => {
    if (localStorage.getItem("pointb_token")) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (successfullreset === true) {
      history.push("/login");
    } else {
      setuserData({
        password: "",
        confirm_password: "",
      });
    }
  }, [successfullreset]);
  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>Reset Password</h2>

            <br />
            <div className="form-group">
              <label>Your Password</label>
              <input
                type="password"
                placeholder="Your Password"
                name="password"
                value={userData.password}
                onChange={postUserData}
              />
              <div className="error">{error.password}</div>
            </div>
            <div className="form-group">
              <label>Your Confirm Password</label>
              <input
                type="password"
                placeholder="Your Confirm Password"
                name="confirm_password"
                value={userData.confirm_password}
                onChange={postUserData}
              />
              <div className="error">{error.confirm_password}</div>
            </div>

            {status === true ? (
              <div className="loginbtn">
                <button
                  type="button"
                  disabled={status}
                  onClick={submitdata}
                  className="btn"
                >
                  Loading
                </button>
              </div>
            ) : (
              <div className="loginbtn">
                <button type="button" onClick={submitdata} className="btn">
                  Reset Password?
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
