/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { signup, socialSignup } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Signup.scss";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";

import firebase, { auth } from "../../firebaseConfig";

export default function Signup() {
  // const clientId =
  //"517671913185-6noj9hhnh7ftfcsrsqvmnkebuem1dtuj.apps.googleusercontent.com";
  // "38608546965-2nh53mrebi2r8l2prlq6ockp9fkm2btk.apps.googleusercontent.com";

  const [userData, setuserData] = useState({
    fullname: "",
    email: "",
    phone: "",
    password: "",
    city: "",
    address: "",
    zipcode: "",
  });
  const [location, setlocation] = useState(0);
  const [time, settime] = useState(0);
  const [error, setError] = useState({});
  const [google, setgoogle] = useState();
  const { status, userToken } = useSelector((e) => e.Auth);

  const dispatch = useDispatch();
  const history = useHistory();

  // const onLoginFailure = (res) => {
  //   console.log(res, "res");
  // };

  // const onLoginSuccess = (res, e) => {
  //   console.log(res, "res");
  //   const user = {
  //     fullname: res?.profileObj?.givenName + " " + res?.profileObj?.familyName,
  //     email: res?.profileObj?.email,
  //     social_id: res?.profileObj?.googleId,
  //     register_type: "google",
  //   };
  //   setgoogle(user);
  //   setuserData({ fullname: user?.fullname });
  // };

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
    setgoogle({ ...google, [name]: value });
  };

  const handleChangePhone = (e) => {
    const value = e.target.value;
    // setValues({ ...values, phone: value });
    const newValue = value.replace(/\D/g, "").substring(0, 10);
    setuserData({ ...userData, phone: formatPhoneNumber(newValue) });
    setgoogle({ ...google, phone: formatPhoneNumber(newValue) });
  };

  const formatPhoneNumber = (input) => {
    if (!input || isNaN(input)) return "";
    if (typeof input !== "string") input = input.toString();
    return input.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  const handleChangeLocation = (e) => {
    setlocation(e.target.value);
  };

  const handleChangeTime = (e) => {
    settime(e.target.value);
  };

  const validation = () => {
    let error = {};
    if (!google?.register_type) {
      if (!userData.email) {
        error.email = "Email is required";
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          userData.email
        )
      ) {
        error.email = "Enter a valid email";
      }
    }

    if (!userData.fullname) error.fullname = "Name is required";
    else if (/\d/.test(userData.fullname))
      error.fullname = "Please enter a valid Name";

    if (!google?.register_type) {
      if (!userData.password) {
        error.password = "Password is required";
      } else if (userData.password.length < 8) {
        error.password = "Minimum 8 characters";
      }
    }
    if (userData.phone === "") {
      error.phone = "Phone number is required";
    }
    // else if (!/^([+]\d{2})?\d{10}$/.test(userData.phone)) {
    //   error.phone = "Invalid Phone Number";
    // }
    if (!userData.city) error.city = "City name is required";
    if (!userData.address) error.address = "Address is required";
    if (!userData.zipcode) error.zipcode = "Zipcode is required";
    // else if (!/^[0-9]{6}(?:-[0-9]{4})?$/.test(userData.zipcode)) {
    //   error.zipcode = "Invalid Zipcode";
    // }
    if (location === 0) {
      error.location = "Select any one";
    }
    if (time === 0) {
      error.time = "Select any one";
    }

    return error;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const submitdata = (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      if (!google?.register_type) {
        const user = {
          fullname: userData.fullname,
          password: userData.password,
          email: userData.email,
          phone: userData.phone,
          city: userData.city,
          address: userData.address,
          zipcode: userData.zipcode,
          estimated_buildable_lot: location,
          when_would_you_start: time,
        };

        dispatch(signup(user));
      } else {
        // fullname: res?.profileObj?.givenName + " " + res?.profileObj?.familyName,
        // email: res?.profileObj?.email,
        // social_id: res?.profileObj?.googleId,
        // register_type: "google",
        const user = {
          fullname: google?.fullname,
          social_id: google?.social_id,
          register_type: "google",
          email: google?.email,
          phone: userData.phone,
          city: userData.city,
          address: userData.address,
          zipcode: userData.zipcode,
          estimated_buildable_lot: location,
          when_would_you_start: time,
          type: "register",
        };
        dispatch(socialSignup(user));
      }
    }
  };
  const handleAppleLogin = () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    console.log("provider", provider);
    auth
      .signInWithPopup(provider)
      .then((result) => {
        const loginSocial = {
          fullname: result?.user?._delegate?.displayName,
          email: result?.user?._delegate?.email,
          social_id: result?.user?._delegate?.uid,
          register_type: "apple",
          type: "signup",
        };

        dispatch(socialSignup(loginSocial));
      })
      .catch((error) => {
        console.log("apple error,", error);
      });
  };

  // social login /////////
  // const login = useGoogleLogin({
  //   onSuccess: async (respose) => {
  //     //console.log(respose, "respose");

  //     try {
  //       const res = await axios.get(
  //         "https://www.googleapis.com/oauth2/v3/userinfo",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${respose.access_token}`,
  //           },
  //         }
  //       );

  //       const loginSocial = {
  //         fullname: res?.data?.name,
  //         email: res?.data?.email,
  //         social_id: res?.data?.sub,
  //         register_type: "google",
  //         type: "register",
  //       };

  //      dispatch(socialSignup(loginSocial));

  //       //console.log(res.data, "resttt");
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   },
  // });

  const handleGoogleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    // console.log("provider", provider);
    auth
      .signInWithPopup(provider)
      .then((result) => {
        // console.log(result,'resssssssssss')
        // console.log(result?.user?._delegate?.displayName,'check');
        // console.log(result?.user?._delegate?.email,'check');
        // console.log(result?.user?._delegate?.uid,'check');

        const loginSocial = {
          fullname: result?.user?._delegate?.displayName,
          email: result?.user?._delegate?.email,
          social_id: result?.user?._delegate?.uid,
          register_type: "google",
          type: "signup",
        };

        dispatch(socialSignup(loginSocial));
      })
      .catch((error) => {
        console.log("apple error,", error);
      });
  };

  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>Sign Up</h2>

            <div className="socialLogin">
              <button type="button" onClick={() => handleGoogleLogin()}>
                <img src="./images/google.svg" alt="" /> Sign Up With Google
                {/* <GoogleLogin
                  id="socialLogin1"
                  clientId={clientId}
                  buttonText="Login with Google"
                  onSuccess={onLoginSuccess}
                  onFailure={onLoginFailure}
                  cookiePolicy={"single_host_origin"}
                /> */}
              </button>
              <button type="button" onClick={handleAppleLogin}>
                <img
                  src="./images/apple.svg"
                  alt=""
                  style={{ width: "25px" }}
                />
                Sign Up With Apple
              </button>
            </div>
            <div className="ortag">
              <span>OR</span>
            </div>

            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Full Name"
                name="fullname"
                value={
                  userData.fullname === ""
                    ? google?.fullname !== undefined
                      ? google?.fullname
                      : ""
                    : userData.fullname
                }
                onChange={postUserData}
              />
              <div className="error">{error.fullname}</div>
            </div>

            <div className="form-group">
              <label>Your Email</label>

              {google?.email !== undefined && userData.email === undefined ? (
                <input
                  type="email"
                  disabled
                  placeholder="Your Email"
                  name="email"
                  value={google?.email}
                  onChange={postUserData}
                />
              ) : (
                <input
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={userData.email}
                  onChange={postUserData}
                />
              )}

              <div className="error">{error.email}</div>
            </div>

            {google?.email !== undefined &&
            google?.fullname !== undefined &&
            userData.password === undefined ? (
              ""
            ) : (
              <div className="form-group">
                <label>Your Password</label>
                <input
                  type="password"
                  placeholder="Your Password"
                  name="password"
                  value={userData.password}
                  onChange={postUserData}
                />
                <div className="error">{error.password}</div>
              </div>
            )}

            <div className="form-group" id="phn_no">
              <label>Phone number</label>
              <input
                type="text"
                placeholder="Phone number"
                name="phone"
                value={userData.phone}
                onChange={(e) => handleChangePhone(e)}
              />
              <div className="error">{error.phone}</div>
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                placeholder="Your Address"
                name="address"
                value={userData.address}
                onChange={postUserData}
              />
              <div className="error">{error.address}</div>
            </div>
            <div className="form-group" id="phn_no">
              <label>Zip Code</label>
              <input
                type="number"
                placeholder="Zip Code"
                name="zipcode"
                value={userData.zipcode}
                onChange={postUserData}
              />
              <div className="error">{error.zipcode}</div>
            </div>
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                placeholder="Your City"
                name="city"
                value={userData.city}
                onChange={postUserData}
              />
              <div className="error">{error.city}</div>
            </div>
            <div className="form-group">
              <label>Estimated sqft in your backyard</label>
              <select value={location} onChange={handleChangeLocation}>
                <option value="0">Choose One</option>
                <option value="1">400 - 600 sqft</option>
                <option value="2">600 - 900 sqft</option>
                <option value="3">900 - 1200 sqft</option>
                <option value="4">1200 sqft+</option>
              </select>
              <div className="error">{error.location}</div>
            </div>
            <div className="form-group">
              <label>When would you like to start?</label>
              <select value={time} onChange={handleChangeTime}>
                <option value="0">Choose One</option>
                <option value="1">Immediately</option>
                <option value="2">3 months from now</option>
                <option value="3">6 months from now</option>
                <option value="4">12+ months from now</option>
              </select>
              <div className="error">{error.time}</div>
            </div>
            {status === true ? (
              <div className="loginbtn">
                <button
                  type="button"
                  disabled={status}
                  onClick={submitdata}
                  className="btn"
                >
                  Loading
                </button>
              </div>
            ) : (
              <div className="loginbtn">
                <button type="button" onClick={submitdata} className="btn">
                  Submit
                </button>
              </div>
            )}

            <p className="loginLink">
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
