import React, { useState, useEffect } from "react";
import { forgotPassword } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import "./ForgotPassword.scss";
import {useHistory } from "react-router-dom";

export default function ForgotPassword() {
  const [userData, setuserData] = useState({
    email: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  const { status, successfullforgot } = useSelector((e) => e.Auth);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (successfullforgot === true) {
      history.push("/otp-verification");
    }
  }, [successfullforgot]);

  useEffect(() => {
    if (localStorage.getItem("pointb_token")) {
      history.push("/");
    }
  }, []);

  const validation = () => {
    let error = {};
    if (!userData.email) {
      error.email = "Email is required";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userData.email
      )
    ) {
      error.email = "Enter a valid email";
    }

    return error;
  };

  const submitdata = (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      // if (isLoggedIn === true) {
      const user = {
        email: userData.email,
      };
      dispatch(forgotPassword(user));
    }
  };
  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>Forgot Password</h2>
            {/* <p>
              Provide the email address associated with your account and we’ll
              send you a link to log in.
            </p> */}

            <br />
            <div className="form-group">
              <label>Your Email</label>
              <input
                type="email"
                placeholder="Your Email"
                name="email"
                value={userData.email}
                onChange={postUserData}
              />
              <div className="error">{error.email}</div>
            </div>

            {status === true ? (
              <div className="loginbtn">
                <button
                  type="button"
                  disabled={status}
                  onClick={submitdata}
                  className="btn"
                >
                  Loading
                </button>
              </div>
            ) : (
              <div className="loginbtn">
                <button type="button" onClick={submitdata} className="btn">
                  Forgot Password?
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
