import React from "react";

const steps = [
  {
    stepCount: 1,
    title: "Select ADU Design & Schedule Consultation",
  },
  {
    stepCount: 2,
    title: "Review Quote & Consultation",
  },
  {
    stepCount: 3,
    title: "Sign Contract",
  },
  {
    stepCount: 4,
    title: "Payment",
  },
];

const CommonHeader = (props) => {
  const {
    currentProjectStep,
    isCompleted,
    redirectToStep3,
    redirectToStep4,
    redirectToStep,
  } = props;

  return (
    <div className="gapsm">
      <div className="container">
        <div className="stepsec">
          <ul>
            {steps.map((item) => (
              <li
                key={item.title}
                className={
                  isCompleted >= item.stepCount ||
                  currentProjectStep > item.stepCount
                    ? "active pe-auto"
                    : ""
                }
                style={{ cursor: "pointer" }}
              >
                <button
                  onClick={() =>
                    currentProjectStep === 3
                      ? redirectToStep4()
                      : redirectToStep3()
                  }
                  disabled={redirectToStep !== item.stepCount}
                  className="number"
                >
                  <span>{item.stepCount}</span>
                </button>
                <p>{item.title}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CommonHeader;
