import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Otp from "./Components/Otp/Otp";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Login from "./Components/Login/Login";
import Cities from "./Components/Cities/Cities";
import Signup from "./Components/Signup/Signup";
import Privacy from "./Components/Privacy/Privacy";
import Home2 from "./Components/HomeNewDesign/Home2";
import Contact from "./Components/Contact Us/Contact";
import Showroom from "./Components/Showroom/Showroom";
import Footer from "./Components/Shared/Footer/Footer";
import Header from "./Components/Shared/Header/Header";
import Step2 from "./Components/SelectDesignStep/Step2";
import Step3 from "./Components/SelectDesignStep/Step3";
import Step4 from "./Components/SelectDesignStep/Step4";
import HowItWork from "./Components/HowItWork/HowItWork";
import Listproject from "./Components/Projectlist/Listproject";
import PaymentError from "./Components/PaymentError/PaymentError";
import PaymentSucess from "./Components/PaymentSucess/PaymentSucess";
import ActiveProject from "./Components/ActiveProject/ActiveProject";
import ChooseDesign from "./Components/SelectDesignStep/ChooseDesign";
import ResetPassword from "./Components/Reset Password/ResetPassword";
import ProtectedRoute from "./Components/Protected Route/ProtectedRoute";
import ForgotPassword from "./Components/Forgot Password/ForgotPassword";
import ChangePassword from "./Components/Change Password/ChangePassword";
import OrderDetail from "./Components/ActiveProject/OrderDetail/OrderDetail";
import UpdateUserProfile from "./Components/Update User Profile/UpdateUserProfile";
import useUser from "./hooks/useApp";

function App() {
  // eslint-disable-next-line no-unused-vars
  const userDetails = useUser()
  
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route component={Home2} path="/" exact />
          <Route component={Home} path="/home" exact />
          <Route component={Contact} path="/contact-us" exact />
          <Route component={Privacy} path="/Privacy" exact />
          <ProtectedRoute
            component={UpdateUserProfile}
            path="/update-User-profile"
            exact
          />
          <Route component={Login} path="/login" exact />
          <Route component={ForgotPassword} path="/forgot-password" exact />
          <Route component={Otp} path="/otp-verification" exact />
          <ProtectedRoute
            component={ChangePassword}
            path="/change-password"
            exact
          />
          <Route component={ResetPassword} path="/reset-password" exact />
          <Route component={Signup} path="/signup" exact />
          <Route component={About} path="/about" exact />
          <Route component={Showroom} path="/showroom" exact />
          <Route component={HowItWork} path="/howitwork" exact />
          <Route component={Cities} path="/cities" exact />
          <Route component={PaymentSucess} path="/payment-sucess" exact />
          <Route component={PaymentError} path="/payment-error" exact />
          <ProtectedRoute component={Listproject} path="/list" exact />
          <ProtectedRoute
            component={ActiveProject}
            path="/activeproject"
            exact
          />

          <ProtectedRoute
            component={OrderDetail}
            path="/activeproject/orderdetai/:id"
          />

          <ProtectedRoute
            component={ChooseDesign}
            path="/:id/choosedesign"
            exact
          />
          
          <ProtectedRoute component={Step2} path="/:id/step2" exact />
          <ProtectedRoute component={Step3} path="/:id/step3" exact />
          <ProtectedRoute component={Step4} path="/:id/step4" exact />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
