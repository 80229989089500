import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  updateProfile,
  clearupdate,
  addreduxname,
} from "../../Redux/AuthSlice";
import { photoURL } from "../../Helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import "./UpdateUserProfile.scss";
import Avatar from "react-avatar";

export default function UpdateUserProfile() {
  const [location, setlocation] = useState(0);
  // const [timevalue, settimevalue] = useState("");
  //const [locationvalue, setlocationvalue] = useState("");
  const [time, settime] = useState(0);
  const [fullname, setfullname] = useState();
  //const [gfname, setgfname] = useState();
  const inputFile2 = useRef(null);
  // const [glname, setglname] = useState();
  const [phone, setphone] = useState();
  const [zip, setzip] = useState();
  const [address, setaddress] = useState();
  const [city, setcity] = useState();
  //const [isdisable, setisdisable] = useState(false);
  const [isdisabledphone, setisdisabledphone] = useState(true);
  const [isdisabledzip, setisdisabledzip] = useState(true);
  const [isdisabledaddress, setisdisabledaddress] = useState(true);
  const [isdisabledfullname, setisdisabledfullname] = useState(true);
  const [isdisabledcity, setisdisabledcity] = useState(true);
  const [isdisablelocation, setisdisablelocation] = useState(true);
  const [isdisabletime, setisdisabletime] = useState(true);
  const [perror, setperror] = useState();
  const [zerror, setzerror] = useState();
  const [fullnameerror, setfullnameerror] = useState();
  const [addresserror, setaddresserror] = useState();
  const [cerror, setcerror] = useState();
  const [timeerror, settimeerror] = useState();
  const [locationerror, setlocationerror] = useState();
  const [image, setImage] = useState("");

  const handleChangePhone = (e) => {
    const value = e.target.value;
    // setValues({ ...values, phone: value });
    const newValue = value.replace(/\D/g, "").substring(0, 10);
    setphone(formatPhoneNumber(newValue));
  };

  const formatPhoneNumber = (input) => {
    if (!input || isNaN(input)) return "";
    if (typeof input !== "string") input = input.toString();
    return input.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  const { status, userprofile, updateprofilestatus, profilepicture } =
    useSelector((e) => e.Auth);
  const gfullname = userprofile?.fullname;

  useEffect(() => {
    if (userprofile?.city?.length === 0) {
      setisdisabledcity(false);
    }
    if (userprofile?.zipcode?.length === 0) {
      setisdisabledzip(false);
    }
    if (userprofile?.address?.length === 0) {
      setisdisabledaddress(false);
    }
    if (userprofile?.fullname?.length === 0) {
      setisdisabledfullname(false);
    }
    if (userprofile?.phone?.length === 0) {
      setisdisabledphone(false);
    }
    if (userprofile?.estimated_buildable_lot?.length === 0) {
      setisdisablelocation(false);
    }
    if (userprofile?.when_would_you_start?.length === 0) {
      setisdisabletime(false);
    }
    // setImage(userprofile?.profile_image)
    setcity(userprofile?.city);
    setphone(userprofile?.phone);
    setzip(userprofile?.zipcode);
    setaddress(userprofile?.address);
    if (gfullname) {
      setfullname(gfullname);
    }

    setlocation(userprofile?.estimated_buildable_lot);
    settime(userprofile?.when_would_you_start);
  }, [userprofile]);

  const dispatch = useDispatch();

  const disablephone = () => {
    setisdisabledphone(false);
  };
  const disablezip = () => {
    setisdisabledzip(false);
  };
  const disablecity = () => {
    setisdisabledcity(false);
  };

  const disablelocation = () => {
    setisdisablelocation(false);
  };

  const disabletime = () => {
    setisdisabletime(false);
  };
  const history = useHistory();

  const submitdata = (e) => {
    setperror();
    setcerror();
    setzerror();
    setaddresserror();
    setfullnameerror();
    settimeerror();
    setlocationerror();
    e.preventDefault();
    // const phoneReg = /^\d{10}$/;
    if (phone?.length === 0) {
      setperror("You can't keep this empty");
    }
    // else if (!phoneReg.test(phone?.length) ) {
    //   setperror("Invalid phone number");
    // }
    if (city?.length === 0) {
      setcerror("You can't keep this empty");
    }
    if (zip?.length === 0) {
      setzerror("You can't keep this empty");
    }
    // else if (zip?.length < 6) {
    //   setzerror("Invalid Zip");
    // }
    if (address?.length === 0) {
      setaddresserror("You can't keep this empty");
    }
    if (fullname?.length === 0) {
      setfullnameerror("You can't keep this empty");
    }
    if (location?.length === 0) {
      setlocationerror("You can't keep this empty");
    }
    if (time?.length === 0) {
      settimeerror("You can't keep this empty");
    } else if (
      zip !== undefined &&
      fullname?.length > 0 &&
      address?.length > 0 &&
      city?.length > 0 &&
      phone &&
      location?.length > 0 &&
      time?.length > 0
    ) {
      let formData = new FormData();

      if (image !== "") {
        formData.append("profile_image", image);
      }

      formData.append("phone", phone);
      formData.append("zipcode", zip);
      formData.append("fullname", fullname);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("estimated_buildable_lot", location);
      formData.append("when_would_you_start", time);

      dispatch(updateProfile(formData));
      dispatch(addreduxname(fullname));
    }
  };

  useEffect(() => {
    if (updateprofilestatus === 200) {
      dispatch(clearupdate());
      history.push("/");
    }
  }, [updateprofilestatus]);

  const handleChangeLocation = (e) => {
    setlocation(e.target.value);
  };

  const handleChangeTime = (e) => {
    settime(e.target.value);
  };

  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>User Profile</h2>
            <div className="form-group">
              <div className="uploadimg">
                <div className="innerUploadimg">
                  {/* {image !== "" ? (
                    <img src={URL.createObjectURL(image)} alt="" />
                  ) : userprofile?.profile_image ? (
                    <img
                      src={`${photoURL}/${userprofile?.profile_image}`}
                      alt=""
                    />:
    
                  )} */}

                  {image !== "" ? (
                    <>
                      {" "}
                      <img src={URL.createObjectURL(image)} alt="" />{" "}
                    </>
                  ) : userprofile?.profile_image ? (
                    <img
                      src={`${photoURL}/${userprofile?.profile_image}`}
                      alt=""
                    />
                  ) : (
                    <>
                      {userprofile?.fullname ? (
                        <Avatar
                          name={userprofile?.fullname}
                          size="150"
                          round={true}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  <button
                    onClick={() => {
                      inputFile2.current.click();
                    }}
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          setImage(e.target.files[0]);
                        } else {
                          setImage(image);
                        }
                      }}
                      accept="image/*"
                      ref={inputFile2}
                      style={{ display: "none" }}
                    />
                    <span>
                      <i className="fas fa-camera cameraIcon"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Full Name"
                value={fullname}
                onChange={(e) => {
                  setfullname(e?.target?.value);
                }}
              />
              <div className="error">{fullnameerror}</div>
            </div>
            <div className="form-group">
              <label>Your Email</label>
              <input
                type="text"
                placeholder="Your Email"
                disabled
                value={userprofile?.email}
              />
            </div>
            <div className="form-group" id="phn_no">
              <>
                <div>
                  <label>Phone Number</label>

                  <input
                    type="text"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => {
                      handleChangePhone(e);
                    }}
                  />
                  <div className="error">{perror}</div>
                </div>{" "}
              </>
            </div>
            <div>
              <label>Address</label>

              <input
                type="text"
                placeholder="Address"
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                value={address}
              />
              <div className="error">{addresserror}</div>
            </div>{" "}
            <div className="form-group" id="phn_no">
              <>
                <div>
                  <label>Zip</label>

                  <input
                    type="text"
                    placeholder="Zip"
                    value={zip}
                    onChange={(e) => {
                      setzip(e?.target?.value.toUpperCase());
                    }}
                  />
                  <div className="error">{zerror}</div>
                </div>{" "}
              </>
            </div>
            <div className="form-group">
              <>
                <div>
                  <label>Your City</label>

                  <input
                    type="text"
                    placeholder="Your City"
                    value={city}
                    onChange={(e) => {
                      setcity(e?.target?.value);
                    }}
                  />
                  <div className="error">{cerror}</div>
                </div>{" "}
              </>
            </div>
            {/* {isdisablelocation === true ? ( */}
            <>
              <div>
                <div className="row">
                  <div className="form-group">
                    <label>Estimated sqft in your backyard</label>
                    <select onChange={handleChangeLocation}>
                      <option
                        selected={
                          userprofile?.estimated_buildable_lot === "0"
                            ? true
                            : false
                        }
                        value="0"
                      >
                        Choose One
                      </option>
                      <option
                        selected={
                          userprofile?.estimated_buildable_lot === "1"
                            ? true
                            : false
                        }
                        value="1"
                      >
                        400 - 600 sqft
                      </option>
                      <option
                        selected={
                          userprofile?.estimated_buildable_lot === "2"
                            ? true
                            : false
                        }
                        value="2"
                      >
                        600 - 900 sqft
                      </option>
                      <option
                        selected={
                          userprofile?.estimated_buildable_lot === "3"
                            ? true
                            : false
                        }
                        value="3"
                      >
                        900 - 1200 sqft
                      </option>
                      <option
                        selected={
                          userprofile?.estimated_buildable_lot === "4"
                            ? true
                            : false
                        }
                        value="4"
                      >
                        1200 sqft+
                      </option>
                    </select>
                    <div className="error">{locationerror}</div>
                  </div>
                </div>
              </div>{" "}
            </>
            <>
              <div>
                <div className="row">
                  <div className="form-group">
                    <label>When would you like to start?</label>
                    <select onChange={handleChangeTime}>
                      <option
                        selected={
                          userprofile?.when_would_you_start === "0"
                            ? true
                            : false
                        }
                        value="0"
                      >
                        Choose One
                      </option>
                      <option
                        selected={
                          userprofile?.when_would_you_start === "1"
                            ? true
                            : false
                        }
                        value="1"
                      >
                        Immediately
                      </option>
                      <option
                        selected={
                          userprofile?.when_would_you_start === "2"
                            ? true
                            : false
                        }
                        value="2"
                      >
                        3 months from now
                      </option>
                      <option
                        selected={
                          userprofile?.when_would_you_start === "3"
                            ? true
                            : false
                        }
                        value="3"
                      >
                        6 months from now
                      </option>
                      <option
                        selected={
                          userprofile?.when_would_you_start === "4"
                            ? true
                            : false
                        }
                        value="4"
                      >
                        12+ months from now
                      </option>
                    </select>
                    <div className="error">{timeerror}</div>
                  </div>
                </div>
              </div>{" "}
            </>
            {status === true ? (
              <div className="loginbtn">
                <button
                  type="button"
                  disabled={status}
                  onClick={submitdata}
                  className="btn"
                >
                  Loading
                </button>
              </div>
            ) : (
              <div className="loginbtn">
                <button type="button" onClick={submitdata} className="btn">
                  Update Profile
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
