import React,{useState} from 'react'
import { Modal } from 'react-bootstrap'
import './CancelModal.scss'
const CancelModal = ({exitmodal,confirmExit,contineuPaymentpage}) => {



  return (
 <Modal
 show={exitmodal}
 onHide={contineuPaymentpage}
 animation={false}
 centered
 className="cancel-modal-design"
 >
   <Modal.Header style={{justifyContent:"center"}} >
    <h3>Are you sure you want to cancel the transaction ?</h3>
   </Modal.Header>
   <Modal.Body>
  <div className='last-btn' style={{display:"flex",justifyContent:"space-around"}}>
  <button className='btn' onClick={()=>confirmExit()}>Yes</button>
 <button  className='btn' onClick={()=>contineuPaymentpage()} >No</button>
  </div>

   </Modal.Body>
 </Modal>
  )
}

export default CancelModal