import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";

import "./Footer.scss";

import { getFooterLinks } from "../../../Redux/FooterSlice";

import WifiCalling3OutlinedIcon from "@mui/icons-material/WifiCalling3Outlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";

export default function Footer() {
  const dispatch = useDispatch();
  const { footerDetails } = useSelector((e) => e?.Footer);

  // don't need to use dispatch we can call api directly to get data
  // project flow is already set that's why I leave as it is
  useEffect(() => {
    dispatch(getFooterLinks());
  }, [dispatch]);

  return (
    <footer className="footer">
      <div className="footerTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="logoSocial">
                <div className="logoFoot">
                  <Link to="/">
                    <img
                      height={200}
                      width={200}
                      src="/images/logo.svg"
                      alt="logo"
                    />
                  </Link>
                </div>

                <p>{footerDetails ? footerDetails[6]?.setting_value : ""}</p>

                <ul className="socialmedia">
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        footerDetails ? footerDetails[1]?.setting_value : ""
                      }`}
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        footerDetails ? footerDetails[2]?.setting_value : ""
                      }`}
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        footerDetails ? footerDetails[3]?.setting_value : ""
                      }`}
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${
                        footerDetails ? footerDetails[4]?.setting_value : ""
                      }`}
                      rel="noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <h3>QUICK LINKS</h3>
              <ul className="links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/showroom">Our Models</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>

                <li>
                  <Link to="/Privacy">Privacy</Link>
                </li>

                <li>
                  <Link to="/howitwork#resultWrap">FAQ</Link>
                  {/* <Link to="/howitwork#resultWrap">FAQ</Link> */}
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4">
              <h3>Cities we're in</h3>
              <ul className="links">
                <li>
                  <Link to="/cities">View cities</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-4">
              <h3>MEET WITH US</h3>
              <ul className="addressContact">
                <li>
                  <AlternateEmailOutlinedIcon />
                  <a
                    href={`mailto:${
                      footerDetails ? footerDetails[0]?.setting_value : ""
                    }`}
                  >
                    {footerDetails ? footerDetails[0]?.setting_value : ""}
                  </a>
                </li>
                <li>
                  <WifiCalling3OutlinedIcon />
                  <span>
                    <a
                      href={`tel:${
                        footerDetails ? footerDetails[7]?.setting_value : ""
                      }`}
                    >
                      {footerDetails ? footerDetails[7]?.setting_value : ""}{" "}
                    </a>{" "}
                    {/* <Link to="tel:+11 10 222">11 10 222</Link> */}
                  </span>
                </li>
                {/* <li>
                  <img src="./images/telephone.svg" alt="" />{" "}
                  <a
                    href={`tel:${
                      footerDetails ? footerDetails[8]?.setting_value : ""
                    }`}
                  >
                    {footerDetails ? footerDetails[8]?.setting_value : ""}
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBot">
        <div className="container">
          <p>
            &copy; {footerDetails ? footerDetails[9]?.setting_value : ""}
            {/* Spatial | All rights reserved{" "} */}
          </p>
        </div>
      </div>
    </footer>
  );
}
