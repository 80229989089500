import axios from "axios";
import { baseUrlApi } from "./endpoints";

const axiosInstance = axios.create({
  baseURL: baseUrlApi,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("pointb_token");

  if (token && !!config.headers) {
    config.headers["x-access-token"] = `${token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => {
    //     // only show success notification on this routes

    //     // if (sucessNotificationEndPoints.includes(res.config.url)) {
    //     //   if (res?.data?.status !== 200) {
    //     //     // globalCatchWarning(res);
    //     //   } else {
    //     //     // globalCatchSucess(res);
    //     //   }
    //     // }

    return res;
  },

  async (error) => {
    const { data, status } = error;
    // globalCatchError(error);

    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   const access_token = await refreshAccessToken();
    //   setCookieClient("token", access_token?.token);
    //   axios.defaults.headers.common["x-access-token"] = access_token?.token;
    //   return axiosInstance(originalRequest);
    // }

    switch (status) {
      case 400:
        console.error(data);
        break;

      case 401:
        console.error("unauthorized");
        break;

      case 404:
        console.error("/not-found");
        break;

      case 500:
        console.error("/server-error");
        break;
      default:
        return Promise.reject(error);
    }
  }
);

export default axiosInstance;
