import React, { useEffect, useRef, useState } from "react";
import "./HowItWork.scss";
import { getfaqlist, getHowitwork } from "../../Redux/CmsSlice";
import { API_URL } from "../../Helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router";
export default function HowItWork() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [faq, setfaq] = useState();
  const { singleHowitwork } = useSelector((e) => e.CmsDetails);
  const faqdata = useSelector((e) => e?.CmsDetails?.faqdatalist);

  const myRef = useRef(null)
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)  

   const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    dispatch(getHowitwork());
    dispatch(getfaqlist());
   
    
    
    if(window.location.hash==="#resultWrap"){
      // executeScroll()
    }
  }, []);

  useEffect(()=>{
    // singleHowitwork!==[] && executeScroll()
  },[singleHowitwork])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  useEffect(() => {
    if (faqdata?.status === 200) {
      setfaq(faqdata?.data?.data);
    }
  }, [faqdata]);

  return (
    <>
      <div className="innerBanner">
        <figure>
          <img
            src={
              API_URL +
              `/uploads/how_it_works/${singleHowitwork?.main_section_image}`
            }
            alt=""
          />
        </figure>
        <div className="bancont container">
          <div className="innerbancont">
       
            <h1>{singleHowitwork?.title !==undefined? singleHowitwork?.title:"Loading..."}</h1>

            <p
              dangerouslySetInnerHTML={{
                __html: `${singleHowitwork?.content===undefined? "Loading...":singleHowitwork?.content}`,
              }}
            ></p>
          </div>
        </div>
      </div>
      <div className="gapsm workstepsec">
        <div className="container">
          <div className="workStep">
            <div className="item">
              <div className="imagebox">
                {/* <figure><img src="./images/howwork1.jpg" alt="" /></figure> */}
                <figure>
                  <img
                    src={
                      API_URL +
                      `/uploads/how_it_works/${singleHowitwork?.section1_image}`
                    }
                    alt=""
                  />
                </figure>
              </div>
              <div className="line">&nbsp;</div>
              <div className="content">
                <span className="number">1 </span>
          
                <h4>{singleHowitwork?.section1_heading? singleHowitwork?.section1_heading:"Loading..."}</h4>
             
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${singleHowitwork?.section1_content?singleHowitwork?.section1_content:"Loading..."}`,
                  }}
                ></p>
              </div>
            </div>
            <div className="item">
              <div className="imagebox">
                <figure>
                  <img
                    src={singleHowitwork?.section2_image!==undefined?
                      API_URL +
                      `/uploads/how_it_works/${singleHowitwork?.section2_image}`:""
                    }
                    alt=""
                  />
                </figure>
              </div>
              <div className="line">&nbsp;</div>
              <div className="content">
                <span className="number">2 </span>
                {/* <h4>Permit</h4> */}
                <h4>{singleHowitwork?.section2_heading?singleHowitwork?.section2_heading:"Loading..."}</h4>
                {/* <p>A PointB advisor will prepare your permit applications and work directly with the city to expedite your permits.
                </p>  */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${singleHowitwork?.section2_content?singleHowitwork?.section2_content:"Loading..."}`,
                  }}
                ></p>
              </div>
            </div>
            <div className="item">
              <div className="imagebox">
                {/* <figure><img src="./images/howwork3.jpg" alt="" /></figure> */}
                <figure>
                  <img
                    src={
                      API_URL +
                      `/uploads/how_it_works/${singleHowitwork?.section3_image}`
                    }
                    alt=""
                  />
                </figure>
              </div>
              <div className="line">&nbsp;</div>
              <div className="content">
                <span className="number">3 </span>
                {/* <h4>Construction</h4> */}
                <h4>{singleHowitwork?.section3_heading?singleHowitwork?.section3_heading:"Loading..."}</h4>
                {/* <p>We offer you the highest quality professionals to bring your ADU to life.</p> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${singleHowitwork?.section3_content?singleHowitwork?.section3_content:"Loading..."}`,
                  }}
                ></p>
              </div>
            </div>
            <div className="item">
              <div className="imagebox">
                {/* <figure><img src="./images/howwork4.jpg" alt="" /></figure> */}
                <figure>
                  <img
                    src={
                      API_URL +
                      `/uploads/how_it_works/${singleHowitwork?.section4_image}`
                    }
                    alt=""
                  />
                </figure>
              </div>
              <div className="line">&nbsp;</div>
              <div className="content">
                <span className="number">4 </span>
              
                <h4>{singleHowitwork?.section4_heading?singleHowitwork?.section4_heading:"Loading..."}</h4>
               
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${singleHowitwork?.section4_content?singleHowitwork?.section4_content:"Loading..."}`,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faqsec gapsec"  >
        
         
          <div className="gapsec readytostart" ref={myRef} id="resultWrap">
        <div className="container">
          <div className="headingsec">
            <h2>ready to start a project?</h2>
            <p>Contact Us! We look forward to meeting you</p>
          </div>
          <button
            type="button"
            className="btn"
            onClick={() => history.push("/showroom")}
          >
            Choose your design
          </button>
        </div>
      </div>
      <div  style={{visibility:'hidden'}} />
      <div  className="headingsec text-center mt-5">
        <div className="container">
            <h2 >Frequently Asked Questions</h2>
         
          <Accordion defaultActiveKey="0">
            {faq !== undefined
              ? faq?.map((x, key) => (
                  <Accordion.Item eventKey={key}>
                    <Accordion.Header>{x?.question?x?.question:"Loading..."}</Accordion.Header>
                    <Accordion.Body>{x?.answer?x?.answer:"Loading..."}</Accordion.Body>
                  </Accordion.Item>
                ))
              : ""}
          </Accordion>
        </div></div>
        </div>
    </>
  );
}
