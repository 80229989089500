import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// const firebaseConfig = {
//   // Your Firebase config object here
//   apiKey: "AIzaSyD262HOztyOcEyCWqQLIWU0f-XBVAMWERI",
//   authDomain: "spatial-e69e1.firebaseapp.com",
//   databaseURL: "https://spatial-e69e1-default-rtdb.firebaseio.com",
//   projectId: "spatial-e69e1",
//   storageBucket: "spatial-e69e1.appspot.com",
//   messagingSenderId: "302263679854",
//   appId: "1:302263679854:web:f5f59d0e16b069170edf7f",
//   measurementId: "G-TLJTKHLFJY",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCr3Vf730W6iug0Z7sSsQRKHRlEMyDK1JU",
  authDomain: "spatial-b1abf.firebaseapp.com",
  databaseURL: " https://spatial-b1abf-default-rtdb.firebaseio.com ",
  projectId: "spatial-b1abf",
  storageBucket: "spatial-b1abf.appspot.com",
  messagingSenderId: "234040392311",
  appId: "1:234040392311:web:8b3a3f50b6be3916716472",
  measurementId: "G-235J9X9FL3"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;
