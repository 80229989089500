import React, { useState, useEffect } from "react";
import { changePassword, clrrespass } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/AuthSlice";
import "./ChangePassword.scss";
import { useHistory } from "react-router-dom";

export default function ChangePassword() {
  const [userData, setuserData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  const { status, islogout, userprofile } = useSelector(
    (e) => e.Auth
  );


  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userprofile?.register_type === "google") {
      history.push("/");
    }
  }, [userprofile]);
  const validation = () => {
    let error = {};
    if (!userData.old_password) {
      error.old_password = "Old Password is required";
    } else if (userData.old_password.length < 8) {
      error.old_password = "Minimum 8 characters";
    }

    if (!userData.new_password) {
      error.new_password = "New Password is required";
    } else if (userData.new_password.length < 8) {
      error.new_password = "Minimum 8 characters";
    }
    if (!userData.confirm_password) {
      error.confirm_password = "Confirm Password is required";
    } else if (userData.new_password !== userData.confirm_password) {
      error.confirm_password = "Password doesn't match";
    }

    return error;
  };

  const submitdata = (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      const user = {
        old_password: userData.old_password,
        new_password: userData.new_password,
      };
      dispatch(changePassword(user));
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (islogout === true) {
      dispatch(logout());
      dispatch(clrrespass())
      history.push("/login");
      localStorage.clear();
    } else {
      setuserData({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
    }
  }, [islogout]);
  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>Change Password</h2>

            <br />
            <div className="form-group">
              <label>Old Password</label>
              <input
                type="password"
                placeholder="Old Password"
                name="old_password"
                value={userData.old_password}
                onChange={postUserData}
              />
              <div className="error">{error.old_password}</div>
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                placeholder="New Password"
                name="new_password"
                value={userData.new_password}
                onChange={postUserData}
              />
              <div className="error">{error.new_password}</div>
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirm_password"
                value={userData.confirm_password}
                onChange={postUserData}
              />
              <div className="error">{error.confirm_password}</div>
            </div>

            {status === true ? (
              <div className="loginbtn">
                <button
                  type="button"
                  disabled={status}
                  onClick={submitdata}
                  className="btn"
                >
                  Loading
                </button>
              </div>
            ) : (
              <div className="loginbtn">
                <button type="button" onClick={submitdata} className="btn">
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
