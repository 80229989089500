import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import CardIdent from "global-card-ident";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "./StripeModal.scss";
import cardValidator from "simple-card-validator";
import { useDispatch, useSelector } from "react-redux";
import { clrpaymetdetails, paymentviaCard } from "../../../Redux/ProductSlice";
import cvvicon from "./cvv.png";
import { toast } from "react-toastify";
import CancelModal from "./CancelModal/CancelModal";
import { getActiveProject } from "../../../Redux/ActiveSlice";

export default function PaymentModal({
  stripeshow,
  stripehandleClose,
  paymenttype,
  amount,
  address,
}) {
  const [exitmodal, setExitmodal] = useState(false);
  const [showpopup, setshowpopup] = useState(false);
  const currentproduct = useSelector((state) => state?.product?.productdet);
  const sid=currentproduct?.basic_details?._id!==undefined?currentproduct?.basic_details?._id:localStorage.getItem("product_id");
  const exitmodalFnc = () => {
    if (exitmodal === false) {
      setExitmodal(true);
    } else {
      setExitmodal(false);
    }
  };

  const { cardPaymentStatus } = useSelector((state) => state?.product);

  const d = new Date();
  let yearcur = d.getFullYear();

  const dispatch = useDispatch();
  const [cardno, setcardno] = useState("");
  const [month, setmonth] = useState("");
  const [year, setyear] = useState("");
  const [ccv, setccv] = useState("");

  const [cardnoerr, setcardnoerr] = useState("");
  const [montherr, setmontherr] = useState("");
  const [yearerr, setyearerr] = useState("");
  const [ccverr, setcvverr] = useState("");

  const cardDetail = CardIdent(parseInt(cardno));

  const x = () => {
    if (exitmodal === false) {
      exitmodalFnc();
      setshowpopup(true);
    }
  };

  const confirmExit = () => {
    exitmodalFnc();
    stripehandleClose();
    setcardno("");
    setmonth("");
    setyear("");
    setccv("");
    setcardnoerr("");
    setmontherr("");
    setyearerr("");
    setcvverr("");
    setshowpopup(false);
  };

  const contineuPaymentpage = () => {
    exitmodalFnc();
    setshowpopup(false);
  };

  const payviacard = () => {
    dispatch(
      paymentviaCard({
        project_id: sid,
        card_number: cardno,
        exp_month: month,
        exp_year: year,
        cvv: ccv,
        amount: amount || 100,
        address: address,
        typeofpayment: paymenttype,
      })
    );
  };

  useEffect(() => {
    if (cardPaymentStatus !== "") {
      if (cardPaymentStatus?.data?.status == 200) {
        // history.push("/list");

        // x();
        confirmExit()
        dispatch(clrpaymetdetails());
        dispatch(getActiveProject());
      } else {
        dispatch(clrpaymetdetails());
        dispatch(getActiveProject());
      }
    }
  }, [cardPaymentStatus]);

  return (
    <>
      {showpopup === false ? (
        <Modal
          show={stripeshow}
          onHide={x}
          animation={false}
          centered
          className="new_all_payment-page-sec"
        >
          <Modal.Header
            style={{
              background: "#00709a",
              display: "flex",
              justifyContent: "center",
              border: "0",
            }}
          >
            <h4 style={{ color: "whitesmoke", margin: "0", border: "0" }}>
              Enter Debit Card Details
            </h4>
          </Modal.Header>
          <Modal.Body style={{ background: "#b5c4cf" }}>
            <div className="card-text-header" style={{ border: "0" }}>
              {cardDetail?.logo == undefined || "" ? (
                <div className="default-svg">
                  <CreditCardIcon />
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: cardDetail?.logo }} />
              )}

              <div className="form-group-payment">
                <label htmlFor="">Enter your Card Number</label>
                <input
                  placeholder="Enter Debit Card Number"
                  max={16}
                  value={cardno}
                  onChange={(e) => {
                    if (e?.target?.value?.length > 16) {
                      setcardnoerr("card no should be 16 digit number");
                    } else {
                      setcardnoerr("");
                      setcardno(e.target.value);
                    }
                  }}
                  type="number"
                  name=""
                  id=""
                  style={{ marginBottom: "0px" }}
                />
              </div>

              <div className="valid-err" style={{ color: "red" }}>{cardnoerr}</div>
            </div>

            <div className="card-txt-footer">
              <div className="all-flex_card-payment">
                <div className="form-group-payment">
                  <label htmlFor="">Expiry</label>
                  <input
                    type="number"
                    value={month}
                    onChange={(e) => {
                      let hasError = false;
                      if (e?.target?.value < 0 || e?.target?.value > 12) {
                        setmontherr("enter a valid month");
                        hasError = true;
                      }

                      if (e?.target?.value?.length > 2) {
                        setmontherr("enter a valid month");
                        hasError = true;
                      }

                      if (!hasError) {
                        setmontherr("");
                        setmonth(e?.target?.value);
                      }
                    }}
                    placeholder="MM"
                    style={{
                      width: "64px",
                      padding: "12px 5px",
                      fontWeight: "600",
                      margin: "5px 5px 0px 1px",
                    }}
                    max={2}
                    name=""
                    id=""
                  />

                  <div className="valid-err" style={{ color: "red" }}>{montherr}</div>
                </div>
                <div className="form-group-payment">
                  <label htmlFor="">Year</label>
                  <input
                    value={year}
                    onChange={(e) => {
                      let hasError = false;
                      if (
                        e?.target?.value?.length == 4 &&
                        e?.target?.value < yearcur
                      ) {
                        hasError = true;
                        setyearerr("card is expaired");
                      }
                      if (
                        e?.target?.value?.length == 4 &&
                        e?.target?.value > 2099
                      ) {
                        hasError = true;
                        setyearerr("please enter a valid year");
                      }
                      if (!hasError) {
                        setyearerr("");
                        setyear(e?.target?.value);
                      }
                    }}
                    placeholder="YYYY"
                    style={{
                      width: "100px",
                      padding: "0px 5px",
                      fontWeight: "600",
                      margin: "5px 5px 0px 1px",
                    }}
                    maxlength="4"
                    type="text"
                    name=""
                    id=""
                  />
                  <div className="valid-err" style={{ color: "red" }}>{yearerr}</div>
                </div>
              </div>

              <div className="form-group-payment">
                <label htmlFor="">Cvv</label>
                <input
                  type="password"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  onChange={(e) => {
                    if (ccv?.length < 3) {
                      setccv(e?.target?.value);
                    }
                  }}
                  placeholder="CVV"
                  style={{
                    width: "115px",
                    padding: "0px 5px",
                    fontWeight: "600",
                    margin: "5px 5px 0px 1px",
                  }}
                  maxLength={3}
                  name=""
                  id=""
                />

                <div style={{ position: "relative" }}>
                  <img
                    style={{
                      position: "absolute",
                      width: "23px",
                      top: "-36px",
                      right: "15px",
                    }}
                    src={cvvicon}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {cardPaymentStatus == "loading" ? (
              <button className="btn w-100 payment-btn-padding new_btn_last_payment-pay">
                Loading...
              </button>
            ) : (
              <button
                className="btn w-100 payment-btn-padding new_btn_last_payment-pay"
                onClick={payviacard}
              >
                Pay
              </button>
            )}
          </Modal.Body>
        </Modal>
      ) : (
        <CancelModal
          exitmodal={exitmodal}
          confirmExit={confirmExit}
          contineuPaymentpage={contineuPaymentpage}
        />
      )}
    </>
  );
}
