import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./Listproject.scss";

import { API_URL } from "../../Helper/Helper";
import SmallIcon from "../smallIcon/SmallIcon";

import { projectDelete } from "../../Redux/ProductSlice";
import { FetchOwnProductLists } from "../../Redux/ProductSlice";

const Listproject = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [inActivePrjList, setInActivePrjList] = useState();
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [count, setcount] = useState(12);
  const [isLoadingProductList, setIsLoadingProductList] = useState(false);

  const { inactiveProjectList } = useSelector((state) => state.product);

  useEffect(() => {
    setIsLoadingProductList(true);
    dispatch(FetchOwnProductLists());
  }, []);

  const deleteProjectData = (projectId) => {
    setShow(false);

    dispatch(projectDelete({ project_id: projectId }));
    dispatch(FetchOwnProductLists());
  };

  // set Inactive Project List
  useEffect(() => {
    if (inactiveProjectList) {
      if (inactiveProjectList?.length > 12) {
        const data = inactiveProjectList?.slice(0, count);

        setInActivePrjList(data);
      } else if (inactiveProjectList?.length <= 12) {
        setInActivePrjList(inactiveProjectList);
      }

      setIsLoadingProductList(false);
    }

    console.log(inactiveProjectList, "From List Project");
  }, [inactiveProjectList, count]);

  useEffect(() => {}, [inActivePrjList]);

  const jumptoproduct = (item) => {
    const {
      _id,
      showroom_id,
      envelopestatus,
      status_document,
      date,
      end_time,
      start_time,
      project_step,
    } = item;

    localStorage.setItem("product_id", _id);
    localStorage.setItem("showroom_id", showroom_id);

    if (date === null || start_time === "" || end_time == "") {
      // history.push("choosedesign", { id: _id });
      history.push(`${_id}/choosedesign`);
    } else if (project_step === 1 || status_document === "") {
      // history.push("step2", { id: _id });
      history.push(`${_id}/step2`);
    } else if (project_step === 2 && status_document !== "") {
      // history.push(`step3`, { id: _id });
      history.push(`${_id}/step3`);
    } else if (envelopestatus === true && status_document !== "") {
      // history.push(`step4`, { id: _id });
      history.push(`${_id}/step4`, { id: _id });
    }
  };

  return (
    <>
      <div className="gapsec">
        <h1 style={{ textAlign: "center" }}>Inactive Project List</h1>

        <div className="showroomItems list-project">
          <div className="container">
            <div className="selectfilter"></div>

            <div className="row">
              {isLoadingProductList ? (
                <div className="loaderwrapper">
                  <img src="./images/loader.gif" alt="" />
                </div>
              ) : (
                <>
                  {inActivePrjList?.length > 0 ? (
                    inActivePrjList?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="col-lg-4 col-md-6"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="item">
                            <figure>
                              <div
                                className="delect_icon"
                                onClick={() => {
                                  setShow(item?._id);
                                  setDeleteId(item?._id);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: "red",
                                    position: "relative",
                                    left: "390px",
                                  }}
                                />
                                <p>Delete Project</p>
                              </div>

                              <img
                                onClick={() => jumptoproduct(item)}
                                style={{ cursor: "pointer" }}
                                src={
                                  item?.showroom_details?.default_image === ""
                                    ? "/images/blank.png"
                                    : `${API_URL}/uploads/showroom/${item?.showroom_details?.default_image}`
                                }
                                alt="showroom-img-banner"
                              />
                            </figure>
                            <div className="itemInner">
                              <div className="nameBtn  ">
                                <h3>{item?.showroom_details?.title}</h3>
                              </div>

                              <div className="d-flex gap-1 mb-2">
                                <span style={{ color: "#0d3e87" }}>
                                  <LocationOnIcon />
                                </span>{" "}
                                <span className="text-wrap">
                                  {item?.address}
                                </span>
                              </div>

                              <div className="bedPrice">
                                <h4>
                                  {item?.showroom_details?.showroom_floor_plan?.map(
                                    (id) => (
                                      <div key={id?.unique_key}>
                                        {id?.bedroom_numbers && (
                                          <>
                                            <SmallIcon img="bed.svg" />
                                            {id.bedroom_numbers}
                                          </>
                                        )}
                                        {id?.studio && "Studio"}
                                        {id?.bathroom_numbers && (
                                          <>
                                            <SmallIcon img="bath.svg" />
                                            {id.bathroom_numbers}
                                          </>
                                        )}
                                      </div>
                                    )
                                  )}
                                </h4>

                                <p className="pricetag">
                                  {item?.showroom_details?.estimated_cost?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "USD",
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>No data</>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {inactiveProjectList?.length > 12 ? (
          <div className="text-center">
            {inactiveProjectList?.length == inActivePrjList?.length ? (
              ""
            ) : (
              <button
                className="loadmore-class"
                onClick={() => setcount(count + 12)}
              >
                Load More...
              </button>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        centered
        className="addLocation deleteModalModification"
      >
        <Modal.Body>
          <div className="form-group">
            <label style={{ color: "red" }}>
              Are you sure you want to delete this project?
            </label>
          </div>
          <div className="btngrp">
            <button
              type="button"
              onClick={() => deleteProjectData(deleteId)}
              className="btn"
            >
              Yes
            </button>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="cancelbtn"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Listproject;
