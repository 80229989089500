import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { setUserDetails, socialSignup } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import "./Login.scss";
import "react-toastify/dist/ReactToastify.css";

import firebase, { auth } from "../../firebaseConfig";
import { login } from "../../services/functions/user.api";

toast.configure();

export default function Login() {
  const history = useHistory();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [userData, setuserData] = useState({
    email: "",
    password: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };

  const validation = () => {
    const errors = {};

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!userData.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(userData.email)) {
      errors.email = "Enter a valid email";
    }

    if (!userData.password) {
      errors.password = "Password is required";
    } else if (userData.password.length < 8) {
      errors.password = "Minimum 8 characters";
    }

    return errors;
  };

  const handleGoogleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    auth
      .signInWithPopup(provider)
      .then((result) => {
        if (result && result.user && result.user._delegate) {
          const loginSocial = {
            fullname: result.user._delegate.displayName,
            email: result.user._delegate.email,
            social_id: result.user._delegate.uid,
            register_type: "google",
            type: "login",
          };

          dispatch(socialSignup(loginSocial));
        } else {
          // Handle the case where user data is not available
          console.log("User data not available");
        }
      })
      .catch((error) => {
        // Handle errors more gracefully (e.g., show an error message to the user)
        console.log("Google login error:", error);
      });
  };

  const handleAppleLogin = () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");

    auth
      .signInWithPopup(provider)
      .then((result) => {
        const loginSocial = {
          fullname: result?.user?._delegate?.displayName,
          email: result?.user?._delegate?.email,
          social_id: result?.user?._delegate?.uid,
          register_type: "apple",
          type: "login",
        };

        dispatch(socialSignup(loginSocial));
      })
      .catch((error) => {
        console.log("apple error,", error);
      });
  };

  const submitdata = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let ErrorList = validation();
    setError(validation());

    if (Object.keys(ErrorList).length !== 0) {
    } else {
      const user = {
        email: userData.email,
        password: userData.password,
      };
      try {
        const loginResponse = await login(user);
        const { data, status, message, token } = loginResponse;

        if (status === 200) {
          console.log("token: ",token)
          window.localStorage.setItem("pointb_token", token);

          dispatch(setUserDetails(data));
          history.push("/");

          toast.success(message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });

          setIsLoading(false);
        } else throw new Error(message);
      } catch (error) {
        toast.success(error.message, {
          type: "error",
          hideProgressBar: true,
          autoClose: 2500,
        });

        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>Login</h2>
            <p>
              Provide the email address associated with your account and we’ll
              send you a link to log in.
            </p>
            <div className="socialLogin">
              <button type="button" onClick={() => handleGoogleLogin()}>
                <img src="./images/google.svg" alt="" /> Continue with google
              </button>
              <button type="button" onClick={handleAppleLogin}>
                <img
                  src="./images/apple.svg"
                  alt="apple-img"
                  style={{ width: "25px" }}
                />
                Continue with Apple
              </button>
            </div>
            <div className="ortag">
              <span>Or</span>
            </div>
            <div className="form-group">
              <label>Your Email</label>
              <input
                type="email"
                placeholder="Your Email"
                name="email"
                value={userData.email}
                onChange={postUserData}
              />
              <div className="error">{error.email}</div>
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                name="password"
                placeholder="Your Password"
                type="password"
                value={userData.password}
                onChange={postUserData}
              />
              <div className="error">{error.password}</div>
              <div className="forgotpassLink">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>

            <div className="loginbtn">
              <button
                type="button"
                onClick={submitdata}
                className="btn"
                disabled={isLoading}
              >
                {isLoading ? "Loading" : "Login"}
              </button>
            </div>

            <div className="ortag">
              <span>Don't have an account?</span>
            </div>
            <Link to="/signup" className="btnwh">
              Create Your Account
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
