import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { contact } from "../../Redux/ContactSlice";
import "./Contact.scss";

export default function Contact() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [content, setcontent] = useState("");
  const [error, setError] = useState({});

  const { status, contactusmessage } = useSelector((e) => e.contactreducer);

  const dispatch = useDispatch();

  const validation = () => {
    let error = {};

    if (!email) {
      error.email = "Email is required";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      error.email = "Enter a valid email";
    }

    if (!name) error.name = "Name is required";

    if (!content) error.content = "You can't keep this field empty";

    return error;
  };

  const submitdata = (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      dispatch(contact({ full_name: name, email: email, content: content }));
    }
  };
  useEffect(() => {
    if (contactusmessage === "Thank You for Contacting Us!") {
      setname("");
      setcontent("");
      setemail("");
    }
  }, [contactusmessage]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>Contact Us</h2>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
              <div className="error">{error.name}</div>
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                placeholder="Your Email"
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                value={email}
              />
              <div className="error">{error.email}</div>
            </div>

            <div className="form-group">
              <label>Content</label>
              <textarea
                type="text"
                style={{ height: "120px" }}
                placeholder="Your Content"
                onChange={(e) => {
                  setcontent(e.target.value);
                }}
                value={content}
              />
              <div className="error">{error.content}</div>
            </div>
            {status === true ? (
              <div className="loginbtn">
                <button
                  type="button"
                  disabled={status}
                  onClick={submitdata}
                  className="btn"
                >
                  Loading
                </button>
              </div>
            ) : (
              <div className="loginbtn">
                <button type="button" onClick={submitdata} className="btn">
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
