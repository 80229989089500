export const baseUrl =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? process.env.REACT_APP_SERVER_DEV
    : process.env.REACT_APP_SERVER;

export const baseUrlApi = `${baseUrl}/api/`;

export const photoURL =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? process.env.REACT_APP_PHOTO_URL_PATH_DEV
    : process.env.REACT_APP_PHOTO_URL_PATH;

export const endpoints = {
  auth: {
    signup: "user/signup",
    login: "user/login",
    socialLogin: "user/social-signup",
    forgetPassword: "user/forgot-password",
    resetPassword: "user/reset-password",
    otpVerify: "user/verify-otp",
    changepassword: "user/change-password",
    profileUpdate: "user/edit-profile",
    profileDetails: "user/profile/details",
    addAddress: 'user/add-new-address',
    removeAddress:'user/delete-address'
  },
  cms: {
    home: "home/user-content",
    artist: "user/list",
    about: "aboutpolicy/details",
    faq: "faq/all",
    categoryList: "event/category/list",
    exploreOptionData: "home/data/count",
    cms: (val) => {
      return `cms/${val}`;
    },
    dashboardSearch: "user/dashboard/search",
  },
  payment: {
    paymentHistory: "/user/transactions",
  },
};
