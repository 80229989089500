import React, { useEffect } from "react";
import "./About.scss";
import { API_URL } from "../../Helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import { getAboutus } from "../../Redux/CmsSlice";
import { getTeamlist } from "../../Redux/CmsSlice";

export default function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const dispatch = useDispatch();

  const { singleAboutDetails } = useSelector((e) => e.CmsDetails);
  //const { singleteamlist } = useSelector((e) => e.CmsDetails);

  useEffect(() => {
    // if (singleAboutDetails) {
    dispatch(getAboutus());
    // }
  }, [dispatch]);
  useEffect(() => {
    // if (singleAboutDetails) {
    dispatch(getTeamlist());
    // }
  }, [dispatch]);

  return (
    <>
      <div className="innerBanner">
        <figure>
          <img
            src={
              API_URL +
              `/uploads/about_us/${singleAboutDetails?.main_section_image}`
            }
            alt=""
          />
        </figure>
        <div className="bancont container">
          <div className="innerbancont">
            <h1>
              {singleAboutDetails?.title
                ? singleAboutDetails?.title
                : "Loading..."}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: `${
                  singleAboutDetails?.content
                    ? singleAboutDetails?.content
                    : "Loading..."
                }`,
              }}
            ></p>
          </div>
        </div>
      </div>
      <div
        className="ourmissionsec"
        style={{
          backgroundImage: `url("${API_URL}/uploads/about_us/${singleAboutDetails?.section3_image}")`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="innerourmissionsec">
            <h2>
              {singleAboutDetails?.section5_title
                ? singleAboutDetails?.section5_title
                : "Loading..."}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: `${
                  singleAboutDetails?.section5_content
                    ? singleAboutDetails?.section5_content
                    : "Loading..."
                }  `,
              }}
            ></p>
            {/* <button
              type="button"
              className="btn"
              onClick={() => {
                history.push("/showroom");
              }}
            >
              {singleAboutDetails?.section5_button_text?singleAboutDetails?.section5_button_text:"Loading..."}
            </button> */}
          </div>
        </div>
      </div>
      {/* <div className="gapsec aboutsec1">
        <div className="container">
          <div className="inneraboutsec1">
            <div className="row">
              <div className="col-lg-7">
                <div className="lftsec">
                  <h2>
                    {" "}
                    {singleAboutDetails?.section1_heading
                      ? singleAboutDetails?.section1_heading
                      : "Loading..."}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${
                        singleAboutDetails?.section1_content
                          ? singleAboutDetails?.section1_content
                          : "Loading..."
                      }`,
                    }}
                  ></div>
                
                  <button
                  style={{marginTop:"40px"}}
                    type="button"
                    className="btn"
                    onClick={() => {
                      history.push("/showroom");
                    }}
                  >
                    Choose Your Design
                  </button>
                </div>
              </div>

              <div className="col-lg-5">
                <figure>
                  <img
                    src={
                      API_URL +
                      `/uploads/about_us/${singleAboutDetails?.section1_image}`
                    }
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="teamsec gapsec">
        <div className="container">
          <div className="headingsec">
            <h2>{singleAboutDetails?.section4_title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: `${singleAboutDetails?.section4_content}  `,
              }}
            ></p>
          </div>
          <div className="row">
            {singleteamlist?.length > 0 ? (
              <>
                {singleteamlist?.map((item) => {
                  return (
                    <div className="col-lg-3 col-md-6">
                      <figure>
                     
                        <img
                          src={API_URL + `/uploads/teams/${item?.image}`}
                          alt=""
                        />
                      </figure>
                  
                      <h4>{item?.title}</h4>
                   
                      <p>{item?.profession}</p>
                    </div>
                  );
                })}
              </>
            ) : (
              <>No data</>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
}
