/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Slider from "react-slick";
import ReactRating from "react-rating";

import {
  getHomeDetails,
  gettestimonial,
  // getservicearealist
} from "../../Redux/CmsSlice";
import "./Home.scss";
import { API_URL } from "../../Helper/Helper";

import PaintIcon from "../../asset/phone.png";

export const SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  autoPlay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [accordian, setAccordian] = useState(1);

  const { singleHomeDetails, testimonials, servicearealist } = useSelector(
    (state) => state?.CmsDetails
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(getHomeDetails());
    dispatch(gettestimonial());
    // dispatch(getservicearealist());
  }, [dispatch]);

  return (
    <>
      <div className="hmban">
        <figure>
          <img
            src={API_URL + `/uploads/home/${singleHomeDetails?.image}`}
            alt=""
          />
        </figure>
        <div className="bannerCont container">
          <div className="innerHmBan">
            <h1>{singleHomeDetails?.title}</h1>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${singleHomeDetails?.section1_content}`,
                }}
              ></div>
            </p>

            <button
              type="button"
              className="bannerbtn btn"
              onClick={() => history.push("/showroom")}
            >
              {singleHomeDetails?.button_text}
            </button>
          </div>
        </div>
      </div>

      <div className="bannerbottom gapsec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <h3>
                  <img src="./images/ic1.svg" alt="" />{" "}
                  {singleHomeDetails?.section1_button1_text}
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <h3>
                  {" "}
                  <img src="./images/ic2.svg" alt="" />{" "}
                  {singleHomeDetails?.section1_button2_text}
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <h3>
                  {" "}
                  <img src="./images/ic3.svg" alt="" />{" "}
                  {singleHomeDetails?.section1_button3_text}
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item">
                <h3>
                  <img src="./images/ic4.svg" alt="" />{" "}
                  {singleHomeDetails?.section1_button4_text}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hmBestAff gapsec">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="headingsec">
                <h2>{singleHomeDetails?.section1_heading}</h2>
              </div>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${singleHomeDetails?.section1_content}`,
                  }}
                ></div>
              </p>
            </div>
            <div className="col-lg-5">
              <figure>
                <img
                  src={
                    API_URL +
                    `/uploads/home/${singleHomeDetails?.section1_image}`
                  }
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
        <img src="./images/logo-hm.svg" alt="" className="hmBestAfflogo" />
      </div>

      <div className="hmHowWork  ">
        <div className="container">
          <div className="innerhmHowWork">
            <div className="headingsec">
              <h2>{singleHomeDetails?.section2_heading}</h2>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: `${singleHomeDetails?.section2_content}`,
              }}
            ></p>
            {/* <button type="button" className="downarrow">
              <KeyboardArrowDownOutlinedIcon />
            </button> */}
          </div>
        </div>
      </div>
      <div className="hmExpandPanel">
        <ul id="expandsection">
          <li
            onClick={() => setAccordian(1)}
            className={accordian === 1 ? "active" : ""}
          >
            <span className="number">1</span>
            <div className="headingContent">
              <h3>{singleHomeDetails?.section3_heading1}</h3>
              <div className="content">
                <h4>{singleHomeDetails?.section3_heading1}</h4>
                <p>{singleHomeDetails?.section3_content1}</p>
              </div>
            </div>
          </li>
          <li
            onClick={() => setAccordian(2)}
            className={accordian === 2 ? "active" : ""}
          >
            <span className="number">2</span>
            <div className="headingContent">
              <h3>{singleHomeDetails?.section3_heading2}</h3>
              <div className="content">
                <h4>{singleHomeDetails?.section3_heading2}</h4>
                <p>{singleHomeDetails?.section3_content2}</p>
              </div>
            </div>
          </li>
          <li
            onClick={() => setAccordian(3)}
            className={accordian === 3 ? "active" : ""}
          >
            <span className="number">3</span>
            <div className="headingContent">
              <h3>{singleHomeDetails?.section3_heading3}</h3>
              <div className="content">
                <h4>{singleHomeDetails?.section3_heading3}</h4>
                <p>{singleHomeDetails?.section3_content3}</p>
              </div>
            </div>
          </li>
          <li
            onClick={() => setAccordian(4)}
            className={accordian === 4 ? "active" : ""}
          >
            <span className="number">4</span>
            <div className="headingContent">
              <h3>{singleHomeDetails?.section3_heading4}</h3>
              <div className="content">
                <h4>{singleHomeDetails?.section3_heading}</h4>
                <p>{singleHomeDetails?.section3_content4}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="hmphnsec gapsec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              {/* <img
                src={
                  API_URL + `/uploads/home/${singleHomeDetails?.section4_image}`
                }
                alt=""
              /> */}
              <div className="phvideo_area">
                <img className="phimg" src={PaintIcon} alt="" />

                {/* <iframe
                  src={phvideo}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe> */}

                <video autoPlay loop muted playsInline>
                  <source
                    src="https://pointb.dedicateddevelopers.us/uploads/video/pointb_video_sample.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="headingsec">
                <h2>{singleHomeDetails?.section4_heading}</h2>
                <p>{singleHomeDetails?.section4_content}</p>
                <img src="./images/dotsbg.svg" alt="" className="dotsbg" />
              </div>
              <div className="listitems">
                <ul>
                  <li>{singleHomeDetails?.section4_point1}</li>
                  <li>{singleHomeDetails?.section4_point2}</li>
                  <li>{singleHomeDetails?.section4_point3}</li>
                  <li>{singleHomeDetails?.section4_point4}</li>
                </ul>
              </div>
              <button
                type="button"
                className="btn"
                onClick={() => history.push("/showroom")}
              >
                {singleHomeDetails?.section4_button_text}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* CUSTOMERS TESTIMONIAL */}
      <div style={{ marginBottom: "100px" }} className="customerTestimonial">
        <div className="container">
          <div className="headingsec">
            <h2>{singleHomeDetails?.testimonial_heading}</h2>
            <p>{singleHomeDetails?.testimonial_content}</p>
          </div>
          <div className="row">
            <div className="col-lg-6 lftimg">
              <img
                src={
                  singleHomeDetails?.testimonial_image !== ""
                    ? API_URL +
                      "/uploads/home/" +
                      singleHomeDetails?.testimonial_image
                    : ""
                }
                alt=""
              />
            </div>

            <div className="col-lg-6 testiSliderOuter">
              <div className="testimonialSlider">
                <Slider {...SLIDER_SETTINGS}>
                  {testimonials?.map((id) => (
                    <div>
                      <figure>
                        <img
                          src={
                            id?.image
                              ? API_URL + "/uploads/testimonial/" + id?.image
                              : "./images/blank.png"
                          }
                          alt=""
                        />
                      </figure>
                      <h3>{id?.title}</h3>
                      <div className="starsec">
                        <ReactRating
                          stop={5}
                          initialRating={id?.rating}
                          emptySymbol={
                            <i
                              style={{ color: "#FFC900" }}
                              className="far fa-star fa-2x"
                            ></i>
                          }
                          fullSymbol={
                            <i
                              style={{ color: "#FFC900" }}
                              className="fas fa-star fa-2x"
                            ></i>
                          }
                          readonly
                        />
                      </div>
                      <div className="comments">
                        <p>{id?.content}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Service Areas {as per client feedback} */}
      {/* <div className="ourService gapsec">
        <div className="container">
          <div className="headingsec text-center">
            <h2>Our Service Areas</h2>
          </div>
          <div className="row">
            {servicearealist?.length
              ? servicearealist?.map((data) => (
                  <div className="col-lg-3 col-md-6">
                    <div
                      className="item"
                      onClick={() => {
                        history.push("/howitwork");
                      }}
                    >
                      <figure style={{ height: "300px" }}>
                        <img
                          src={
                            data?.image
                              ? API_URL + "/uploads/service_area/" + data?.image
                              : "noimg"
                          }
                          alt=""
                        />
                        <span className="number">8</span>
                      </figure>
                      <h3>{data?.title}</h3>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Home;
