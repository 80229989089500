import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getpopup } from "../Redux/CmsSlice";
import { setUserDetails } from "../Redux/AuthSlice";
import { getActiveProject } from "../Redux/ActiveSlice";
import { FetchOwnProductLists } from "../Redux/ProductSlice";

import { getProfileDetails } from "../services/functions/user.api";

const useUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("pointb_token");

    const GetUserDetails = async () => {
      try {
        const loggedInUserDetails = await getProfileDetails();
        dispatch(getActiveProject());
        dispatch(FetchOwnProductLists());

        // eslint-disable-next-line no-unused-vars
        const { status, data, message } = loggedInUserDetails;

        if (status === 200) {
          dispatch(setUserDetails(data[0]));
          history?.push("/");
        }
      } catch (error) {
        console.log(error);
      }
    };

    dispatch(getpopup());
    if (token) {
      GetUserDetails();
    }
  }, [dispatch, history]);
};

export default useUser;
