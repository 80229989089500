import React, { useState, useEffect } from "react";
import { otp } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Otp.scss";
import { Link, useHistory } from "react-router-dom";

export default function Otp() {
  const [userData, setuserData] = useState({
    otp: "",
  });
  const [error, setError] = useState({});
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setuserData({ ...userData, [name]: value });
  };
  const { status, email, successfullotp, successfullforgot } = useSelector(
    (e) => e.Auth
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const validation = () => {
    let error = {};
    if (!userData.otp) {
      error.otp = "OTP is required";
    }

    return error;
  };

  useEffect(() => {
    if (successfullotp === true) {
      history.push("/reset-password");
    }
  }, [successfullotp]);

  useEffect(() => {
    if (localStorage.getItem("pointb_token")) {
      history.push("/");
    }
  }, []);

  // useEffect(() => {
  //   if (successfullotp === false) {
  //     history.push("/login");
  //   }
  // }, [successfullotp]);

  const submitdata = (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(validation());
    if (Object.keys(ErrorList).length !== 0) {
    } else {
      const user = {
        otp: userData.otp,
        email: email,
      };
      dispatch(otp(user));
    }
  };
  return (
    <>
      <div className="gapsm">
        <div className="container">
          <div className="innerLogin">
            <h2>OTP Verification</h2>

            <br />
            <div className="form-group">
              <label>Your OTP</label>
              <input
                type="password"
                placeholder="Your OTP"
                name="otp"
                value={userData.otp}
                onChange={postUserData}
              />
              <div className="error">{error.otp}</div>
            </div>

            {status === true ? (
              <div className="loginbtn">
                <button
                  type="button"
                  disabled={status}
                  onClick={submitdata}
                  className="btn"
                >
                  Loading
                </button>
              </div>
            ) : (
              <div className="loginbtn">
                <button type="button" onClick={submitdata} className="btn">
                  Verify OTP
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
