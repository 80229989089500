import React, {  useEffect } from "react";
import "./Cities.scss";
import { useDispatch, useSelector } from "react-redux";
import { getcities } from "../../Redux/CmsSlice";
// import {
//     Accordion,
//     AccordionDetails,
//     AccordionSummary,
//     Typography,
// } from "@material-ui/core";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Cities = () => {
    // const [city, setcity] = useState([
    //     "Los Angeles",
    //     "San Diego",
    //     "San Jose",
    //     "San Francisco",
    //     "Fresno",
    //     "Sacramento",
    //     "Long Beach",
    //     "Oakland",
    //     "Bakersfield",
    //     "Anaheim",
    //     "Santa Ana",
    //     "Riverside",
    //     "Stockton",
    //     "Chula Vista",
    //     "Irvine",
    //     "Fremont",
    //     "San Bernardino",
    //     "Modesto",
    //     "Fontana",
    //     "Oxnard",
    //     "Moreno Valley",
    //     "Huntington Beach",
    //     "Glendale",
    //     "Santa Clarita",
    //     "Garden Grove",
    //     "Oceanside",
    //     "Rancho Cucamonga",
    //     "Santa Rosa",
    //     "Ontario",
    //     "Lancaster",
    //     "Elk Grove",
    //     "Corona",
    //     "Palmdale",
    //     "Salinas",
    //     "Pomona",
    //     "Hayward",
    //     "Escondido",
    //     "Torrance",
    //     "Sunnyvale",
    //     "Orange",
    //     "Fullerton",
    //     "Pasadena",
    //     "Thousand Oaks",
    //     "Visalia",
    //     "Simi Valley",
    //     "Concord",
    //     "Roseville",
    //     "Victorville",
    //     "Santa Clara",
    //     "Vallejo",
    //     "Berkeley",
    //     "El Monte",
    //     "Downey",
    //     "Costa Mesa",
    //     "Inglewood",
    //     "Carlsbad",
    //     "San Buenaventura (Ventura)",
    //     "Fairfield",
    //     "West Covina",
    //     "Murrieta",
    //     "Richmond",
    //     "Norwalk",
    //     "Antioch",
    //     "Temecula",
    //     "Burbank",
    //     "Daly City",
    //     "Rialto",
    //     "Santa Maria",
    //     "El Cajon",
    //     "San Mateo",
    //     "Clovis",
    //     "Compton",
    //     "Jurupa Valley",
    //     "Vista",
    //     "South Gate",
    //     "Mission Viejo",
    //     "Vacaville",
    //     "Carson",
    //     "Hesperia",
    //     "Santa Monica",
    //     "Westminster",
    //     "Redding",
    //     "Santa Barbara",
    //     "Chico",
    //     "Newport Beach",
    //     "San Leandro",
    //     "San Marcos",
    //     "Whittier",
    //     "Hawthorne",
    //     "Citrus Heights",
    //     "Tracy",
    //     "Alhambra",
    //     "Livermore",
    //     "Buena Park",
    //     "Menifee",
    //     "Hemet",
    //     "Lakewood",
    //     "Merced",
    //     "Chino",
    //     "Indio",
    //     "Redwood City",
    //     "Lake Forest",
    //     "Napa",
    //     "Tustin",
    //     "Bellflower",
    //     "Mountain View",
    //     "Chino Hills",
    //     "Baldwin Park",
    //     "Alameda",
    //     "Upland",
    //     "San Ramon",
    // ]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getcities());
    }, [dispatch]);

    const countryWiseData = useSelector((e) => e?.CmsDetails?.citiesdata?.data?.data);
    // console.log(countryWiseData,"countryWiseData")
    return (
        <section className='citiesWrap py-5'>
            <div className='container py-md-3'>
                <div className='text-center'>
                    <h1 className='text-center'>Municiple ADU Regulation</h1>
                    <p className='description-text'>
                        The passage experienced a surge in popularity during the
                        1960s when Letraset used it on their dry-transfer
                        sheets, and again during the 90s as desktop publishers
                        bundled the text with their software. Today it's seen
                        all around the web; on templates, websites, and stock
                        designs. Use our generator to get your own, or read on
                        for the authoritative history of lorem ipsum
                    </p>
                </div>

                        {countryWiseData?.map((country)=>{
                            return(
                                <div className="cities_list">
                                <h2>{country.title}</h2>
                                {country.state.map((item)=>(
                                    <div className="cities_nested">
                                    <h3>{item.title}</h3>
                                    {item.counties.map((_country)=>(
                                        <div className="cities_inner">
                                        <h4>{_country.title}</h4>
                                        <ul>
                                        {_country.cities.map((city)=>(
                                            <li>{city.title}</li>
                                        ))}
                                        </ul>
                                    </div>
                                    ))}                                    
                                </div>
                                ))}
                            </div>
                            )
                        })}
            </div>
        </section>
    );
};

export default Cities;
