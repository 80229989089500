import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../Helper/Helper";

//get footer links
export const getFooterLinks = createAsyncThunk(
  "get-footer-links",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: `/api/setting/getlist`,
      method: "GET",
    });

    const { data: responseData } = response.data;

    return responseData;
  }
);

const initialState = {
  status: "idle",
  footerDetails: [],
};

export const FooterSlice = createSlice({
  name: "FooterDetails",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFooterLinks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFooterLinks.fulfilled, (state, { payload }) => {
        state.status = "idle";

        state.footerDetails = payload;

        return state;
      });
  },
});

export default FooterSlice.reducer;
