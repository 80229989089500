import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../Helper/Helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const initialState = {
  status: false,
  contactusmessage: "",
};

export const contact = createAsyncThunk("/contact", async (user) => {
  const response = await ApiHelperFunction({
    urlPath: "/api/contactus",
    method: "POST",
    formData: user,
  });

  if (response !== "") {
  }

  return response;
});

export const contactSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(contact.pending, (state, action) => {
        state.status = true;
      })
      .addCase(contact.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          toast.success(action?.payload?.data?.message, {
            type: "success",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.status = false;
          state.contactusmessage = action?.payload?.data?.message;
        } else {
          toast.success(action?.payload?.data?.message, {
            type: "error",
            hideProgressBar: true,
            autoClose: 2500,
          });
          state.contactusmessage = action?.payload?.data?.message;
          state.status = false;
        }

        return state;
      });
  },
});

export default contactSlice.reducer;
