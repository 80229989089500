import React, { useEffect } from "react";
//import { Modal, Button } from "react-bootstrap";
import { API_URL } from "../../../Helper/Helper";
//import fileDownload from "js-file-download";
import { useSelector } from "react-redux";

import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import SmallIcon from "../../smallIcon/SmallIcon";
import { toast } from "react-toastify";
const OrderDetail = (props) => {
  let params = useLocation();
  const history = useHistory();

  params = params.pathname.split("/");
  // console.log(currentitem)
  // console.log(currentitem?.payment1_details)
  const { activeProjectList} = useSelector((e) => e.ActiveSlice);
  let val = activeProjectList?.data?.filter((x) => x?._id === params[3]);
  let currentitem = val && val[0];
  console.log(currentitem);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const file_download = (csvlink, filename) => {
    if (filename) {
      const config = { responseType: "blob" };
      axios.get(csvlink, config).then((message) => {
        if (typeof window.chrome !== "undefined") {
          // Chrome version
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(message.data);
          a.href = url;
          a.download = filename;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else if (typeof window.navigator.msSaveBlob !== "undefined") {
          // IE version
          var blob = new Blob([message.data], {
            type: "application/force-download",
          });
          window.navigator.msSaveBlob(blob, filename);
        } else {
          // Firefox version
          var file = new File([message.data], filename, {
            type: "application/force-download",
          });
          window.open(URL.createObjectURL(file));
        }
      });
    } else {
      toast.warning("Invoice is not available");
    }
  };

  return (
    <>
      <div className="bannerCont container">
        <div className="innerHmBan bannerInner">
          <button
            className="button-back"
            onClick={() => history.push("/activeproject")}
            style={{
              border: "1px solid #c2b7b7",
              borderRadius: "14px",
              fontWeight: "bold",
            }}
          >
            {" "}
            {"<"} Back
          </button>

          <div className="ord-heading">
            <h2>{currentitem?.showroom_details?.title}</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="total-pic">
                <div className="order">
                  <div className="order-img">
                    <figure>
                      <img
                        src={
                          currentitem?.showroom_details?.default_image === ""
                            ? "/images/blank.png"
                            : `${API_URL}/uploads/showroom/${currentitem?.showroom_details?.default_image}`
                        }
                        alt=""
                      />
                    </figure>
                  </div>

                  <div className="cart-icon">
                    {currentitem?.showroom_details?.showroom_floor_plan !==
                    undefined
                      ? currentitem?.showroom_details?.showroom_floor_plan?.map(
                          (id) => (
                            <>
                              <SmallIcon img="bed.svg" /> {id?.bedroom_numbers},{" "}
                              <SmallIcon img="bath.svg" />{" "}
                              {id?.bathroom_numbers}
                            </>
                          )
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="total-payment">
                {currentitem && currentitem?.payment1_details ? (
                  <div className="payment-detail">
                    <div className="payment-heading main-heading">
                      <h3>Payment (1)</h3>
                    </div>
                    {/* {console.log(
                      currentitem?.payment1_details?.payment_mode,
                      "mode"
                    )}
                    {currentitem?.payment1_details?.payment_mode ===
                      "Debit" && (
                      <>
                        <div className="payment-heading">
                          <h3>
                            Order Id :{" "}
                            <span>
                              {currentitem?.payment1_details?.orderId}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <h3>
                            Payment Status :{" "}
                            <span className="success">
                              {currentitem?.payment1_details?.payment_status}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <h3>
                            Payment Mode :{" "}
                            <span>
                              {currentitem?.payment1_details?.payment_mode}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <h3>
                            Transaction Id :{" "}
                            <span>
                              {currentitem?.payment1_details?.transcationID}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <button
                            className="download-btn"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              file_download(
                                API_URL +
                                  "/uploads/project/invoice/" +
                                  currentitem?.payment1_details?.invoice_name,
                                currentitem?.payment1_details?.invoice_name
                              )
                            }
                          >
                            Download
                          </button>
                        </div>
                      </>
                    )} */}
                    <>
                     
                        <div className="payment-heading">
                          <h3>
                            Order Id :{" "}
                            <span>
                              {currentitem?.payment1_details?.orderId}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <h3>
                            Payment Status :{" "}
                            <span className="success">
                              {currentitem?.payment1_details?.payment_status}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <h3>
                            Payment Mode :{" "}
                            <span>
                              {currentitem?.payment1_details?.payment_mode}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <h3>
                            Transaction Id :{" "}
                            <span>
                              {currentitem?.payment1_details?.transcationID}
                            </span>
                          </h3>
                        </div>
                        <div className="payment-heading">
                          <button
                            className="download-btn"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              file_download(
                                API_URL +
                                  "/uploads/project/invoice/" +
                                  currentitem?.payment1_details?.invoice_name,
                                currentitem?.payment1_details?.invoice_name
                              )
                            }
                          >
                            Download
                          </button>
                        </div>
                      </>
                  </div>
                ) : (
                  ""
                )}

                {currentitem &&
                currentitem?.payment2_details?.invoice_name === "" ? (
                  <></>
                ) : (
                  <div className="payment-detail2">
                    {currentitem && currentitem?.payment2_details ? (
                      <div className="payment-detail">
                        <div className="payment-heading main-heading">
                          <h3>Payment (2)</h3>
                        </div>
                        {currentitem?.payment2_details?.payment_mode ===
                        "Debit" ? (
                          <>
                            <div className="payment-heading">
                              <h3>
                                Order Id :{" "}
                                <span>
                                  {currentitem?.payment2_details?.orderId}
                                </span>
                              </h3>
                            </div>
                            <div className="payment-heading">
                              <h3>
                                Payment Status :{" "}
                                <span className="success">
                                  {
                                    currentitem?.payment2_details
                                      ?.payment_status
                                  }
                                </span>
                              </h3>
                            </div>
                            <div className="payment-heading">
                              <h3>
                                Payment Mode :{" "}
                                <span>
                                  {currentitem?.payment2_details?.payment_mode}
                                </span>
                              </h3>
                            </div>
                            <div className="payment-heading">
                              <h3>
                                Transaction Id :{" "}
                                <span>
                                  {currentitem?.payment2_details?.transcationID}
                                </span>
                              </h3>
                            </div>
                            <div className="payment-heading">
                              <button
                                className="download-btn"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  file_download(
                                    API_URL +
                                      "/uploads/project/invoice/" +
                                      currentitem?.payment2_details
                                        ?.invoice_name,
                                    currentitem?.payment2_details?.invoice_name
                                  )
                                }
                              >
                                Download
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
