/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./Newhome.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import {
  getHomeDetails,
  getservicearealist,
  gettestimonial,
} from "../../Redux/CmsSlice";
import PaintIcon from "../../asset/phone.png";
import { API_URL } from "../../Helper/Helper";

export const SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  autoPlay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Home2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { singleHomeDetails, testimonials, servicearealist } = useSelector(
    (state) => state?.CmsDetails
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(getHomeDetails());
    dispatch(gettestimonial());
    dispatch(getservicearealist());
  }, [dispatch]);

  const whatmakescon = [
    {
      heading: singleHomeDetails?.section1_button1_text,
      image: "./images/icon11.svg",
    },
    {
      heading: singleHomeDetails?.section1_button2_text,
      image: "./images/icon16.svg",
    },
    {
      heading: singleHomeDetails?.section1_button3_text,
      image: "./images/icon15.svg",
    },
    {
      heading: singleHomeDetails?.section1_button4_text,
      image: "./images/icon14.svg",
    },
  ];

  const howitworks = [
    {
      heading: singleHomeDetails?.section3_heading1,
      image: "./images/icon20.svg",
      description: singleHomeDetails?.section3_content1,
      number: "1",
    },
    {
      heading: singleHomeDetails?.section3_heading2,
      image: "./images/icon25.svg",
      description: singleHomeDetails?.section3_content2,
      number: "2",
    },
    {
      heading: singleHomeDetails?.section3_heading3,
      image: "./images/icon26.svg",
      description: singleHomeDetails?.section3_content3,
      number: "3",
    },
    {
      heading: singleHomeDetails?.section3_heading4,
      image: "./images/icon24.svg",
      description: singleHomeDetails?.section3_content4,
      number: "4",
    },
  ];

  return (
    <>
      <div className="home2">
        <div className="banerimg">
          <figure>
            <img
              src={API_URL + `/uploads/home/${singleHomeDetails?.image}`}
              alt=""
              className="homebaner"
            />
          </figure>

          <div className="banertext">
            <div className="container">
              <h2>{singleHomeDetails?.smalltitle}</h2>
              <h1>{singleHomeDetails?.title}</h1>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${singleHomeDetails?.content}`,
                  }}
                ></div>
              </p>
              <button
                className="choose-design"
                onClick={() => history.push("/showroom")}
              >
                {singleHomeDetails?.button_text}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="whatmakes">
        <div className="container">
          <h2>{singleHomeDetails?.section1_heading}</h2>
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: `${singleHomeDetails?.section1_content}`,
              }}
            ></div>
          </p>
          <div className="containsection">
            <div className="row">
              {whatmakescon?.length > 0 &&
                whatmakescon?.map(
                  (item) => (
                    <div className="col-md-3" key={item?.heading}>
                      <div className="what-makes-con">
                        <figure>
                          <img src={item?.image} alt="" />
                          {/* <img src="./images/icon11.svg" alt="" /> */}
                        </figure>
                        <h4>{item?.heading}</h4>
                        {/* <h4>All-Inclusive Transparent Pricing</h4> */}
                      </div>
                    </div>
                  )
                  // <div className="col-sm-3">
                  //   <div className="what-makes-con">
                  //     <figure>
                  //       <img src="./images/icon12.svg" alt="" />
                  //     </figure>
                  //     <h4>Professionally Crafted Designs</h4>
                  //   </div>
                  // </div>
                  // <div className="col-sm-3">
                  //   <div className="what-makes-con">
                  //     <figure>
                  //       <img src="./images/icon13.svg" alt="" />
                  //     </figure>
                  //     <h4>Carefully Selected Professionals</h4>
                  //   </div>
                  // </div>
                  // <div className="col-sm-3">
                  //   <div className="what-makes-con">
                  //     <figure>
                  //       <img src="./images/icon14.svg" alt="" />
                  //     </figure>
                  //     <h4>Fully Integrated Experience</h4>
                  //   </div>
                  // </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="whatmakes background-none">
        <div className="container">
          <h2>{singleHomeDetails?.section2_heading}</h2>
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: `${singleHomeDetails?.section2_content}`,
              }}
            ></div>
          </p>
          <div className="containsection">
            <div className="row">
              {howitworks?.map((item) => (
                <div className="col-md-3" key={item?.heading}>
                  <div className="what-makes-con1">
                    <span>{item?.number}</span>
                    <figure>
                      <img src={item?.image} alt="" />
                    </figure>
                    <h4>{item?.heading}</h4>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}

              {/* <div className="col-sm-3">
                <div className="what-makes-con">
                  <figure>
                    <img src="./images/icon12.svg" alt="" />
                  </figure>
                  <h4>Professionally Crafted Designs</h4>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="what-makes-con">
                  <figure>
                    <img src="./images/icon13.svg" alt="" />
                  </figure>
                  <h4>Carefully Selected Professionals</h4>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="what-makes-con">
                  <figure>
                    <img src="./images/icon14.svg" alt="" />
                  </figure>
                  <h4>Fully Integrated Experience</h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hmphnsec gapsec staty-connect">
        <div className="container">
          <div className="row stay-connect-main">
            <div className="col-sm-5">
              <div className="phvideo_area">
                <img className="phimg" src={PaintIcon} alt="" />
                <video autoPlay loop muted playsInline>
                  <source
                    // src="https://pointb.dedicateddevelopers.us/uploads/video/pointb_video_sample.mp4"
                    src="https://admin.spatial.site/uploads/video/pointb_video_sample.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="stay-connect-main">
                <div className="staty-connectmain">
                  <h2>{singleHomeDetails?.section4_heading}</h2>
                  <p>{singleHomeDetails?.section4_content}</p>
                  <ul>
                    <li>{singleHomeDetails?.section4_point1}</li>
                    <li>{singleHomeDetails?.section4_point2}</li>
                    <li>{singleHomeDetails?.section4_point3}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CUSTOMERS TESTIMONIAL */}
      {/* <div className="testimonials">
        <h2>{singleHomeDetails?.testimonial_heading}</h2>
        <div className="container">
          <div className="testimonials-silder">
            <Slider {...SLIDER_SETTINGS}>
              {testimonials?.length &&
                testimonials?.map((item) => {
                  return (
                    <div>
                      <div className="sildermain">
                        <p>
                          <span>{item?.content}</span>{" "}
                        </p>
                        <div className="silder-mid">
                          <figure>
                            <img
                              src={
                                API_URL + "/uploads/testimonial/" + item?.image
                              }
                              alt=""
                            />
                          </figure>
                          <div>
                            <h4>{item?.title}</h4>
                            <h5>{item?.designation}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Home2;
