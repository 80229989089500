import React, { useState, useEffect } from "react";
import "./Showroom.scss";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { API_URL, ApiHelperFunction } from "../../Helper/Helper";

import { FormControl, MenuItem, Select } from "@mui/material";

import SmallIcon from "../smallIcon/SmallIcon";

import {
  CreatePoduct,
  setProductDetails,
} from "../../Redux/ProductSlice";
import {
  clrdata,
  getdesignlist,
  getfloorlist,
  showroomDetailsById,
} from "../../Redux/CmsSlice";
import { getShowroomlist } from "../../Redux/showroomSlice";
import { toast } from "react-toastify";

export default function Showroom() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentShowroomDetails = useSelector(
    (state) => state?.CmsDetails?.viewshowroom
  );
  const { status, singleShowroomlist } = useSelector((state) => state.showroom);
  const cfloorlist = useSelector((state) => state?.CmsDetails?.floorlist);
  const designlist = useSelector((state) => state?.CmsDetails?.designlist);

  const [showModal, setShowModal] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [showroomId, setShowroomId] = useState();
  const [floorId, setFloorId] = useState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (location?.state?.product_id === undefined) {  // look into it latter
      localStorage.removeItem("item_id");
    }
    dispatch(setProductDetails({}));
  }, [dispatch, location?.state?.product_id]);

  useEffect(() => {
    dispatch(getShowroomlist());
    dispatch(getfloorlist());
    dispatch(getdesignlist());
  }, [dispatch]);

  // modal id wise Filter
  useEffect(() => {
    if (showroomId !== undefined || floorId !== undefined) {
      const filters = {};

      if (showroomId !== undefined) {
        filters.design = showroomId;
      }

      if (floorId !== undefined) {
        filters.floor_plan = floorId;
      }

      dispatch(getShowroomlist(filters));
    }
  }, [dispatch, showroomId, floorId]);

  /* MODAL */
  const viewdesign = (showroomId) => {
    dispatch(showroomDetailsById(showroomId));
    setShowModal(true);
  };

  useEffect(() => {
    if (
      currentShowroomDetails?.default_image &&
      currentShowroomDetails?.other_image
    ) {
      const newarray = currentShowroomDetails.other_image.map(
        (x) => API_URL + "/uploads/showroom/" + x
      );
      setSliderImages(newarray);
    }
  }, [currentShowroomDetails]);

  useEffect(() => {
    if (!showModal) {
      dispatch(clrdata());
    }
  }, [dispatch, showModal]);

  // didn't refactor this useEffect will look latter
  useEffect(() => {
    if (location.pathname === "/showroom") {
      let productd = location?.state?.product_id; // result: 'some_value'
      const showroomId = localStorage.getItem("item_id");
      dispatch(CreatePoduct({ showroom_id: showroomId, project_id: productd }));
    } // result: '/secondpage'
  }, [dispatch, location]);

  /* Select Desighn Handler */
  const selectDesignHandler = async (showroomDetails) => {
    try {

     const token =  window.localStorage.getItem("pointb_token")


      if (!token) {
        history.push("/login");
      }
      
      localStorage.setItem("showroom_id", showroomDetails._id);

      const response = await ApiHelperFunction({
        urlPath: `/api/project/inactive/projectCreate`,
        method: "POST",
        formData: {
          showroom_id: showroomDetails._id,
        },
      });

      const { status, data: responseData } = response.data;
      console.log("PRODUCT CREATED: ", responseData);

      if (status === 200) {
        dispatch(setProductDetails(responseData));
        history.push(`${responseData?._id}/choosedesign`);
        localStorage?.setItem("product_id", responseData?._id); // if any need in future
      }
    } catch (error) {
      toast.warning(`${error.message}`);
    }

    // const productId =
    // location?.state?.product_id || localStorage.getItem("product_id");
    // if (productId !== undefined && productId !== "undefined") {
    //   dispatch(
    //     CreatePoduct({
    //       showroom_id: showroomDetails._id,
    //       project_id: productId,
    //     })
    //   );
    // } else {
    //   dispatch(setProductShowroomDesignId(showroomDetails._id));
    //   dispatch(CreatePoduct({ showroom_id: showroomDetails._id }));
    // }
  };

  return (
    <>
      <div className="showroomban">
        <figure>
          <img src="./images/showroomban.jpeg" alt="banner" />
        </figure>
        <div className="bancont container">
          <h1>Our Models</h1>
        </div>
      </div>

      <div className="showroomItems">
        <div className="container">
          <div className="selectfilter">
            <ul>
              <li className="mui_select">
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => setShowroomId(e?.target?.value)}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty
                    defaultValue=""
                    placeholder="Select Category"
                    style={{
                      elevation: 0,
                      boxShadow: "none",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          marginTop: "5px",
                          borderRadius: "15px",
                          border: "1px solid #BFC6D2",
                          elevation: 0,
                          boxShadow: "none",
                        },
                      },
                    }}
                  >
                    <MenuItem style={{ fontFamily: "futuramedium" }} value={""}>
                      All
                    </MenuItem>
                    {designlist?.length > 0 &&
                      designlist.map((item) => {
                        return (
                          <MenuItem
                            style={{ fontFamily: "futuramedium" }}
                            value={item?._id}
                          >
                            {item?.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </li>

              <li className="mui_select">
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => setFloorId(e?.target?.value)}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty
                    defaultValue=""
                    placeholder="Select Category"
                    style={{
                      elevation: 0,
                      boxShadow: "none",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          marginTop: "5px",
                          borderRadius: "15px",
                          border: "1px solid #BFC6D2",
                          elevation: 0,
                          boxShadow: "none",
                        },
                      },
                    }}
                  >
                    <MenuItem style={{ fontFamily: "futuramedium" }} value={""}>
                      Floor Plan
                    </MenuItem>

                    {cfloorlist?.length > 0 &&
                      cfloorlist.map((item) => (
                        <MenuItem
                          style={{ fontFamily: "futuramedium" }}
                          key={item?._id}
                          value={item?._id}
                        >
                          {item?.studio && <>{item?.bedroom_numbers} Studio </>}
                          {item?.bedroom_numbers && (
                            <>
                              {" "}
                              <SmallIcon img="bed.svg" />{" "}
                              {item?.bedroom_numbers}{" "}
                            </>
                          )}
                          {item?.bathroom_numbers && (
                            <>
                              {" "}
                              <SmallIcon img="bath.svg" />{" "}
                              {item?.bathroom_numbers}{" "}
                            </>
                          )}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </li>
            </ul>
          </div>

          <div className="row">
            {status === "loading" ? (
              <>
                <div className="loaderwrapper">
                  <img src="./images/loader.gif" alt="loading" />
                </div>
              </>
            ) : (
              <>
                {singleShowroomlist?.length ? (
                  singleShowroomlist?.map((showroom, index) => {
                    return (
                      <div className="col-lg-4 col-md-6" key={index}>
                        <div className="item">
                          <figure>
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => viewdesign(showroom?._id)}
                              src={
                                showroom?.default_image === ""
                                  ? "/images/blank.png"
                                  : `${API_URL}/uploads/showroom/${showroom?.default_image}`
                              }
                              alt={showroom?.title}
                            />
                          </figure>
                          <div className="itemInner">
                            <div className="nameBtn">
                              <h3>
                                {showroom?.model[0]?.title !== undefined && (
                                  <h6 style={{ fontSize: "26px" }}>
                                    {showroom?.title}
                                  </h6>
                                )}
                                <span>|</span>
                                <span className="smHead">
                                  {showroom?.model[0]?.title}
                                </span>
                              </h3>

                              <button
                                type="button"
                                className="btnwh"
                                onClick={() => {
                                  selectDesignHandler(showroom);
                                }}
                              >
                                Select Design
                              </button>
                            </div>

                            <div className="bedPrice">
                              <h4>
                                {showroom?.showroom_floor_plan?.map(
                                  (eachItem, index) => (
                                    <div key={index}>
                                      {eachItem.bedroom_numbers && (
                                        <>
                                          <SmallIcon img="bed.svg" />
                                          {eachItem.bedroom_numbers}{" "}
                                        </>
                                      )}
                                      {eachItem.studio && "Studio"}
                                      {eachItem.bathroom_numbers && (
                                        <>
                                          <SmallIcon img="bath.svg" />
                                          {eachItem.bathroom_numbers}
                                        </>
                                      )}
                                    </div>
                                  )
                                )}
                              </h4>

                              <p className="pricetag">
                                {showroom?.estimated_cost?.toLocaleString(
                                  "en-US",
                                  { style: "currency", currency: "USD" }
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    No data available
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        className="showroomdetailmodal"
      >
        <Modal.Header>
          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="closebtn"
          >
            <img src="./images/closeic.svg" alt="" />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="lftimgslider">
                {sliderImages?.length > 0 ? (
                  <Slider
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    infinite={false}
                  >
                    {sliderImages.map((imageURL, index) => (
                      <div key={index}>
                        <img src={imageURL} alt="" />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <>
                    <Slider
                      asNavFor={nav2}
                      ref={(slider1) => setNav1(slider1)}
                      infinite={false}
                    >
                      <div>
                        <img src="./images/blank.png" alt="" />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sliderRightsec">
                <h2>{currentShowroomDetails?.title}</h2>
                <h6>{currentShowroomDetails?.model.title}</h6>
                <ul className="showroomdetail">
                  <li>
                    <span className="icon">
                      <img src="./images/detailic1.svg" alt="" />
                    </span>
                    <span className="detailHeading">Starting Price</span>

                    {currentShowroomDetails?.estimated_cost?.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )}
                  </li>
                  <li>
                    <span className="icon">
                      <img src="./images/detailic2.svg" alt="" />
                    </span>
                    <span className="detailHeading">Floor Plan</span>
                    <ul className="bedbath">
                      <li>
                        {currentShowroomDetails?.showroom_floor_plan?.map(
                          (id, index) => (
                            <React.Fragment key={index}>
                              {id.bedroom_numbers !== "" && (
                                <>
                                  <SmallIcon img="bed.svg" />
                                  {id.bedroom_numbers}
                                </>
                              )}

                              {id.studio && "Studio"}

                              {id.bathroom_numbers !== "" && (
                                <React.Fragment>
                                  <SmallIcon img="bath.svg" />
                                  {id.bathroom_numbers}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="icon">
                      <img src="./images/detailic3.svg" alt="" />
                    </span>
                    <span className="detailHeading">Package Includes</span>
                    {currentShowroomDetails?.packge_includes}
                  </li>
                  <li>
                    <span className="icon">
                      <img src="./images/detailic4.svg" alt="" />
                    </span>
                    <span className="detailHeading">Estimated Size</span>~
                    {currentShowroomDetails?.estimated_size}
                    {"  sqft"}
                  </li>
                </ul>
                <div className="imgGal">
                  {sliderImages.length && (
                    <Slider
                      asNavFor={nav1}
                      ref={(slider2) => setNav2(slider2)}
                      slidesToShow={
                        sliderImages?.length >= 6 ? 5 : sliderImages?.length
                      }
                      swipeToSlide={true}
                      focusOnSelect={true}
                      infinite={false}
                    >
                      {sliderImages.map((imageURL, index) => (
                        <div className="media-thumble" key={index}>
                          {" "}
                          <img src={imageURL} alt="" />
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <button
              type="button"
              className="btnwh"
              onClick={() => {
                selectDesignHandler(currentShowroomDetails);
              }}
              style={{ borderRadius: "35px" }}
            >
              Select Design
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
