import React, { useState, useEffect, useMemo } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./SelectDesign.scss";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import {
  clrsignaturenoti,
  docuSign,
  product_details,
} from "../../Redux/ProductSlice";
import "react-toastify/dist/ReactToastify.css";

import { API_URL } from "../../Helper/Helper";
import CommonHeader from "./CommonHeader";

toast.configure();

export default function Step3() {
  const params = useLocation();
  const history = useHistory();
  const inputElement = useRef();
  const dispatch = useDispatch();

  const [productId, setProductId] = useState();

  const [numPages, setNumPages] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const signaturedocu = useSelector((state) => state?.product?.signature);
  const CurrentProductDetails = useSelector(
    (state) => state?.product?.productdet
  );
  console.log("FROM STEP3 :: CurrentProductDetails: ", CurrentProductDetails);

  const download_signed_document_step3 = useSelector(
    (state) => state?.product?.download_signed_document_step3
  );

  console.log(download_signed_document_step3, "download_signed_document_step3");

  // set ProductId
  useEffect(() => {
    if (!!params) {
      let _t = params?.pathname?.split("choosedesign");
      let _id = _t[0].split("/");
      setProductId(_id[1]);
    }
  }, [params]);

  // call for fetch product details with productId
  useEffect(() => {
    if (productId) {
      dispatch(product_details(productId));
    }
  }, [productId, dispatch]);

  useEffect(() => {
    const intervalForStepTwo = setInterval(() => {
      dispatch(product_details(productId));
      console.log("test 3");
    }, 30000);

    return () => {
      clearInterval(intervalForStepTwo);
    };
  });

  // const docuResponce = async (e) => {
  //   const accountID = "6e22a7f4-ea36-431c-832f-f2d6172d19de";
  //   const IntegratorKey = "d10f48f9-c4a9-4bb9-b39e-d95a1bb0dcf2";
  //   const docusign_username = "arijita.rudra@webskitters.com";
  //   const Docusign_Password = "tatasky23";
  //   const envelopeid = "72cf674b-5980-44e7-ac18-0df66cb408b8";
  //   try {
  //     const data = await axios(
  //       `https://demo.docusign.net/restapi/v2/accounts/${accountID}/envelopes/${envelopeid}/documents/1234`,
  //       {
  //         method: "get",
  //         headers: {
  //           "X-DocuSign-Authentication": JSON.stringify({
  //             Username: docusign_username,
  //             Password: Docusign_Password,
  //             IntegratorKey: IntegratorKey,
  //           }),
  //           "Content-Type": "application/pdf",
  //         },
  //         responseType: "stream",
  //       }
  //     );
  //     return data;
  //   } catch {
  //     console.log("docuResponce Error");
  //   }
  // };

  // useEffect(() => {
  //   //downloadPdf();
  //   docuResponce();
  // }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const changePage = (offSet) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  };

  useEffect(() => {
    if (signaturedocu?.data !== undefined) {
      toast.success(signaturedocu?.data?.message, {
        type: "success",
        hideProgressBar: true,
        autoClose: 5000,
      });
      dispatch(clrsignaturenoti());
    }
  }, [signaturedocu, dispatch]);
  console.log(
    `https://pointb.dedicateddevelopers.us/signed_documents/${download_signed_document_step3?.data?.data}`
  );

  useEffect(() => {
    if (download_signed_document_step3?.data?.status === 200) {
      setLoadData(false);
      setTimeout(() => {
        let alink = document.createElement("a");
        alink.setAttribute("target", "_blank");
        alink.href = `https://pointb.dedicateddevelopers.us/signed_documents/${download_signed_document_step3?.data?.data}`;
        alink.download = "SamplePDF.pdf";
        alink.click();
      }, 500);
    } else {
      console.log(download_signed_document_step3, "testing work");
    }
  }, [download_signed_document_step3]);

  // Function for DownloadDocs
  const DownloadDocs = () => {
    fetch(
      API_URL +
        "/uploads/project/additional_file/" +
        CurrentProductDetails?.basic_details?.status_document
    ).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };

  // Function for send mail For Signature
  const sendForSignature = () => {
    dispatch(docuSign({ project_id: productId }));
  };

  const redirectToStep4 = () => {
    if (CurrentProductDetails?.basic_details?.status_document === "") {
      toast.warning("Please wait for doc uploadation");
    } else if (CurrentProductDetails?.basic_details?.envelopestatus) {
      history.push("step4", { id: productId });
    } else {
      toast.warning("Please sign the doc first.");
    }
  };

  const isCompleted = useMemo(() => {
    console.log("object",CurrentProductDetails?.basic_details?.payment1_details?.payment_status)
    if (
      CurrentProductDetails?.basic_details?.isPaid?.payment_status !== "No" &&
      CurrentProductDetails?.basic_details?.isPaidPayment2 !== "No"
    )
      return 4;
      else if(CurrentProductDetails?.basic_details?.envelopestatus)
      return 3
    else return 2;
  }, [CurrentProductDetails?.basic_details]);

  console.log("isCompleted from step-3",isCompleted)

  return (
    <>
      <CommonHeader
        currentProjectStep={3}
        isCompleted={ isCompleted}
        redirectToStep={4}
        redirectToStep4={redirectToStep4}
      />
      <div className="gapsec pt-0">
        <div className="container">
          <div className="step3">
            <div className="logosec">
              <img src="/images/logo.svg" alt="" />
            </div>
            <div>
              <div
                style={{
                  height: "500px",
                  margin: "auto",
                  padding: "0px auto",
                  overflowY: "scroll",
                  overflowX: "scroll",
                  border: "2px solid #31719C",
                }}
              >
                {CurrentProductDetails?.basic_details?.status_document !=
                  null &&
                CurrentProductDetails?.basic_details?.status_document !== "" ? (
                  <>
                    <Document
                      ref={inputElement}
                      file={
                        API_URL +
                        "/uploads/project/additional_file/" +
                        CurrentProductDetails?.basic_details?.status_document
                      }
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page scale={1.6} size="A4" pageNumber={pageNumber} />
                    </Document>

                    <p style={{ textAlign: "center" }}>
                      Page {pageNumber} of {numPages}
                    </p>
                  </>
                ) : (
                  <>No Doc Found1 Please Contact Adim</>
                )}
              </div>

              {/* for PDF having more than 1 page */}
              <div style={{ textAlign: "center" }}>
                {CurrentProductDetails?.basic_details?.status_document !==
                  null && (
                  <>
                    {pageNumber > 1 && (
                      <button onClick={() => changePage(-1)}>
                        &lt;&lt; Previous Page
                      </button>
                    )}
                    {pageNumber < numPages && (
                      <button onClick={() => changePage(+1)}>
                        Next Page &gt;&gt;
                      </button>
                    )}
                  </>
                )}
              </div>

              <div style={{ position: "relative", margin: "10px 0" }}>
                {CurrentProductDetails?.basic_details?.envelopestatus ===
                false ? (
                  <div className="signaturesec">
                    <p>This is a PDF that requires a signature</p>

                    <button
                      className="btn btn-primary rounded-pill text-nowrap btn-sm"
                      style={{ width: "fit-content", blockSize: "fit-content" }}
                      onClick={sendForSignature}
                    >
                      <img
                        style={{
                          objectFit: "contain",
                          width: "30px",
                          height: "28px",
                          display: "inline-block",
                          paddingRight: "4px",
                        }}
                        src="/images/signature.png"
                        alt="signature-icon"
                      />
                      send mail
                    </button>
                  </div>
                ) : CurrentProductDetails?.basic_details?.envelopestatus ===
                  true ? (
                  <>
                    <div className=" d-flex justify-content-between align-items-center">
                      <div className=" d-flex justify-content-between align-items-center">
                        <p className="d-flex justify-content-between align-items-center">
                          Document already signed
                        </p>
                        {/* {loadData && ( */}

                        {/* )} */}
                      </div>

                      {localStorage.getItem("activeproject") &&
                        (loadData ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <div>
                            <button
                              className="btnwh btn-sm"
                              onClick={DownloadDocs}
                              style={{ margin: "0 15px 0 0" }}
                            >
                              Download
                            </button>
                            <button
                              className="btn btnwh btn-outline-success"
                              style={{
                                backgroundColor: "transparent",
                                color: "#31719c",
                              }}
                              onClick={() => {
                                history.push("step4", { id: productId });
                              }}
                            >
                              Continue
                            </button>
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* <div className="loader-class">
            
              <div>
              <TailSpin width="100" color="grey" />
                
              </div>
          
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
