import axiosInstance from "..";
import { baseUrlApi, endpoints } from "../endpoints";

export const signUp = async (userFormData) => {
  const res = await axiosInstance.post(endpoints.auth.signup, userFormData);

  return res.data;
};

export const socialSignUp = async (userFormData) => {
  const res = await axiosInstance.post(
    endpoints.auth.socialLogin,
    userFormData
  );

  return res.data;
};

export const login = async (userFormData) => {
  const res = await axiosInstance.post(
    `${baseUrlApi}/${endpoints.auth.login}`,
    userFormData
  );

  return res.data;
};

export const forgotPassword = async (userFormData) => {
  const res = await axiosInstance.post(
    endpoints.auth.forgetPassword,
    userFormData
  );

  return res.data;
};

export const getResetPassword = async (params) => {
  return axiosInstance
    .post(endpoints.auth.resetPassword, params)
    ?.then((response) => response.data);
};

export const updateProfile = async (userFormData) => {
  const res = await axiosInstance.post(
    endpoints.auth.profileUpdate,
    userFormData
  );
  return res.data;
};

export const getProfileDetails = async (body) => {
  const res = await axiosInstance.get(endpoints.auth.profileDetails);

  return res.data;
};

export const changePassword = async (params) => {
  const res = axiosInstance.post(endpoints.auth.changepassword, params);

  return res.data;
};

export const addNewAddress = async (params) => {
  const res = await axiosInstance.post(endpoints.auth.addAddress, params);

  return res.data;
};

export const removeAddress = async (addressId) => {
  console.log("addressId: ",addressId)
  const res = await axiosInstance.post(endpoints.auth.removeAddress, {address_arr_id: addressId});

  return res.data;
};
