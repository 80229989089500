import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import "./SelectDesign.scss";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import {
  milestone,
  paymentMilestone,
  product_details,
  clearSuccessPaymentStatus,
} from "../../Redux/ProductSlice";
import { instructionCms } from "../../Redux/CmsSlice";
import PaymentModal from "./PaymentModal";
import { API_URL } from "../../Helper/Helper";
import CommonHeader from "./CommonHeader";
import Slider from "react-slick";
import SmallIcon from "../smallIcon/SmallIcon";

export default function Step4() {
  const params = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [productId, setProductId] = useState();
  const [accordianState, setAccordianState] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);

  const payment_group_details = useSelector(
    (state) => state?.product?.paymentmilstep
  );
  const CurrentProductDetails = useSelector(
    (state) => state?.product?.productdet
  );

  // set ProductId
  useEffect(() => {
    if (!!params) {
      let _t = params?.pathname?.split("step4");
      let _id = _t[0].split("/");

      setProductId(localStorage.getItem("product_id") ?? _id[1]);
    }
  }, [params]);

  // call for fetch product details with productId
  useEffect(() => {
    if (productId) {
      dispatch(product_details(productId));
    }
  }, [productId, dispatch]);

  useEffect(() => {
    if (productId && CurrentProductDetails) {
      dispatch(milestone());
      dispatch(instructionCms());

      if (CurrentProductDetails?.basic_details?.address !== undefined) {
        dispatch(
          paymentMilestone({
            id: productId,
          })
        );
      }
    }
  }, [dispatch, productId, CurrentProductDetails]);

  useEffect(() => {
    if (CurrentProductDetails && payment_group_details) {
      const tempPaymentDetails = [
        {
          ...CurrentProductDetails?.basic_details?.payment1_details,
          isPaid: CurrentProductDetails?.basic_details?.isPaid,
          payOut: payment_group_details?.payment_one?.price,
        },
        {
          ...CurrentProductDetails?.basic_details?.payment2_details,
          isPaid: CurrentProductDetails?.basic_details?.isPaidPayment2,
          isPaidPayment2: CurrentProductDetails?.basic_details?.isPaidPayment2,
          payOut: payment_group_details?.payment_two?.price,
        },
      ];

      setPaymentDetails(tempPaymentDetails);
    }
  }, [CurrentProductDetails, payment_group_details]);

  const updateAccordian = (arg) => {
    if (accordianState === undefined) {
      setAccordianState(0);
    } else if (accordianState) {
      setAccordianState(arg);
    } else {
      setAccordianState(null);
    }
  };

  // set Showroom slider (for images)
  useEffect(() => {
    if (CurrentProductDetails?.basic_details?.showroom_id?.other_image) {
      const newarray =
        CurrentProductDetails?.basic_details?.showroom_id?.other_image.map(
          (x) => API_URL + "/uploads/showroom/" + x
        );

      setSliderImages(newarray);
    }
  }, [CurrentProductDetails?.basic_details?.showroom_id]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <button>
        <ArrowBackIosIcon />
      </button>
    ),
    nextArrow: (
      <button>
        <ArrowForwardIosIcon />
      </button>
    ),
  };

  const isCompleted = useMemo(() => {
    if (
      CurrentProductDetails?.basic_details?.isPaid?.payment_status !== "No" &&
      CurrentProductDetails?.basic_details?.isPaidPayment2 !== "No"
    )
      return 4;
    else return 3;
  }, [CurrentProductDetails?.basic_details]);

  console.log("isCompleted from step-4", isCompleted);
  return (
    <>
      <CommonHeader
        currentProjectStep={4}
        redirectToStep={3}
        isCompleted={isCompleted}
        redirectToStep3={() => history.push("step3", { id: productId })}
      />

      <div className="gapsec pt-0 step4">
        <div className="container wrapper_stepFour d-flex  justify-content-between">
          <div className="step_forStepfour">
            {/* <span
              dangerouslySetInnerHTML={{
                __html: `${instructionList?.data?.data[0]?.content}`,
              }}
            ></span> */}
            <div className="designDetail stepfourSlide">
              <div class="card px-1">
                <Slider {...settings}>
                  {sliderImages.length ? (
                    sliderImages.map((imageUrl, index) => (
                      <img
                        key={index}
                        src={imageUrl || "/images/blank.png"}
                        alt="shorroom-imgs"
                        style={{
                          height: "100px",
                          maxHeight: "100px",
                          cursor: "pointer",
                        }}
                      />
                    ))
                  ) : (
                    <img
                      src={"/images/blank.png"}
                      alt="blank-imgs"
                      style={{
                        height: "100px",
                        maxHeight: "100px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Slider>

                <div class="card-body slider_cardDetails">
                  <h5 class="card-title">
                    {CurrentProductDetails?.basic_details?.showroom_id?.title}
                  </h5>

                  <ul className="showroomdetail">
                    <li className="mt-2">
                      <ul className="bedbath1 mt-2">
                        {CurrentProductDetails?.basic_details?.showroom_id
                          ?.floor_plan !== undefined &&
                          CurrentProductDetails?.basic_details?.showroom_id?.floor_plan?.map(
                            (id) => (
                              <>
                                <li>
                                  <SmallIcon img="bed.svg" />

                                  <>
                                    {" "}
                                    {id?.bedroom_numbers ?? "0"}
                                    <span style={{ marginLeft: "2px" }}>
                                      Bedroom
                                    </span>
                                  </>
                                </li>
                                <li>
                                  <SmallIcon img="bath.svg" />

                                  <>
                                    {" "}
                                    {id?.bathroom_numbers ?? "0"}
                                    <span style={{ marginLeft: "2px" }}>
                                      Bathroom
                                    </span>
                                  </>
                                </li>
                                {/* <li className="d-flex align-content-center">
                                  <CalendarMonthIcon />{" "}
                                  <span className="h6">
                                    {`${moment(
                                      CurrentProductDetails?.basic_details?.date
                                    )?.format("ddd, MMM Do YYYY")} ${
                                      CurrentProductDetails?.basic_details
                                        ?.start_time
                                    }`}
                                  </span>
                                </li> */}
                              </>
                            )
                          )}
                      </ul>
                    </li>
                    <li className="mt-2">
                      <div>
                        <span className="icon pe-1">
                          <img
                            src="/images/detailic3.svg"
                            alt="package-detail-img"
                          />
                        </span>
                        <span className="detailHeading h5 card-text">
                          Package Includes:{" "}
                        </span>
                      </div>
                      <p className="h6">
                        {
                          CurrentProductDetails?.basic_details?.showroom_id
                            ?.packge_includes
                        }
                      </p>
                    </li>
                    <li className="mt-2">
                      <span className="icon pe-1">
                        <img src="/images/detailic4.svg" alt="detailic4" />
                      </span>
                      <span className="detailHeading h5 pe-1">
                        Estimated Size
                      </span>

                      <span className="h6">
                        ~
                        {
                          CurrentProductDetails?.basic_details?.showroom_id
                            ?.estimated_size
                        }{" "}
                        sqft
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="accordionsec2">
            <Accordion defaultActiveKey="0">
              {paymentDetails?.length > 0 &&
                paymentDetails?.map((paymentDetail, index) => {
                  return (
                    <Accordion.Item
                      onClick={() => updateAccordian(index.toString())}
                      eventKey={index.toString()}
                    >
                      <Child
                        paymentDetails={paymentDetail}
                        address={CurrentProductDetails?.basic_details?.address}
                        index={index}
                      />
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}

const Child = ({ paymentDetails, address, index }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { successPaymentStatus } = useSelector((state) => state?.product);

  const CurrentProductDetails = useSelector(
    (state) => state?.product?.productdet
  );

  useEffect(() => {
    setTimeout(() => {
      if (CurrentProductDetails._id && successPaymentStatus === 200) {
        dispatch(product_details(CurrentProductDetails._id));
      }
    }, 500);

    setTimeout(() => {
      dispatch(clearSuccessPaymentStatus(null));
    }, 400);
  }, [successPaymentStatus, CurrentProductDetails?._id, dispatch]);

  const handleShow = () => {
    if (paymentDetails?.price === 0) {
      toast.warning("Unable to place order");
      return;
    }

    setShow(true);
  };

  const file_download = (csvlink, filename) => {
    if (filename) {
      const config = { responseType: "blob" };
      axios.get(csvlink, config).then((message) => {
        if (typeof window.chrome !== "undefined") {
          // Chrome version
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(message.data);
          a.href = url;
          a.download = filename;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else if (typeof window.navigator.msSaveBlob !== "undefined") {
          // IE version
          var blob = new Blob([message.data], {
            type: "application/force-download",
          });
          window.navigator.msSaveBlob(blob, filename);
        } else {
          // Firefox version
          var file = new File([message.data], filename, {
            type: "application/force-download",
          });
          window.open(URL.createObjectURL(file));
        }
      });
    } else {
      toast.warning("Invoice is not available");
    }
  };

  const isFirstPayment = index === 0;
  const isSecondPayment = index === 1;

  let content = "";

  if (isFirstPayment) {
    if (paymentDetails?.isPaid === "No") {
      content = "Due Now";
    } else if (paymentDetails?.isPaid === "Yes") {
      content = "View Receipt";
    }
  } else if (isSecondPayment) {
    if (paymentDetails?.isPaidPayment2 === "No") {
      content = "Due Later";
    } else if (paymentDetails?.isPaidPayment2 === "Yes") {
      content = "View Receipt";
    }
  }

  return (
    <>
      <PaymentModal
        address={address}
        paymenttype={`payment${index + 1}`}
        amount={paymentDetails?.price || 500}
        handleClose={handleClose}
        show={show}
        // _data={paymentDetails}
      />

      <Accordion.Header>
        <h3>
          Payment {index + 1}
          <span className="pricetag ps-1">${paymentDetails?.payOut}</span>
        </h3>
        <div className="btndue">
          <button
            disabled={paymentDetails?.isPaid === "Yes"}
            type="button"
            className={`btn btn-sm`}
            style={{
              background: paymentDetails?.isPaid === "Yes" ? "#077952" : "",
              opacity: 1,
            }}
            onClick={handleShow}
          >
            {paymentDetails?.isPaid === "No" ? "Pay" : "Paid"}
          </button>

          {content && <h4>{content}</h4>}
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="total-payment">
          {paymentDetails?.invoice_name !== "" ? (
            <div className="payment-detail">
              <div className="payment-heading main-heading ">
                <h3 style={{ width: "fix-content" }}>Payment ({index + 1})</h3>
                <div className="payment-heading">
                  <button
                    className="download-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      file_download(
                        API_URL +
                          "/uploads/project/invoice/" +
                          paymentDetails?.invoice_name,
                        paymentDetails?.invoice_name
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              </div>

              <div className="payment-heading">
                <h3>
                  Order Id : <span>{paymentDetails?.orderId}</span>
                </h3>
              </div>
              <div className="payment-heading">
                <h3>
                  Payment Status :{" "}
                  <span className="success">
                    {paymentDetails?.payment_status}
                  </span>
                </h3>
              </div>
              <div className="payment-heading">
                <h3>
                  Payment Mode : <span>{paymentDetails?.payment_mode}</span>
                </h3>
              </div>
              <div className="payment-heading">
                <h3>
                  Transaction Id : <span>{paymentDetails?.transcationID}</span>
                </h3>
              </div>
            </div>
          ) : (
            <>No Payment Recipt Found</>
          )}
        </div>
      </Accordion.Body>
    </>
  );
};
